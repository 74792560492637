<!--
RESET PASSWORD

Renders the reset password form, containing the following:
	- Email (input)
	- Log In (button)
	- Rest Password (button)
-->
<template>
	<div class="d-flex justify-center pa-4" style="width: 100%">
		<div style="width: 100%; max-width: 512px">

			<!--Logo-->
			<div class="d-flex justify-center">
				<v-img :src="require('@/assets/images/company-logo-win-blue.svg')"/>
			</div>

			<v-divider class="mt-8"/>

			<!--Title-->
			<app-text category="text-large" class="primary--text mt-8">Register</app-text>

			<!--Instruction-->
			<app-text category="text-default" class="mt-4">
				Please enter your email address to request a password reset.
			</app-text>

			<!--Reset Password Form-->
			<firebase-auth-reset class="mt-4"/>

		</div>
	</div>
</template>

<script>
export default {
	name: "ResetPassword",
};
</script>
