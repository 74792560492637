<template>
    <v-card flat>

        <!--Toolbar-->
        <v-toolbar class="px-2" color="primary">

            <app-text category="text-medium" class="white--text">Project Won</app-text>

            <v-spacer/>

            <close-icon @click.native="closeDialog"/>

        </v-toolbar>

        <!--Content-->
        <div class="pa-4">

            <!--Description-->
            <app-text>
				You are about to mark this project as 'In Progress' which will start the process of turning it into a Current Project.
				<br>
				<br>
				To do this, click the YES button below, complete the remainder of the form, and the following will happen:
				<br>
				<br>
				- Contract will be viewable in the Current Projects page
				<br>
				<br>
				- <strong>New Current Project</strong> emails will be sent to all users
				<br>
				<br>
				- <strong>Project Team Launch</strong> calendar reminder will be sent to the project team (dated one week after the Contract Award Date)
				<br>
				<br>
				- <strong>Tender Handover</strong> calendar reminder will be sent to the project team (dated two weeks after the Contract Award Date)
            </app-text>

			<v-divider class="mt-4"/>

            <!--Instructional Text-->
            <app-text category="text-default-bold" class="mt-4">
                Are you sure you want to mark this project as won?
            </app-text>

            <!--Action Buttons-->
            <div class="d-flex justify-space-between mt-4">

                <!--No-->
                <app-btn @click.native="closeDialog"
                         color="lightgrey"
                         icon="icons8-cancel"
                         label="Cancel"/>

                <!--Yes-->
                <app-btn @click.native="handleYesButton"
                         color="success"
                         icon="icons8-checkmark-yes"
                         label="Yes"/>

            </div>

        </div>

    </v-card>
</template>

<script>
export default {

    name: "jobWonDialog",

    methods: {

        closeDialog() {
            const t = this

            t.$emit('emitCloseJobWonDialog')
        },

        handleYesButton() {
            const t = this

            t.$emit('emitSetJobAsWon')
        },

    }
}
</script>

<style scoped>

</style>
