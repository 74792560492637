<template>
    <div class="d-flex flex-column" style="width: 100%">

        <app-text category="text-medium">{{ title }}</app-text>

		<app-text v-if="description" class="grey--text" category="text-small">{{ description }}</app-text>

        <v-divider class="mt-2"/>

    </div>
</template>

<script>
export default {

    name: "formSectionTitle",

    props: ['description', 'title']

}
</script>

<style scoped>

</style>
