<template>
    <v-expansion-panels class="rounded-lg mt-4" flat>
        <v-expansion-panel>

            <!--Icon | Title | Text -->
            <v-expansion-panel-header class="d-flex flex-column align-start px-4">

                <!--Icon | Title -->
                <div class="d-flex">

                    <!--Icon-->
                    <v-icon class="icons8-user-clock ma-n1" color="accent" size="48"/>

                    <!--Title-->
                    <app-text category="text-medium-bold" class="primary--text ml-4">Awaiting Approval</app-text>

                </div>

                <!--Message-->
                <app-text category="text-default" class="mt-4">{{ usersAwaitingApprovalMessage }}</app-text>

            </v-expansion-panel-header>

            <!--Awaiting approval-->
            <v-expansion-panel-content class="expansion-panel-content-container">

                <!--Users-->
                <div v-for="user in usersAwaitingApproval" class="mb-4">

                    <v-divider class="mb-4"/>

                    <app-text category="text-default-bold">{{ user.userName }}</app-text>
                    <app-text category="text-default">{{ user.userPosition }}</app-text>
                    <app-text category="text-default">{{ user.userEmail }}</app-text>
                    <app-text category="text-default">{{ user.userTelephone }}</app-text>

                </div>

                <!--Action button-->
                <app-btn @click.native="MIX_go('staff-directory')"
                             :block="true"
                             color="primary"
                             label="Show Accounts"/>

            </v-expansion-panel-content>

        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {

    name: "awaitingApprovalHomePageCard",

    props: ['usersAwaitingApprovalMessage', 'usersAwaitingApproval'],
}
</script>
