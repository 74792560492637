import { render, staticRenderFns } from "./observationsForm.vue?vue&type=template&id=100c9dc4&scoped=true&"
import script from "./observationsForm.vue?vue&type=script&lang=js&"
export * from "./observationsForm.vue?vue&type=script&lang=js&"
import style0 from "./observationsForm.vue?vue&type=style&index=0&id=100c9dc4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "100c9dc4",
  null
  
)

export default component.exports