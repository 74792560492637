<template>
	<v-card>

		<!--Toolbar-->
		<v-toolbar class="pl-2" color="primary">

			<!--Title-->
			<app-text category="text-medium" class="white--text">{{ point.projectTenderNumber }}</app-text>

			<v-spacer/>

			<close-icon @click.native="closeDialog"/>

		</v-toolbar>

		<!--Content-->
		<div class="d-flex flex-column justify-center text-center pa-4">

			<!--Client-->
			<div class="mpd-2row-container">

				<!--Project Title-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Project title</app-text>
					<app-text>{{ point.projectTitle }}</app-text>
				</div>

				<div style="border-left: 1px solid lightgrey"/>

				<!--Client-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Client</app-text>
					<app-text>{{ point.projectClient }}</app-text>
				</div>

			</div>

			<!--Contract Type | PriceQuality -->
			<div class="mpd-2row-container mt-8">

				<!--Contract Type-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Contract Type</app-text>
					<app-text>{{ point.projectContractType }}</app-text>
				</div>

				<div style="border-left: 1px solid lightgrey"/>

				<!--Client Advertised Value-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Client Advertised Value</app-text>
					<app-text>{{ new Intl.NumberFormat('en-GB', {
						maximumFractionDigits: 0,
						style: 'currency',
						currency: 'GBP'
					}).format(point.projectClientAdvertisedValue) }}
					</app-text>
				</div>

			</div>

			<!--Bid Lead | Submission Date-->
			<div class="mpd-2row-container mt-8">

				<!--Bid Lead-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Bid Lead</app-text>
					<app-text>{{ getUserName(point.projectBidLead) }}</app-text>
				</div>

				<div style="border-left: 1px solid lightgrey"/>

				<!--Submission Date-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Submission Date</app-text>
					<app-text>{{ renderDate(point.projectSubmissionDate) }}</app-text>
				</div>

			</div>

		</div>

	</v-card>
</template>

<script>
export default {

	name: "mapNewSitePointDialog",

	props: ['point', 'usersData'],

	data: () => ({}),

	methods: {

		closeDialog() {
			const t = this

			t.$emit('emitCloseMapPointDialog')
		},

		/**
		 * Get UserName
		 *
		 * Get and return the userName from the user's ID
		 *
		 * @param userId the user's ID to search on
		 * @returns {String} the userName if it's found, or a 'Not Found' message
		 */
		getUserName(userId) {
			const t = this
			const usersData = t.$props.usersData
			let userName

			const foundUser = userName = usersData.find(user => user.id === userId)

			return userName = foundUser ? foundUser.userName : 'Not Found'
		},

		renderDate(date) {
			const t = this

			return t.$vuetify.breakpoint.width < 512
				? t.$moment(Number(date)).format('Do MMM \'YY')
				: t.$moment(Number(date)).format('ddd Do MMM \'YY')
		}

	}

}
</script>

<style scoped>
.mpd-2row-container {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
}

.mpd-3row-container {
	display: grid;
	grid-template-columns: 1fr auto 1fr auto 1fr;

	margin-top: 32px;
}
</style>
