<template>
	<div>

		<div id="map" :style="style"></div>

		<!--Map Site Filters (desktop)-->
		<div v-if="$vuetify.breakpoint.width > 600"
			 style="position: fixed; top: 80px; right: 16px; width: 240px">

			<!--Toggle buttons-->
			<v-btn-toggle class="d-flex flex-column red rounded-lg"
						  color="white"
						  multiple
						  style="box-shadow: 0 0 8px grey"
						  v-model="toggleMultiple">

				<!--Walters Offices-->
				<v-btn @click="filterByWaltersOffices()"
					   class="rounded-t-lg rounded-b-0 pa-2"
					   value="Office"
					   style="height: 48px; justify-content: start; width: 100%">
					<v-img class="mr-2" :src="require('@/assets/walters_hq.png')" max-width="32"/>
					<app-text category="text-small" class="darkgrey--text">Offices</app-text>
				</v-btn>

				<!--New Sites-->
				<v-btn @click="filterByNewSites()"
					   class="pa-2"
					   value="New"
					   style="height: 48px; justify-content: start; width: 100%">
					<v-icon class="icons8-circled-w mr-2" color="accent" size="32"/>
					<app-text category="text-small" class="darkgrey--text">New Opportunities</app-text>
				</v-btn>

				<!--Sites In Progress-->
				<v-btn @click="filterBySitesInProgress()"
					   class="pa-2"
					   value="In Progress"
					   style="height: 48px; justify-content: start; width: 100%">
					<div class="d-flex align-center justify-center mr-2"
						 style="aspect-ratio: 1/1; background-color: var(--v-warning-base); border-radius: 50%; width: 32px">
						<v-icon class="icons8-bulldozer" color="white" size="24"/>
					</div>
					<app-text category="text-small" class="darkgrey--text">Current Projects</app-text>
				</v-btn>

				<!--Sites Completed-->
				<v-btn @click="filterByCompletedSites()"
					   class="rounded-t-0 rounded-b-lg pa-2"
					   value="Completed"
					   style="height: 48px; justify-content: start; width: 100%">
					<div class="d-flex align-center justify-center mr-2"
						 style="aspect-ratio: 1/1; background-color: var(--v-success-base); border-radius: 50%; width: 32px">
						<v-icon class="icons8-handshake" color="white" size="24"/>
					</div>
					<app-text category="text-small" class="darkgrey--text">Completed Projects</app-text>
				</v-btn>

			</v-btn-toggle>

		</div>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->

		<!--Office Info Dialog-->
		<v-dialog max-width="512"
				  v-model="isOfficePointDialogVisible">
			<map-office-point-dialog :point="selectedPoint"
									 :usersData="usersData"
									 v-on:emitCloseMapPointDialog="emittedCloseMapPointDialog"/>
		</v-dialog>

		<!--New Site Info Dialog-->
		<!--v-if="['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel) || GET_currentUser.userAccessAndAbilities.includes('New Opportunities: Can Open Map Icon')"-->
		<v-dialog v-if="GET_currentUser.userType !== 'Visitor'"
				  max-width="840"
				  v-model="isNewSitePointDialogVisible">
			<map-new-site-point-dialog :point="selectedPoint"
									   :usersData="usersData"
									   v-on:emitCloseMapPointDialog="emittedCloseMapPointDialog"/>
		</v-dialog>

		<!--Site In Progress Info Dialog-->
		<!--v-if="['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel) || GET_currentUser.userAccessAndAbilities.includes('Current Projects: Can Open Map Icon')"-->
		<v-dialog v-if="GET_currentUser.userType !== 'Visitor'"
				  max-width="840"
				  v-model="isSiteInProgressPointDialogVisible">
			<map-site-in-progress-point-dialog :point="selectedPoint"
											   :usersData="usersData"
											   :weatherData="weatherData"
											   v-on:emitCloseMapPointDialog="emittedCloseMapPointDialog"/>
		</v-dialog>

		<!--Completed Site Info Dialog-->
		<!--v-if="['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel) || GET_currentUser.userAccessAndAbilities.includes('Completed Projects: Can Open Map Icon')"-->
		<v-dialog v-if="GET_currentUser.userType !== 'Visitor'"
				  max-width="840"
				  v-model="isCompletedSitePointDialogVisible">
			<map-completed-site-point-dialog :point="selectedPoint"
											 v-on:emitCloseMapPointDialog="emittedCloseMapPointDialog"/>
		</v-dialog>

	</div>
</template>

<script>
import MapNewSitePointDialog from "@/views/map/mapNewSitePointDialog/mapNewSitePointDialog";
import {mapGetters} from "vuex";
import MapSiteInProgressPointDialog from "@/views/map/mapSiteInProgressPointDialog/mapSiteInProgressPointDialog";
import MapCompletedSitePointDialog from "@/views/map/mapCompletedSitePointDialog/mapCompletedSitePointDialog";
import MapOfficePointDialog from "@/views/map/mapOfficePointDialog/mapOfficePointDialog.vue";

export default {

	name: 'Mapbox',

	components: {
		MapOfficePointDialog,
		MapCompletedSitePointDialog,
		MapSiteInProgressPointDialog,
		MapNewSitePointDialog
	},

	props: {
		width: {
			type: String,
			default: '100%',
			required: false,
		},
		height: {
			type: String,
			default: '100%',
			required: false,
		},
		marker: {
			type: Object,
			default: function () {
				return {};
			},
		},
		usersData: {type: Array},
		weatherData: {type: Array},
	},

	data: () => ({
		toggleMultiple: ['Office', 'New', 'In Progress', 'Completed'],

		siteInProgressIconColor: '#f07800',
		completedSiteIconColor: '#00942A',

		// site status arrays
		waltersOfficesArray: [],
		officesCollectionData: [],
		waltersOfficesVisible: true,
		newSitesArray: [],
		newSitesCollectionData: [],
		newSitesVisible: true,
		sitesInProgressArray: [],
		sitesInProgressVisible: true,
		completedSitesArray: [],
		completedSiteVisible: true,

		isNewSitePointDialogVisible: false,
		isSiteInProgressPointDialogVisible: false,
		isCompletedSitePointDialogVisible: false,
		isOfficePointDialogVisible: false,
		selectedPoint: {},

		devices: {
			features: [
				{
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: [],
					},
					properties: {
						name: '',
						id: '',
						value: '',
						color: '',
						icon: '',
						alert: 'false',
						textColor: '',
						circle: '',
					},
				},
			],
		},
		features: [],
		map: {},
		mapLoading: false,
		symbols: [
			{
				name: 'walters-office',
				url: 'https://img.icons8.com/material/25/FFFFFF/building--v1.png',
			},
			{
				name: 'new-site',
				url: 'https://img.icons8.com/ios-filled/52/fcb315/circled-w.png',
			},
			{
				name: 'site-in-progress',
				url: 'https://img.icons8.com/material/24/FFFFFF/bulldozer--v1.png',
			},
			{
				name: 'site-completed',
				url: 'https://img.icons8.com/material/24/FFFFFF/handshake--v1.png',
			},
		],
	}),

	computed: {
		...mapGetters({
			GET_currentUser: 'GET_currentUser',
		}),

		style() {
			const t = this
			let style

			if (t.$vuetify.breakpoint.width >= 840) style = `width: 100vw !important; height: 100vh !important;`
			if (t.$vuetify.breakpoint.width < 840) style = `width: ${t.$vuetify.breakpoint.width}px !important; height: 40vh !important;`

			return style
		},

	},

	methods: {

		/**
		 * Get Collection Data
		 *
		 * Get the collection data from the DB and assign to reactive data.
		 *
		 * @returns {Promise<void>}
		 */
		async getSitesNodes() {
			let t = this

			await t.$firebase.db
				.collection('sites')
				.get()
				.then(querySnapshot => {

					querySnapshot.docs.forEach(doc => {

						const document = doc.data()
						const point = {
							type: 'Feature',
							geometry: {
								type: 'Point',
								coordinates: [
									document.siteLongitude,
									document.siteLatitude,
								],
							},
							properties: {
								name: document.siteName,
								id: document.id,
								status: document.siteStatus,
								color: '#582768',
								icon: '',
								alert: 'false',
								textColor: '#242526',
								circle: 'true',
							},
						}

						// Ignore document if it's been deleted
						if (document.hasOwnProperty('delete')) return

						if (point.properties.status === 'Office') {
							point.properties.color = '#153051'
							point.properties.icon = 'walters-office'
							t.waltersOfficesArray.push(point)
							t.officesCollectionData.push(document)
						}

						t.devices.features.push(point)
					})

				})
				.catch(error => console.error(error))
		},

		/**
		 * Get Collection Data
		 *
		 * Get the collection data from the DB and assign to reactive data.
		 *
		 * @returns {Promise<void>}
		 */
		async getExternalSitesNodes() {
			let t = this
			let newSitesArray = []
			let newSitesCollectionData = []
			let sitesInProgressArray = []
			let sitesInProgressCollectionData = []
			let completedSitesArray = []
			let completedSitesCollectionData = []

			await t.$firebase.db
				.collection('externalSites')
				.get()
				.then(querySnapshot => {

					querySnapshot.docs.forEach(doc => {

						const document = doc.data()
						document.id = doc.id
						const point = {
							type: 'Feature',
							geometry: {
								type: 'Point',
								coordinates: [
									document.projectLocationLongitude,
									document.projectLocationLatitude,
								],
							},
							properties: {
								name: document.projectTitle,
								id: document.id,
								status: document.siteStatus,
								color: '#582768',
								icon: '',
								alert: 'false',
								textColor: '#242526',
								circle: 'true',
							},
						}

						// Ignore document if it's been deleted
						if (document.hasOwnProperty('delete') || document.jobLostWon === 'Lost') return

						// New Sites
						if (point.properties.status === 'New') {
							// point.properties.alert = 'true'
							// point.properties.color = '#0A233E'
							point.properties.color = '#FFFFFF'
							point.properties.icon = 'new-site'
							newSitesArray.push(point)
							newSitesCollectionData.push(document)
						}

						// Sites In Progress
						if (point.properties.status === 'In Progress') {
							point.properties.color = t.siteInProgressIconColor
							point.properties.icon = 'site-in-progress'

							// If SWAPPED in, flash orange 'alert' animation
							if (t.GET_currentUser.swappStatus === 1 && (t.GET_currentUser.lastSite.siteId === point.properties.id)) {
								point.properties.alert = 'true'
							}
							sitesInProgressArray.push(point)
							sitesInProgressCollectionData.push(document)
						}

						// Completed Sites
						if (point.properties.status === 'Complete') {
							point.properties.color = t.completedSiteIconColor
							point.properties.icon = 'site-completed'

							// If SWAPPED in, flash orange 'alert' animation
							if (t.GET_currentUser.swappStatus === 1 && (t.GET_currentUser.lastSite.siteId === point.properties.id)) {
								point.properties.alert = 'true'
							}
							completedSitesArray.push(point)
							completedSitesCollectionData.push(document)
						}

						t.devices.features.push(point)
					})

					// Assign data
					t.newSitesArray = newSitesArray
					t.newSitesCollectionData = newSitesCollectionData
					t.sitesInProgressArray = sitesInProgressArray
					t.sitesInProgressCollectionData = sitesInProgressCollectionData
					t.completedSitesArray = completedSitesArray
					t.completedSitesCollectionData = completedSitesCollectionData
				})
				.catch(error => console.error(error))
		},

		addLayer(payload) {
			const t = this

			return new Promise((resolve, reject) => {

					// Add geoJSON Source by URL
					t.map.addSource(payload.sourceName, {
						type: 'geojson',
						data: payload.sourceURL, // Use a URL for the value for the `data` property.
					})

					// Add Alert Layer to Map
					t.map.addLayer({
						id: payload.layerId + '-alert',
						source: payload.sourceName,
						type: 'circle',
						paint: {
							'circle-radius': payload.initialRadius,
							'circle-color': ['get', 'color'],
						},
						filter: ['==', 'alert', 'true'], // Filter where geoJSON data property alert: true
					})

					// Add Circle Layer to Map
					t.map.addLayer({
						id: payload.layerId + 'circle',
						source: payload.sourceName,
						type: 'circle',
						paint: {
							'circle-radius': payload.initialRadius,
							'circle-radius-transition': {duration: 0},
							'circle-opacity-transition': {duration: 0},
							'circle-color': ['string', ['get', 'color']],
						},
						filter: ['==', 'circle', 'true'], // Filter where geoJSON data property alert: true
					})

					// Add Symbol Layer
					t.map.addLayer({
						id: payload.layerId,
						source: payload.sourceName,
						type: 'symbol',
						layout: {
							'icon-image': '{icon}',
							'icon-allow-overlap': true,
							// 'text-allow-overlap': true,
						},
					})

					// Show Name Label or Not
					if (payload.showLabel) {
						t.map.addLayer({
							id: payload.layerId + '-label',
							source: payload.sourceName,
							type: 'symbol',
							layout: {
								'text-field': ['get', 'name'],
								'text-size': 14,
								'text-font': [
									'Open Sans Semibold',
									'Arial Unicode MS Bold',
								],
								'text-offset': [0, 2.5],
								'text-anchor': 'center',
							},
							paint: {
								'text-color': ['string', ['get', 'textColor']],
							},
						});
					}
					let framesPerSecond = 10
					let initialOpacity = 1
					let opacity = initialOpacity
					let initialRadius = 20
					let radius = initialRadius
					let maxRadius = 60

					// Alert Animation (Pulsing)
					function animateMarker(timestamp) {
						setTimeout(function () {
							requestAnimationFrame(animateMarker)
							radius += (maxRadius - radius) / framesPerSecond
							opacity -= 0.9 / framesPerSecond
							if (opacity <= 0) {
								radius = initialRadius
								opacity = initialOpacity
							}
							t.map.setPaintProperty(
								payload.layerId + '-alert',
								'circle-radius',
								radius
							)
							t.map.setPaintProperty(
								payload.layerId + '-alert',
								'circle-opacity',
								opacity
							)
						}, 1000 / framesPerSecond)
					}

					// Start the animation.
					animateMarker(0)

					// On Layer Click
					t.map.on('click', payload.layerId, function (e) {

						const features = t.map.queryRenderedFeatures(e.point, {layers: [payload.layerId]})

						// Office Point Dialog
						if (features[0].properties.status === 'Office') {
							t.getSelectedPointData(features[0].properties.id, features[0].properties.status)
						}

						// New Sites Point Dialog
						if (features[0].properties.status === 'New') {
							t.getSelectedPointData(features[0].properties.id, features[0].properties.status)
						}

						// Site In Progress Point Dialog
						if (features[0].properties.status === 'In Progress') {
							t.getSelectedPointData(features[0].properties.id, features[0].properties.status)
						}

						// Completed Site Point Dialog
						if (features[0].properties.status === 'Complete') {
							t.getSelectedPointData(features[0].properties.id, features[0].properties.status)
						}

					})

					resolve({
						code: 1,
						message: 'Layer Added Successfully',
						data: null,
						error: null,
					})
				}
			)
		},

		async createMap() {
			return new Promise((resolve, reject) => {
				const t = this

				t.mapLoading = true

				// Create the map
				t.map = new t.$mapboxgl.Map({
					accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
					container: 'map',
					style: 'mapbox://styles/mapbox/light-v9',
					center: [-4.2283417, 51.7089602],
					zoom: 8,
				})

				// Add the map controls (zoom, centre, and pivot)
				const nav = new t.$mapboxgl.NavigationControl()
				t.map.addControl(nav, 'top-left')

				// Load the images for the markers
				t.map.on('load', () => {
					t.symbols.forEach(e => {
						t.map.loadImage(e.url, function (error, res) {
							t.map.addImage(e.name, res)

							// Layer must be added inside the map.on('load') for icons to render correctly
							t.devices.features.forEach(device => {
								let payload = {}
								payload = {}
								payload.layerId = 'task:' + device.properties.id
								payload.sourceName = 'task-layer:' + device.properties.id
								payload.sourceURL = device
								payload.layerType = 'symbol'
								payload.initialRadius = 24
								payload.showLabel = true

								// If Source already exists, don't add the layers again
								if (t.map.getSource(payload.sourceName)) return

								t.addLayer(payload)
							})
						})
					})

					t.mapLoading = false

					resolve({
						code: 1,
						message: 'Map has Loaded Successfully',
						data: null,
						error: null,
					})
				})
			})
		},

		/**
		 * Emitted Close Map Point Dialog
		 *
		 * When the emitted message is received, close the dialog box and clear the selected point.
		 */
		emittedCloseMapPointDialog() {
			const t = this

			t.isOfficePointDialogVisible = false
			t.isNewSitePointDialogVisible = false
			t.isSiteInProgressPointDialogVisible = false
			t.isCompletedSitePointDialogVisible = false
			t.selectedPoint = {}
		},

		// Center map on Site user is SWAPPed in to
		flyToUserLocation() {
			const t = this

			// Return if not swapped in
			if (t.GET_currentUser.swappStatus !== 1) return

			const sites = this.devices.features

			const usersLocation = sites.filter(sites => sites.properties.id === t.GET_currentUser.lastSite.siteId)

			// Match siteId to map marker
			if (t.GET_currentUser.swappStatus === 1 && (t.GET_currentUser.lastSite.siteId === usersLocation[0].properties.id)) {
				t.map.flyTo({
					center: usersLocation[0].geometry.coordinates
				})
			}

		},

		/**
		 * Get Select Point Data
		 *
		 * Using the point's ID and Status, fetch its site data.
		 *
		 * @param id the ID of the site
		 * @param status the status of the site
		 */
		getSelectedPointData(id, status) {
			const t = this
			let point

			if (status === 'Office') {
				point = t.officesCollectionData.find(site => site.id === id)
				t.isOfficePointDialogVisible = true
			}

			if (status === 'New') {
				point = t.newSitesCollectionData.find(site => site.id === id)
				t.isNewSitePointDialogVisible = true
			}

			if (status === 'In Progress') {
				point = t.sitesInProgressCollectionData.find(site => site.id === id)
				t.isSiteInProgressPointDialogVisible = true
			}

			if (status === 'Complete') {
				point = t.completedSitesCollectionData.find(site => site.id === id)
				t.isCompletedSitePointDialogVisible = true
			}

			t.selectedPoint = point
		},

		filterByWaltersOffices() {
			const t = this
			const isVisible = t.waltersOfficesVisible ? 'none' : 'visible'

			t.waltersOfficesArray.forEach((site, index, array) => {

				let id = site.properties.id

				if (t.map.getLayer('task:' + id + 'circle')) t.map.setLayoutProperty('task:' + id + 'circle', 'visibility', isVisible)
				if (t.map.getLayer('task:' + id + '-alert')) t.map.setLayoutProperty('task:' + id + '-alert', 'visibility', isVisible)
				if (t.map.getLayer('task:' + id + '-label')) t.map.setLayoutProperty('task:' + id + '-label', 'visibility', isVisible)
				if (t.map.getLayer('task:' + id)) t.map.setLayoutProperty('task:' + id, 'visibility', isVisible)

				// Toggle the visibility
				if (index === array.length - 1) t.waltersOfficesVisible = !t.waltersOfficesVisible

			})

		},

		filterByNewSites() {
			const t = this
			const isVisible = t.newSitesVisible ? 'none' : 'visible'

			t.newSitesArray.forEach((site, index, array) => {

				let id = site.properties.id

				if (t.map.getLayer('task:' + id + 'circle')) t.map.setLayoutProperty('task:' + id + 'circle', 'visibility', isVisible)
				if (t.map.getLayer('task:' + id + '-alert')) t.map.setLayoutProperty('task:' + id + '-alert', 'visibility', isVisible)
				if (t.map.getLayer('task:' + id + '-label')) t.map.setLayoutProperty('task:' + id + '-label', 'visibility', isVisible)
				if (t.map.getLayer('task:' + id)) t.map.setLayoutProperty('task:' + id, 'visibility', isVisible)

				// Toggle the visibility
				if (index === array.length - 1) t.newSitesVisible = !t.newSitesVisible

			})

		},

		filterBySitesInProgress() {
			const t = this
			const isVisible = t.sitesInProgressVisible ? 'none' : 'visible'

			t.sitesInProgressArray.forEach((site, index, array) => {

				let id = site.properties.id

				if (t.map.getLayer('task:' + id + 'circle')) t.map.setLayoutProperty('task:' + id + 'circle', 'visibility', isVisible)
				if (t.map.getLayer('task:' + id + '-alert')) t.map.setLayoutProperty('task:' + id + '-alert', 'visibility', isVisible)
				if (t.map.getLayer('task:' + id + '-label')) t.map.setLayoutProperty('task:' + id + '-label', 'visibility', isVisible)
				if (t.map.getLayer('task:' + id)) t.map.setLayoutProperty('task:' + id, 'visibility', isVisible)

				// Toggle the visibility
				if (index === array.length - 1) t.sitesInProgressVisible = !t.sitesInProgressVisible

			})

		},

		filterByCompletedSites() {
			const t = this
			const isVisible = t.completedSiteVisible ? 'none' : 'visible'

			t.completedSitesArray.forEach((site, index, array) => {

				let id = site.properties.id

				if (t.map.getLayer('task:' + id + 'circle')) t.map.setLayoutProperty('task:' + id + 'circle', 'visibility', isVisible)
				if (t.map.getLayer('task:' + id + '-alert')) t.map.setLayoutProperty('task:' + id + '-alert', 'visibility', isVisible)
				if (t.map.getLayer('task:' + id + '-label')) t.map.setLayoutProperty('task:' + id + '-label', 'visibility', isVisible)
				if (t.map.getLayer('task:' + id)) t.map.setLayoutProperty('task:' + id, 'visibility', isVisible)

				// Toggle the visibility
				if (index === array.length - 1) t.completedSiteVisible = !t.completedSiteVisible

			})

		},

	},

	watch: {
		marker: {
			handler() {
				this.addLayer(this.marker);
			},
			deep: true,
		},
	},

	async mounted() {
		let t = this

		await t.getSitesNodes()
		await t.getExternalSitesNodes()
		await t.createMap()

		// Center map on User's location if SWAPPed in
		t.flyToUserLocation()
	},

};
</script>
