<!--
Observation Time Picker

Renders a Time Picker as a dialog box, with the activator being a text input which open the time picker pop up.
When a new time is selected, the time is emitted back to the calling component (observation.vue)
as 'emitObservationTime'.
The displayed time on opening a document is passed in as the 'time' prop.
-->
<template>
    <v-dialog max-width="304"
              ref="dialog"
              :return-value.sync="time"
              transition="dialog-bottom-transition"
              v-model="dialog">

        <!--Activator-->
        <template v-slot:activator="{ on, attrs }">

            <v-text-field append-icon="icons8-clock"
						  background-color="white"
                          class="rounded-lg"
                          :disabled="isFormReadOnly"
                          :error="error"
                          :error-messages="errorMessages"
                          filled
                          hide-details="auto"
                          label="Time"
                          :outlined="!isFormReadOnly"
                          readonly
                          v-bind="attrs"
                          v-model="time"
                          v-on="on"/>

        </template>

        <!--Body-->
        <v-time-picker @change="$emit('emitTime', newTime)"
                       color="primary"
                       format="24hr"
                       scrollable
                       v-model="newTime">

            <!--Action buttons-->
            <v-row class="d-flex mb-2" no-gutters>

                <!--Cancel-->
                <app-btn @click.native="dialog = false"
                         color="transparent"
                         label="Cancel"/>

                <v-spacer/>

                <!--Ok-->
                <app-btn @click.native="handleTime"
                         color="success"
                         label="Ok"/>

            </v-row>

        </v-time-picker>

    </v-dialog>
</template>

<script>
export default {

    name: "timePicker",

    props: ['error', 'errorMessages', 'isFormReadOnly', 'time'],

    data: () => ({

        dialog: false,
        newTime: '',

    }),

    methods: {
        handleTime() {
            const t = this

            // Save
            t.$refs.dialog.save(t.newTime)

            // Close the dialog
            t.dialog = false
        }
    },

}
</script>
