<template>
    <v-dialog max-width="512"
              transition="dialog-bottom-transition"
              v-model="dialog">

        <!--Activator-->
        <template v-slot:activator="{on, attrs}">
            <v-icon class="icons8-high-importance"
                    size="40"
                    v-bind="attrs"
                    v-on="on"/>
        </template>

        <!--Dialog Content-->
        <v-card>

            <!--Toolbar-->
            <v-toolbar class="px-4" color="white">

                <!--Title-->
                <app-text category="text-default-bold">{{ eventDataProps.eventName }}</app-text>

                <v-spacer/>

                <!--Close Icon-->
                <v-icon @click="dialog = false" class="icons8-multiply" color="error" size="32"/>

            </v-toolbar>

            <!--Info Text-->
            <div class="pa-4">
                <app-text category="text-default">
                    {{ eventDataProps.eventStewardInstructions || 'No instructions provided' }}
                </app-text>
            </div>

        </v-card>

    </v-dialog>
</template>

<script>
export default {

    name: "infoDialog",

    props: ['eventDataProps'],

    data: () => ({
        dialog: false,
    }),

}
</script>
