import { render, staticRenderFns } from "./notificationBadge.vue?vue&type=template&id=7af1ad33&scoped=true&"
import script from "./notificationBadge.vue?vue&type=script&lang=js&"
export * from "./notificationBadge.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7af1ad33",
  null
  
)

export default component.exports