<!--
Notification Badge

Displays a coloured dot to notify the user to something.
Renders as a small, red, overlapping, outlined, dot by default, but props can be applied.

NB...
- To render content in the badge, the dot prop must be false.

-->
<template>
	<v-badge :bordered="bordered"
			 :color="color"
			 :content="content"
			 :dot="dot"
			 :overlap="overlap"
			 value="value">

		<slot></slot>

	</v-badge>
</template>

<script>
export default {

	name: "notificationBadge",

	props: {
		bordered: {type: Boolean, default: true},
		color: {type: String, default: 'success'},
		content: {String},
		dot: {type: Boolean, default: true},
		overlap: {type: Boolean, default: true},
		value: {type: Boolean, default: false},
	}

}
</script>

<style scoped>

</style>
