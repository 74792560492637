<template>
    <v-bottom-sheet hide-overlay
                    persistent
                    width="336"
                    v-model="updateExists">

        <div class="d-flex align-center accent rounded-lg pa-4">

            <app-btn @click.native="refreshApp"
                     class="pulse"
                     color="primary"
                     label="Update Available"/>

            <v-spacer/>

            <close-icon @click.native="updateExists = false"/>

        </div>

    </v-bottom-sheet>
</template>

<script>
export default {
    name: "appUpdate"
}
</script>

<style scoped>

</style>
