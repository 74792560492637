<!--
Unauthorised

Renders a page for user's with accounts that are awaiting approval.
The page is locked down until it is approved.
-->
<template>
	<div class="d-flex flex-column align-center pa-4" style="width: 100%">

		<!--Page title-->
		<page-title :icon="computedPageData.icon" :page-title="computedPageData.title"/>

		<v-divider style="width: 100%"/>

		<!--Welcome | Information | Refresh Button | ReCaptcha-->
		<div class="d-flex flex-column justify-center">

			<!--Welcome | Information-->
			<v-card class="rounded-lg mt-4 pa-4" flat max-width="600">

				<!--Welcome-->
				<app-text category="text-default-bold">Welcome {{ computedUserName }}!</app-text>

				<!--Information-->
				<app-text category="text-default" class="mt-4">{{ computedPageData.description }}</app-text>

				<!--Instructional text-->
				<app-text category="text-default" class="mt-4">
					Please contact support@swapp.work for further assistance.
				</app-text>

			</v-card>

			<!--Refresh Button-->
			<app-btn v-if="!is2faVisible"
					 @click.native="handleRefreshButton"
					 :block="true"
					 class="text-capitalize mt-4"
					 color="#CCC"
					 label="Refresh"/>

			<!--Recaptcha-->
			<div id="recaptcha-container"></div>

			<div v-if="is2faVisible" style="width: 100%">

				<!--Verification Code Input-->
				<app-input v-if="isVerificationCodeInputVisible"
						   class="mt-4"
						   :error="errors.verificationCode"
						   :error-messages="errors.verificationCodeErrorMessage"
						   input-type="textInput"
						   label="Verification Code"
						   v-model="verificationCode"/>

				<!--Submit Button-->
				<app-btn v-if="isVerificationCodeInputVisible"
						 @click.native="get2faResult"
						 :block="true"
						 class="mt-4"
						 label="Submit"/>

			</div>

		</div>

	</div>
</template>

<script>
import firebase from "firebase/app";

export default {

	name: "unauthorised",

	data: () => ({
		errors: {
			verificationCode: false,
			verificationCodeErrorMessage: '',
		},
		isEmailVerified: false,
		userEmail: '',
		userTelephone: '',
		userStatus: 'PENDING',
		userShouldUse2fa: null,
		verificationId: '',
		verificationCode: '',
		isVerificationCodeInputVisible: false,
		is2faVisible: false,
		userHasPassed2fa: false,
	}),

	computed: {

		/**
		 * Computed Page Data
		 *
		 * Returns an icon, page title, and description based on the user's position within authentication.
		 * E.g. if the user required 2FA:
		 *   true -> verify email > pass 2FA > approved by Admin
		 *   false -> approved by Admin
		 *
		 * @returns {{icon: string, description: string, title: string}}
		 */
		computedPageData() {
			const t = this
			const HAS_PASSED_2FA = t.userHasPassed2fa
			const IS_EMAIL_VERIFIED = t.isEmailVerified
			const SHOULD_USE_2FA = t.userShouldUse2fa
			const USER_STATUS = t.userStatus
			let pageData = {description: '', icon: '', title: ''}

			if (!SHOULD_USE_2FA || HAS_PASSED_2FA) {
				if (t.GET_currentUser.delete) {
					pageData.icon = 'icons8-private'
					pageData.title = 'Deleted'
					pageData.description = 'Your account has been deleted'
				} else if (USER_STATUS.toUpperCase() === 'PENDING') {
					pageData.icon = 'icons8-private'
					pageData.title = 'Awaiting Approval'
					pageData.description = 'Your account is awaiting approval, you will automatically be granted access when it has been approved'
				} else if (USER_STATUS.toUpperCase() === 'SUSPENDED') {
					pageData.icon = 'icons8-private'
					pageData.title = 'Suspended'
					pageData.description = 'Your account has been suspended'
				} else if (USER_STATUS.toUpperCase() === 'REJECTED') {
					pageData.icon = 'icons8-private'
					pageData.title = 'Rejected'
					pageData.description = 'Your account has been rejected'
				} else if (USER_STATUS.toUpperCase() === 'ARCHIVED') {
					pageData.icon = 'icons8-private'
					pageData.title = 'Archived'
					pageData.description = 'Your account has been archived'
				} else if (USER_STATUS.toUpperCase() === 'APPROVED') {
					pageData.icon = 'icons8-checkmark-yes'
					pageData.title = 'Approved'
					pageData.description = 'Your account has been approved. Please refresh the page to continue'
					t.GET_currentUser.userStatus = 'APPROVED'
					t.MIX_go('/')
				}
				return pageData

			} else if (SHOULD_USE_2FA && IS_EMAIL_VERIFIED) {

				pageData.icon = 'icons8-2fa'
				pageData.title = '2 Factor Authentication'
				pageData.description = `Thanks for verifying your email address, and for opting to keep your account secure. Please enter the authorisation code sent to your mobile number ending ${t.userTelephone.slice(-4)} in the box below.`

				t.run2fa()
				t.is2faVisible = true

				return pageData
			} else if (SHOULD_USE_2FA && !IS_EMAIL_VERIFIED) {

				pageData.icon = 'icons8-email'
				pageData.title = 'Verify Your Email'
				pageData.description = `You need to verify your email address before your account can be approved. We've sent an email to ${t.userEmail} with a link for you to follow.`
				return pageData
			}

		},

		/**
		 * Computed UserName
		 *
		 * Returns the current user's first name from their stored userName.
		 *
		 * @returns {name} - the current user's first name.
		 */
		computedUserName() {
			const t = this

			return t.GET_currentUser.userName.split(' ')[0]
		},

	},

	methods: {

		/**
		 * Handle Refresh Button
		 *
		 * Set the action for the button under different statuses.
		 */
		handleRefreshButton() {
			const t = this

			this.$router.go()
		},

		/**
		 * Initialise ReCAPTCHA
		 *
		 * Initialise an invisible reCAPTCHA so the user does not have to check an 'I'm Not a Robot' checkbox.
		 */
		initialiseRecaptcha() {
			const t = this

			t.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
				'recaptcha-container',

				// Optional reCAPTCHA parameters
				{
					'size': 'invisible',
					'callback': function (response) {
						// reCAPTCHA solved
						t.run2fa()
					},
					'expired-callback': function () {
						// Response expired. Ask user to solve reCAPTCHA again
						console.log('expired...')
					}
				})

		},

		/**
		 * Run 2FA
		 *
		 * Setup 2FA nd send a verification code to the user's mobile phone.
		 *
		 * @returns {Promise<void>}
		 */
		async run2fa() {
			const t = this
			const USER = t.$firebase.auth.currentUser
			let phoneNumber = t.userTelephone
			const RECAPTCHA_VERIFIER = t.recaptchaVerifier

			// Reformat phone number with the leading country code, as required (replace the 0)
			if (phoneNumber[0] === '0') phoneNumber = phoneNumber.replace(phoneNumber[0], '+44')

			// Create a session
			const SESSION = await USER.multiFactor.getSession()

			// Specify the phone number and pass the MFA session
			const PHONE_INFO_OPTIONS = {phoneNumber, session: SESSION}

			// Create an auth provider
			const PHONE_AUTH_PROVIDER = new firebase.auth.PhoneAuthProvider()

			// Send SMS verification code
			t.verificationId = await PHONE_AUTH_PROVIDER.verifyPhoneNumber(PHONE_INFO_OPTIONS, RECAPTCHA_VERIFIER)

			t.isVerificationCodeInputVisible = true
			console.log('2FA SMS sent...')
		},

		/**
		 * Get 2FA Result
		 *
		 * Check the verification details and complete the 2FA.
		 * If all is good, update the user's document.
		 *
		 * @returns {Promise<void>}
		 */
		async get2faResult() {
			const t = this
			const USER = t.$firebase.auth.currentUser
			const VERIFICATION_ID = t.verificationId
			const VERIFICATION_CODE = t.verificationCode

			// Only continue if the verification code is valid
			if (!t.validateForm()) return

			// Ask user for the verification code
			const CRED = firebase.auth.PhoneAuthProvider.credential(VERIFICATION_ID, VERIFICATION_CODE)

			// Create a multi-factor assertion
			const MULTI_FACTOR_ASSERTION = firebase.auth.PhoneMultiFactorGenerator.assertion(CRED)

			// Complete enrollment
			try {
				await USER.multiFactor.enroll(MULTI_FACTOR_ASSERTION, 'My Phone Number')
			} catch (error) {
				console.error('Error verifying code: ', error)

				if (error.code === 'auth/invalid-verification-code') {
					t.errors.verificationCode = true
					t.errors.verificationCodeErrorMessage = 'Sorry, this code is not recognised'

					return
				}

			}

			// Update the user document for other parts of the system to check
			await t.MIX_updateDocumentFieldsById('users', t.GET_currentUser.id, {userHasPassed2fa: true})

			t.is2faVisible = false
			console.log('2FA done...')
		},

		validateForm() {
			const t = this

			t.errors.verificationCode = false
			t.errors.verificationCodeErrorMessage = ''

			if (!t.verificationCode.trim() || t.verificationCode.trim().length !== 6) {
				t.errors.verificationCode = true
				t.errors.verificationCodeErrorMessage = 'You must enter a 6 digit code'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

	async created() {
		const t = this

		await t.$firebase.db
			.collection('users')
			.doc(t.GET_currentUser.id)
			.onSnapshot(doc => {

				// Listen for changes to, and set, the user status
				t.userStatus = doc.data().userStatus
				t.userEmail = doc.data().userEmail
				t.userTelephone = doc.data().userTelephone
				t.userShouldUse2fa = doc.data().userShouldUse2fa
				t.userHasPassed2fa = doc.data().userHasPassed2fa

				t.isEmailVerified = t.$firebase.auth.currentUser.emailVerified

				// If the current user is a visitor, automatically forward on to the home page
				if (doc.data().userLevel === 'Visitor-User') t.MIX_go('/')
			})

		t.initialiseRecaptcha()
	}

}
</script>
