import Vue from "vue";
import {mapGetters, mapActions} from 'vuex';
import moment from 'moment';

const mixin = {

	data: () => ({
		buttonSizeDefault: 48,

		// Lookup Values for selection fields
		appSupportTypesOptionsData: [
			'General Enquiry',
			'Problem',
			'Request',
			'Suggestion',
		],
		answersOptionsData: [
			'Yes',
			'No',
		],
		correctAnswersOptionsData: [
			'Yes',
			'No',
		],
		extendedScheduleOptionsData: [
			'Day Off',
			'Holiday',
			'Working from Home',
			'Working Off Site'
		],
		questionTypesOptionsData: [
			'Covid'
		],
		responseTimesOptionsData: [
			'Immediate',
			'24 Hours',
			'3 Days'
		],
		observationPriorityOptionsData: [
			'High',
			'Medium',
			'Low'
		],
		observationTypesOptionsData: [
			'Good Practice',
			'Observation',
			'Recognition',
		],
		observationStatusOptionsData: [
			'Pending',
			'Resolved'
		],
		userRolesOptionsData: [
			'Admin',
			'Manager',
			'User',
		],
		userStatusOptionsData: [
			'PENDING',
			'APPROVED',
			'REJECTED',
			'SUSPENDED',
			'ARCHIVED',
		],
		userTypesOptionsData: [
			'Staff',
			'Visitor',
			// 'Contractor',
		],
		userTypesOptionsDataExtended: [
			'Staff',
			'Visitor',
			// 'Contractor',
		],
		userAccessAbilitiesOptionsData: [
			'Actions: Can Create and Upload Documents',
			'Actions: Can View Responses',
			'Current Projects: Can View',
			'Current Projects: Can Create, Edit, and Convert',
			'Current Projects: Can Open Map Icon',
			'Completed Projects: Can View',
			'Completed Projects: Can Open Map Icon',
			'Dashboard Access',
			'Fire Marshall',
			'Fire Roll Call: Can View',
			'Fire Roll Call: Can Start',
			'First Aider',
			'New Opportunities: Can View',
			'New Opportunities: Can Create, Edit, and Convert',
			'New Opportunities: Can Open Map Icon',
			'Notice Board: Can Create, View, Edit, and Delete',
			'Offices: Can Create, View, Edit, and Delete',
			'Teamship: Can Respond',
			'Users: Can View, Edit, and Delete',
			'Users: Can Assign Access & Abilities',
			'Users: Can Approve/Reject',
			'Sign in from Desktop',
		],
		siteStatusOptionsData: [
			'Office',
			'New',
			'In Progress',
			'Completed',
		],
		actionsUserLevelsOptionsData: [
			'Staff-Admin',
			'Staff-Director',
			'Staff-Senior-Manager',
			'Staff-Project-Manager',
			'Staff-User-Office',
			'Staff-User-Site',
			'Staff-Operative-Groundworker',
			'Staff-Operative-Plant',
		],

		userCategoriesOptionsData: [
			{
				staffUserSite: [
					'Agents',
					'Commercial Site',
					'Foreman',
				],
				staffUserOffice: [
					'Administrative Staff',
					'Buyers',
					'Commercial Office',
					'H&S and Training',
					'IT',
					'Payroll',
					'Plant Management',
					'Receptionist',
					'Work Winning'
				],
				staffOperativeGroundworker: [
					'Gatemen',
					'Groundworker',
					'Labourers',
					'Plant Maintenance'
				],
				staffOperativePlant: [
					'HGV +Drivers',
					'Plant Operator'
				],
			}
		]
	}),

	methods: {
		...mapActions([
			'ACT_mobileNav',
			'ACT_breadcrumbs',
			'ACT_panelLayout',
			'ACT_openStaffDirectory',
			'ACT_openTrainingCourse',
			'ACT_openClients',
			'ACT_openSites',
			'ACT_openQuestion',
			'ACT_trainingCourses',
			'ACT_lookupValues',
			'ACT_sites',
		]),

		// * Set mobile menu
		MIX_mobileNav(payload) {
			this.ACT_mobileNav(payload);
		},

		// * PANEL LAYOUT
		MIX_setPanelLayout(leftPanel, rightPanel, show, title, dynamicComponent) {
			this.ACT_panelLayout({
				leftPanel: leftPanel,
				rightPanel: rightPanel,
				show: show,
				title: title,
				dynamicComponent: dynamicComponent
			});
		},

		MIX_breakpoint() {
			return (this.$vuetify.breakpoint.name);
		},

		MIX_itemsPerPage: function (breakpoint) {
			var result = 5;
			switch (breakpoint) {
				case 'xs':
					result = 1;
					break;
				case 'sm':
					result = 5;
					break;
				case 'md':
					result = 10;
					break;
				case 'lg':
					result = 10;
					break;
				case 'xl':
					result = 10;
					break;
				default:
					result = 5;
					break;
			}
			return result;
		},

		// * VIEW RELATED MIXINS
		MIX_closeView() {
			this.MIX_puDialog(false, '', '', '');
			this.MIX_fsDialog(false, ''); // Close Full Screen Dialog
			// this.ACT_panelLayout({ leftPanel: 12, rightPanel: 0, show: false, dynamicComponent: '' });
		},

		// * Capitalize JS String
		MIX_capitalizeText(text) {
			var capitalized = text.toLowerCase();
			capitalized = capitalized.charAt(0).toUpperCase() + capitalized.slice(1);
			return capitalized;
		},

		// * MIX BACK
		MIX_back() {
			this.$router.go(-1);
		},

		// * CHANGE ROUTE FUNCTION
		MIX_go(path) {
			this.$router.push(path).catch((err) => {
				console.log("Route error: " + err);
			});
		},

		// * FORMAT DATE TIME
		MIX_formatDateTime: function (date, formatIn, formatOut) {
			if ((date !== '') && (date !== null) && (date !== undefined)) {
				return moment(date, formatIn).format(formatOut);
			} else {
				return ''
			}
		},

		MIX_exportDocuments(headers, fileTitle, formatExport) {
			const items = formatExport

			if (headers && items[0] !== headers) {
				items.unshift(headers)
			}

			const jsonObject = JSON.stringify(items)

			// convert object to csv
			const array = typeof jsonObject != "object" ? JSON.parse(jsonObject) : jsonObject

			let str = ""

			for (let i = 0; i < array.length; i++) {
				let line = ""

				for (let index in array[i]) {
					if (line !== "") line += ","
					line += array[i][index];
				}

				str += line + "\r\n"
			}

			const csv = str
			const exportedFilename = fileTitle + ".csv" || "export.csv"
			const blob = new Blob([csv], {type: "text/csv;charset=utf-8;"})

			if (navigator.msSaveBlob) {
				navigator.msSaveBlob(blob, exportedFilename);
			} else {
				const link = document.createElement("a")

				if (link.download !== undefined) {
					const url = URL.createObjectURL(blob)
					link.setAttribute("href", url)
					link.setAttribute("download", exportedFilename)
					link.style.visibility = "hidden"
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				}
			}
		},

		// * sort by createdDateTime
		MIX_sortByCreatedDateTime(toSortObject) {
			var t = this;
			return toSortObject.sort(function (left, right) {
				return t.$moment(right.createdDateTime, "x").diff(t.$moment(left.createdDateTime, "x"))
			});
		},

		// MIXIN TO LOAD DATABASE DATA INTO LOOKUP TABLES STORE
		MIX_lookupTables: function () {
			var t = this;
			return new Promise(async function (resolve) {
				var lookupTablesResult = await t.MIX_readDocuments('lookupTables');
				if (lookupTablesResult.code === 1) {
					t.ACT_lookupTables(lookupTablesResult.data);
				} else {
					console.log('ERROR with lookupTablesResult \n' + JSON.stringify(lookupTablesResult, null, 2))
				}
				resolve();
			})
		},
		// MIXIN TO LOAD DATABASE DATA INTO LOOKUP VALUES STORE
		MIX_lookupValues: function () {
			var t = this;
			return new Promise(async function (resolve) {
				// GET LOOKUP VALUES
				var lookupValuesResult = await t.MIX_readDocuments('lookupValues');
				if (lookupValuesResult.code === 1) {
					t.ACT_lookupValues(lookupValuesResult.data);
				} else {
					console.log('ERROR with lookupValuesResult \n' + JSON.stringify(lookupValuesResult, null, 2))
				}
				resolve();
			})
		},

		// * Page Details
		MIX_breadcrumbs(breadcrumbs) {
			this.ACT_breadcrumbs(breadcrumbs);
		},

		// TO DELETE COMMENTS/LOCATIONS/CONCERNS FROM SUBARRAYS
		MIX_deleteCollectionArray(id, item, collection, parentCollection, parentId) {
			var name = '';
			switch (collection) {
				case 'comments':
					name = item.comment;
					break;
				case 'locations':
					name = item.locationName;
					break;
			}
			var title = 'Delete ' + name;
			var message = 'Are you sure you want to delete the item ' + name + '?';
			var actionDetails = {
				actionName: 'delete',
				collection: collection, // to add delete flag to doc from collection
				itemId: id,
				parentCollection: parentCollection, // to remove from the item id from the collection
				parentId: parentId,
			}

			this.MIX_showConfirmation(true, title, message, 290, "YES", "NO", actionDetails);
		},

		// SITES
		MIX_sites: function () {
			var t = this;
			return new Promise(async function (resolve) {
				var sitesResult = await t.MIX_readDocuments('sites');
				if (sitesResult.code === 1) {
					let sites = sitesResult.data.map((site) => {
						return {
							'siteId': site.id,
							'siteName': site.siteName,
							'siteType': site.siteType,
							'sitePostcode': site.sitePostcode
						}
					});
					t.ACT_sites(sites);
				} else {
					t.MIX_alert(sitesResult.code, sitesResult.message, sitesResult.data, sitesResult.error);
				}
				resolve();
			})
		},

		/**
		 * Generate ID
		 *
		 * Generate a 20 character string for use as a Firebase ID
		 *
		 * @returns {string} string to use as an ID
		 */
		MIX_generateId() {
			let generatedId = ''

			// Alphanumeric characters
			const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

			for (let i = 0; i < 20; i++) {
				generatedId += chars.charAt(Math.floor(Math.random() * chars.length))
			}

			return generatedId
		},

		/**
		 * From Now
		 *
		 * Takes a date, format and ago parameters and returns a string containing the amount of time since creating the concern.
		 *
		 * @param date - the creation date
		 * @param formatIn - the format of the creation date
		 * @param ago - boolean to toggle the 'ago' keyword from the returned string
		 * @returns {string} - the time since concern creation e.g. '1 hour'
		 */
		MIX_fromNow: function (date, formatIn, ago) {
			return moment(date, formatIn).fromNow(ago);
		},

		/**
		 * Render Confirmation Alert
		 *
		 * Render the confirmation alert as a coloured snackbar with either a success or failure message.
		 *
		 * @param document the document to evaluate
		 * @param successMessage the message to render on success
		 * @param failureMessage the message to render on failure
		 */
		MIX_renderConfirmationAlert(document, successMessage, failureMessage) {
			const t = this

			if (document.code === 1) {
				t.MIX_alert(1, `${successMessage}`, null, null)
			} else {
				t.MIX_alert(-1, `${failureMessage}`, null, null)
			}

		},

		/**
		 * Add Site Notice Board Entry
		 *
		 * Create a new notice board entry.
		 * The content is based on the status
		 *
		 * @param siteData the site data
		 * @param status the site status (to know which message to post)
		 */
		async MIX_addSiteNoticeBoardEntry(siteData, status) {
			const t = this
			let noticeDescription
			let noticeTitle

			if (status === 'New') {
				noticeTitle = 'New Work Winning Opportunity'
				noticeDescription = `We are pleased to announce a New Opportunity, ${siteData.projectTitle}`
			}

			if (status === 'In Progress') {
				noticeTitle = 'New Work Won'
				noticeDescription = `We are pleased to announce a New Current Project, ${siteData.projectTitle}`
			}

			if (status === 'Complete') {
				noticeTitle = 'Work Completed'
				noticeDescription = `We are pleased to announce we have completed another project, ${siteData.projectTitle}`
			}

			const noticeEntry = {
				id: t.MIX_generateId(),
				noticeDescription: noticeDescription,
				noticePriority: 'Default',
				noticeTitle: noticeTitle,
				noticeVisibility: 'Visible',
			}

			await t.MIX_createDocument('noticeBoards', noticeEntry)
		},

		// Validation --------------------------------------------------------------------------------------------------

		/**
		 * Is Email Valid
		 *
		 * Validates common email addresses.
		 *
		 * ^ start of string
		 * [a-zA-Z0-9.!#$%&'*+/=?^_{|}~-]+` : Match one or more of the characters within the square brackets (these are characters allowed in the local-part of email address before @)
		 * @ : the 'at' symbol
		 * [a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])? : Matches the domain name before the TLD ( Top-level domain)
		 * (?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)* : Matches the TLD and subdomains(if any)
		 * $ end of string
		 *
		 * @param {string} email The email to be validated
		 * @return {boolean} true if is valid, false otherwise
		 */
		MIX_isEmailValid(email) {
			const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

			return EMAIL_REGEX.test(email)
		},

		/**
		 * Is LatLng Valid
		 *
		 * Validates coordinates in a Latitude and Longitude decimal format.
		 *
		 * @param {string} coords The coordinates to be validated
		 * @return {{isLngValid: boolean, isLatValid: boolean}} true if is valid, false otherwise
		 */
		MIX_isLatLngValid(coords) {
			let isLatValid
			let isLngValid

			isLatValid = coords.latitude >= -90 && coords.latitude <= 90
			isLngValid = coords.longitude >= -180 && coords.longitude <= 180

			return {isLatValid, isLngValid}
		},

		/**
		 * Is Password Valid
		 *
		 * Validates a password.
		 *
		 * Must be at least 8 characters.
		 * Must contain at least 1 uppercase.
		 * Must contain at least 1 lowercase.
		 * Must contain at least 1 number.
		 * Must contain at least 1 special character from ? # @ ! £ $ % &
		 *
		 * @param {string} password The password to be validated
		 * @return {boolean} true if is valid, false otherwise
		 */
		MIX_isPasswordValid(password) {
			const PASSWORD_REGEX = /(?=.*?[A-Z])(?=.*[a-z])(?=.*?[0-9])(?=.*?[?#@!£$%&]).{8,}/

			return PASSWORD_REGEX.test(password)
		},

		/**
		 * Is Postcode Valid
		 *
		 * Validates a UK postcode.
		 *
		 * @param {string} postcode The postcode to be validated
		 * @return {boolean} true if is valid, false otherwise
		 */
		MIX_isPostcodeValid(postcode) {
			const POSTCODE_REGEX = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i

			return POSTCODE_REGEX.test(postcode)
		},

		/**
		 * Is Telephone Number Valid
		 *
		 * Validates a UK telephone.
		 *
		 * Number must start 01|02|03|07.
		 * Numbers starting 01|02|03 and be either 10 or 11 digits long.
		 * Numbers starting 07 must be 11 digits long.
		 *
		 * @param {string} telephoneNumber The telephone number to be validated
		 * @return {boolean} true if is valid, false otherwise
		 */
		MIX_isTelephoneNumberValid(telephoneNumber) {
			const PHONE_REGEX = /^(01|02|03|07)\d{8,9}$/

			return PHONE_REGEX.test(telephoneNumber)
		},

	}
}

export default {
	install(Vue, options) {
		Vue.mixin(mixin)
	}
}
