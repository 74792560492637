<template>
	<v-row no-gutters>

		<!--Page Loader-->
		<page-loading :is-page-loading="isPageLoading"/>

		<!--Page Content-->
		<v-row v-if="!computedIsPageLoading" no-gutters style="height: 100%">

			<!--Left Panel-->
			<v-col v-if="computedSplitScreenColumns.left"
				   :cols="computedSplitScreenColumns.left"
				   class="pa-4">

				<!--Title-->
				<page-title icon="icons8-handshake"
							page-title="Completed Projects"
							description="View contracts marked as completed."
							:divider="true"/>

				<!--Searches-->
				<v-row no-gutters>

					<!--References-->
					<v-col :class="$vuetify.breakpoint.width <= 600 ? '' : 'pr-2'"
						   :cols="$vuetify.breakpoint.width <= 600 ? 12 : 4">
						<app-input v-model.trim="searchByProjectReferenceNumber"
								   append-icon="icons8-search"
								   :clearable="true"
								   input-type="textInput"
								   label="Reference Number"/>
					</v-col>

					<!--Name-->
					<v-col :class="$vuetify.breakpoint.width <= 600 ? 'mt-4' : 'px-2'"
						   :cols="$vuetify.breakpoint.width <= 600 ? 12 : 4">
						<app-input v-model.trim="searchByProjectTitle"
								   append-icon="icons8-search"
								   :clearable="true"
								   input-type="textInput"
								   label="Project Title"/>
					</v-col>

					<!--Client-->
					<v-col :class="$vuetify.breakpoint.width <= 600 ? 'mt-4' : 'pl-2'"
						   :cols="$vuetify.breakpoint.width <= 600 ? 12 : 4">
						<app-input v-model.trim="searchByProjectClient"
								   append-icon="icons8-search"
								   :clearable="true"
								   input-type="textInput"
								   label="Client"/>
					</v-col>

				</v-row>

				<!--Table-->
				<v-data-table v-if="$vuetify.breakpoint.width >= 600"
							  :headers="computedTableHeaders"
							  :items="computedTableData"
							  class="rounded-lg my-4">

					<!--Id-->
					<template v-slot:item.id="{item}">
						<app-text category="text-small">{{ item.id }}</app-text>
					</template>

					<!--Contract Number-->
					<template v-slot:item.projectContractNumber="{item}">
						<app-text category="text-small">{{ item.projectContractNumber }}</app-text>
					</template>

					<!--Project Title-->
					<template v-slot:item.projectTitle="{item}">
						<app-text category="text-small">
							{{ item.projectTitle }}
							<span v-if="item.jobLostWon === 'Lost'" class="error--text"> (Lost)</span>
						</app-text>
					</template>

					<!--Client-->
					<template v-slot:item.projectClient="{item}">
						<app-text category="text-small">{{ item.projectClient }}</app-text>
					</template>

					<!--Value-->
					<template v-slot:item.projectValue="{item}">
						<app-text category="text-small">
							{{ new Intl.NumberFormat('en-GB', {
							maximumFractionDigits: 0,
							style: 'currency',
							currency: 'GBP'
						}).format(item.projectValue) }}
						</app-text>
					</template>

					<!--Contract Type-->
					<template v-slot:item.projectContractType="{item}">
						<app-text category="text-small">{{ item.projectContractType }}</app-text>
					</template>

					<!--Submission Date-->
					<template v-slot:item.projectSubmissionDate="{item}">
						<app-text category="text-small">
							{{ $moment(Number(item.projectSubmissionDate)).format('ddd Do  MMM \'YY') }}
						</app-text>
					</template>

					<!--Actions-->
					<template v-slot:item.actions="{item}">
						<v-btn v-if="['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel) || GET_currentUser.userAccessAndAbilities.includes('Completed Projects: Can Create, Edit, and Convert')"
							   class="primary--text white"
							   depressed @click="openRightPanel(item)">
							<v-icon class="icons8-forward pa-0" color="primary"/>
						</v-btn>
					</template>

				</v-data-table>

				<!--Mobile Cards-->
				<div v-if="$vuetify.breakpoint.width < 600">

					<!--Nothing to Show Message-->
					<app-text v-if="!externalSitesCollectionData.length" class="text-center mt-4">
						There is nothing to show
					</app-text>

					<!--Cards-->
					<v-card v-for="item in externalSitesCollectionData" :key="item.id"
							@click="openRightPanel(item)"
							class="rounded-lg mt-4 pa-4" flat>

						<app-text category="text-default-bold">{{ item.projectTitle }}</app-text>
						<div class="d-flex justify-space-between mt-4">
							<app-text category="text-small">{{ item.projectClient }}</app-text>
							<app-text category="text-small">{{ new Intl.NumberFormat('en-GB', {
								maximumFractionDigits: 0,
								style: 'currency',
								currency: 'GBP'
							}).format(item.projectValue) }}
							</app-text>
							<app-text category="text-small">{{ item.projectContractType }}</app-text>
						</div>

					</v-card>

				</div>

			</v-col>

			<!--Right panel-->
			<v-col v-if="isRightPanelOpen"
				   :class="computedSplitScreenColumns.left && 'borderLeft'"
				   :cols="computedSplitScreenColumns.right">

				<!--Action Bar-->
				<div class="d-flex align-center justify-space-between appbackground pa-4">

					<!--Title-->
					<app-text v-if="$vuetify.breakpoint.width >= 600" category="text-medium">
						{{
							selectedItem && selectedItem.projectTenderNumber ? selectedItem.projectTenderNumber : 'New'
						}}
					</app-text>

					<v-spacer/>

					<!--Action Buttons-->
					<div class="d-flex">

						<!--Edit Icon-->
						<!--<edit-icon :isActive="!isFormReadOnly" @click.native="editForm"/>-->

						<!--Delete Icon-->
						<!--<delete-icon-dialog :disabled="!isFormReadOnly"-->
						<!--					:item-to-delete="selectedItem && selectedItem.projectTitle"-->
						<!--					v-on:emitDeleteFromDialog="emittedDeleteFromDialog"/>-->

						<!--Close Icon-->
						<close-icon @click.native="closeRightPanel"/>

					</div>

				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs" show-arrows>

					<v-tabs-slider color="accent"/>

					<v-tab href="#overview">
						<app-text category="text-small">Overview</app-text>
					</v-tab>

				</v-tabs>

				<!--Tab Content-->
				<v-tabs-items v-model="tabs">

					<!--Overview Tab Content-->
					<v-tab-item class="appbackground pa-4" value="overview">

						<completed-site-form :doesFormStartReadOnly="isFormReadOnly"
											 :formData="selectedItem"
											 :usersData="usersCollectionData"
											 v-on:emitCloseRightPanel="emittedCloseRightPanel"/>

					</v-tab-item>

				</v-tabs-items>

			</v-col>

		</v-row>

	</v-row>
</template>

<script>
import CompletedSiteForm from "@/views/completedSites/completedSiteForm/completedSiteForm";

export default {

	name: "newSites",

	components: {CompletedSiteForm},

	data: () => ({
		isFormReadOnly: true,
		isRightPanelOpen: false,
		searchByProjectClient: '',
		searchByProjectReferenceNumber: '',
		searchByProjectTitle: '',
		selectedItem: {},
		tableHeaders: [
			{
				text: 'Id',
				value: 'id',
				sortable: false,
				align: 'left',
				hiddenByDefault: true,
				hideWithWidth: true
			},
			{
				text: 'Reference',
				value: 'projectContractNumber',
				sortable: false,
				align: 'center',
				width: '96px'
			},
			{
				text: 'Project Title',
				value: 'projectTitle',
				sortable: false,
				align: 'left',
				hiddenByDefault: false,
				hideWithWidth: false,
			},
			{
				text: 'Client',
				value: 'projectClient',
				sortable: false,
				align: 'left',
				hiddenByDefault: false,
				hideWithWidth: false,
			},
			{
				text: 'Value',
				value: 'projectValue',
				sortable: false,
				align: 'left',
				hiddenByDefault: false,
				hideWithWidth: false,
			},
			{
				text: 'Contract Type',
				value: 'projectContractType',
				sortable: false,
				align: 'left',
				hiddenByDefault: false,
				hideWithWidth: false,
			},
			{
				text: '',
				value: 'actions',
				sortable: false,
				align: 'right',
				hiddenByDefault: false,
				hideWithWidth: false,
				width: '96px',
			},
		],
		tabs: '',

		// Collection Data
		usersCollectionData: [],
		isUsersCollectionDataLoaded: false,
		externalSitesCollectionData: [],
		isExternalSitesCollectionDataLoaded: false,
		isPageLoading: true,
	}),

	computed: {

		/**
		 * Computed Is Page Loading
		 *
		 * Return a boolean for the page loading spinner to denote if all data has been loaded.
		 *
		 * @returns {boolean} if data has been loaded or not
		 */
		computedIsPageLoading() {
			const t = this

			t.isPageLoading = !(
				t.isUsersCollectionDataLoaded &&
				t.isExternalSitesCollectionDataLoaded
			)

			return t.isPageLoading
		},

		/**
		 * Computed Split Screen Columns
		 *
		 * Calculate the width of the left snd right panels, based on the currently selected functionality.
		 * The left panel has the table, the right panel has the forms.
		 *
		 * @returns {{left: number, right: number}}
		 */
		computedSplitScreenColumns() {
			const t = this
			let left = 12
			let right = 0

			if (t.$vuetify.breakpoint.width >= 1200) {
				if (t.isRightPanelOpen) {
					left = 6
					right = 6
				}
			} else {
				if (t.isRightPanelOpen) {
					left = 0
					right = 12
				}
			}

			return {left, right}
		},

		/**
		 * Computed Table Headers
		 *
		 * Using the tableHeaders data, hide the fields that aren't required.
		 *
		 * @returns {Array} an array of table header objects
		 */
		computedTableHeaders() {
			const t = this
			let tableHeaders = t.tableHeaders

			tableHeaders = tableHeaders.filter(header => !header.hiddenByDefault)

			if (t.computedSplitScreenColumns.left < 12) {
				tableHeaders = tableHeaders.filter(header => !header.hideWithWidth)
			}

			return tableHeaders
		},

		/**
		 * Computed Table Data
		 *
		 * Return a sorted array of JSON objects.
		 *
		 * @returns {Array} Array of processed JSON data
		 */
		computedTableData() {
			const t = this
			let sitesData = t.externalSitesCollectionData

			// Search By Project Tender Number
			if (t.searchByProjectReferenceNumber) {
				sitesData = sitesData.filter(data =>
					data.projectTenderNumber.toUpperCase().match(t.searchByProjectReferenceNumber.toLocaleUpperCase()) ||
					data.projectContractNumber.toUpperCase().match(t.searchByProjectReferenceNumber.toLocaleUpperCase())
				)
			}

			// Search by Client
			if (t.searchByProjectClient) {
				sitesData = sitesData.filter(data =>
					data.projectClient.toUpperCase().match(t.searchByProjectClient.toLocaleUpperCase()))
			}

			// Search by Project Title
			if (t.searchByProjectTitle) {
				sitesData = sitesData.filter(data =>
					data.projectTitle.toUpperCase().match(t.searchByProjectTitle.toLocaleUpperCase()))
			}

			// Sort by submission date
			sitesData = sitesData.sort((a, b) => {
				return a.projectContractNumber < b.projectContractNumber ? 1 : -1
			})

			return sitesData
		},

	},

	methods: {

		/**
		 * Get Collection Data
		 *
		 * Fetch the data from the collection and assign them to the class state.
		 *
		 * @returns {Promise<void>}
		 */
		async getUsersCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('users')
				.orderBy('userName')
				.onSnapshot(snapshot => {

					// Clear the array to avoid duplications
					collectionData = []

					snapshot.forEach(doc => {

						const DOCUMENT = doc.data()
						DOCUMENT.id = doc.id

						// Only add documents that:
						//  - Aren't marked as deleted
						//  - Are Approved
						//  - Have NOT signed up with 2FA
						//  - Have signed up with, and have passed 2FA
						if (!DOCUMENT.hasOwnProperty('delete') &&
							DOCUMENT.userStatus === 'APPROVED' &&
							(!DOCUMENT.userShouldUse2fa || (DOCUMENT.userHasPassed2fa))
						) {
							collectionData.push(DOCUMENT)
						}

					})

					collectionData = collectionData.sort((a, b) => a.userName > b.userName ? 1 : -1)

					// Assign the documents in one and set isLoaded to true
					t.usersCollectionData = collectionData
					t.isUsersCollectionDataLoaded = true
				})
		},

		/**
		 * Get Collection Data
		 *
		 * Fetch the data from the collection and assign them to the class state.
		 *
		 * @returns {Promise<void>}
		 */
		async getExternalSitesCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('externalSites')
				.where('siteStatus', '==', 'Complete')
				.onSnapshot(snapshot => {

					// Clear the array to avoid duplications
					collectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only push active documents
						if (!document.hasOwnProperty('delete')) collectionData.push(document)

					})

					// Assign the documents in one and set isLoaded to true
					t.externalSitesCollectionData = collectionData
					t.isExternalSitesCollectionDataLoaded = true
				})
		},

		/**
		 * Close Right Panel
		 *
		 * When closing the form, reset it to ensure it opens as new.
		 */
		closeRightPanel() {
			const t = this

			t.isFormReadOnly = true
			t.isRightPanelOpen = false
		},

		/**
		 * Edit Form
		 *
		 * Toggle the read only state of the form.
		 */
		editForm() {
			const t = this

			t.isFormReadOnly = !t.isFormReadOnly;
		},

		/**
		 * Emitted Close Right Panel
		 *
		 * Take the emitted message and call to close the right panel.
		 *
		 * @returns {Promise<void>}
		 */
		async emittedCloseRightPanel() {
			const t = this

			t.closeRightPanel()
		},

		/**
		 * Emitted Delete From Dialog
		 *
		 * Take the emitted message from the 'deleteIconDialog', and mark the selected document as deleted.
		 *
		 * @returns {Promise<void>}
		 */
		async emittedDeleteFromDialog() {
			const t = this

			const deleteDocument = await t.MIX_deleteDocument('externalSites', t.selectedItem.id)

			t.closeRightPanel()

			t.MIX_renderConfirmationAlert(
				deleteDocument,
				'Successfully Deleted Site',
				'Error Deleting Site')
		},

		/**
		 * Open Right Panel
		 *
		 * Open the CRUD form.
		 * If the form is opened using the new button, it will be blank.
		 * If the form is opened from the table, it will be assigned here and populated.
		 *
		 * @param item JSON containing the selected data, if required
		 */
		openRightPanel(item) {
			const t = this

			if (!(['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(t.GET_currentUser.userLevel) || t.GET_currentUser.userAccessAndAbilities.includes('Completed Projects: Can Create, Edit, and Convert'))) return

			if (!item?.id) t.isFormReadOnly = false
			t.selectedItem = item

			t.isRightPanelOpen = true
		},

	},

	async mounted() {
		const t = this

		await t.getUsersCollectionData()
		await t.getExternalSitesCollectionData()
	}

}
</script>

<style scoped>
.borderLeft {
	border-left: 4px solid #999999;
	min-height: calc(100vh - 128px);
}
</style>
