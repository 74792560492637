<template>
	<v-card>

		<!--Toolbar-->
		<v-toolbar class="pl-2" color="primary">

			<!--Title-->
			<app-text category="text-medium" class="white--text">{{ point.projectContractNumber }}</app-text>

			<v-spacer/>

			<close-icon @click.native="closeDialog"/>

		</v-toolbar>

		<!--Content-->
		<div class="d-flex flex-column justify-center text-center pa-4">

			<!--ProjectTitle | Client-->
			<div class="mpd-2row-container">

				<!--Project Title-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Project Title</app-text>
					<app-text>{{ point.projectTitle }}</app-text>
				</div>

				<div style="border-left: 1px solid lightgrey"/>

				<!--Completion Date-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Completion Date</app-text>
					<app-text>{{ renderDate(point.projectCompletionDate) }}</app-text>
				</div>

			</div>

		</div>

	</v-card>
</template>

<script>
export default {

	name: "mapCompletedSitePointDialog",

	props: ['point'],

	data: () => ({}),

	methods: {

		closeDialog() {
			const t = this

			t.$emit('emitCloseMapPointDialog')
		},

		renderDate(date) {
			const t = this

			return t.$vuetify.breakpoint.width < 512
				? t.$moment(Number(date)).format('Do MMM \'YY')
				: t.$moment(Number(date)).format('ddd Do MMM \'YY')
		}

	}

}
</script>

<style scoped>
.mpd-2row-container {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
}

.mpd-3row-container {
	display: grid;
	grid-template-columns: 1fr auto 1fr auto 1fr;

	margin-top: 32px;
}
</style>
