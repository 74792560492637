<!--

Form Meta Data

Display the created and modified by user and date using the form data props.
-->
<template>
	<div v-if="formData.id">

		<v-divider/>

		<!--Created By Name|Date-->
		<app-text category="text-small" class="grey--text mt-2">
			Created by: <span class="font-weight-bold">{{ formData.createdUserData.userName }}</span>
			-
			<span>{{ $moment(Number(formData.createdDateTime)).format('ddd do MMM \'YY @ HH:mm') }}</span>
		</app-text>

		<!--Modified By Name|Date-->
		<app-text category="text-small" class="grey--text">
			Modified by: <span class="font-weight-bold">{{ formData.modifiedUserData.userName }}</span>
			-
			<span>{{ $moment(Number(formData.modifiedDateTime)).format('ddd do MMM \'YY @ HH:mm') }}</span>
		</app-text>

	</div>
</template>

<script>
export default {

	name: "formMetaData",

	props: ['formData'],

}
</script>

<style scoped>

</style>
