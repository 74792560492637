<template>
    <div :class="horizontal ? 'weatherCards-container-horizontal' : 'weatherCards-container'" style="z-index: 1">

        <!--Weather cards for the next seven days-->
        <div v-for="item in computedWeatherData" :key="item.id"
             class="weatherCards-card-container-outer">

            <!--Card Container-->
            <div class="weatherCards-card-container-inner">

                <!--Day | Date-->
                <div class="d-flex justify-space-between">
                    <app-text category="text-small" class="text-center d-flex justify-center">
                        {{ $moment(item.dt, 'X').format('ddd') }}
                    </app-text>
                    <app-text category="text-small" class="text-center d-flex justify-center">
                        {{ $moment(item.dt, 'X').format('Do') }}
                    </app-text>
                </div>

                <!--Weather Icon-->
                <v-icon :class="item.weatherIcon" :size="computedWeatherIconSize"/>

                <!--Min  Temp | Max Temp-->
                <div class="d-flex">
                    <app-text category="text-small"
                              class="text-center d-flex justify-center pr-1"
                              style="border-right: 1px solid lightgrey; width: 50%;">
                        {{ item.temp.min.toFixed(0) }}°C
                    </app-text>
                    <app-text category="text-small"
                              class="text-center d-flex justify-center pl-2"
                              style="border-left: 1px solid lightgrey; width: 50%">
                        {{ item.temp.max.toFixed(0) }}°C
                    </app-text>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
export default {

    name: "weatherCards",

    props: ['horizontal', 'siteId', 'weatherData'],

    computed: {

        /**
         * Computed Icon Size
         *
         * Change the size of the icon based on the screen height.
         *
         * @returns {number}
         */
        computedWeatherIconSize() {
            const t = this
            let iconSize

            // !!! This is not science, it just an approximation to resize the icon
            // Screen height - approximate amount of vertical margin and padding / number of cards
            iconSize = (t.$vuetify.breakpoint.height - 528) / 7
            if (iconSize > 48) iconSize = 48

            return iconSize
        },

        /**
         * Computed Weather Data
         *
         * Process the weather and return an array of daily objects with an icon.
         * The weather is based on the siteId prop.
         *
         * @returns {Array} array of daily objects
         */
        computedWeatherData() {
            const t = this
            let weatherData = t.$props.weatherData
            let forecast = []

            // Safeguard if there is no site
            if (!t.$props.siteId) return []

            // Get the weather data for the current site
            weatherData = weatherData.filter(wd => wd.siteId === t.$props.siteId)

            // Ge the weather forecast for the next seven days
            weatherData = weatherData[0]?.weatherData.daily?.slice(0, 7)

            // Loop over the daily weather objects and add an icon
            weatherData.forEach(day => {
                forecast.push({
                    ...day,
                    weatherIcon: t.getWeatherIconById(day.weather[0].id)
                })
            })

            return forecast
        },

    },

    methods: {

        /**
         * Get Weather Icon By ID
         *
         * Take the weather icon id, and return an icon name to display.
         *
         * @param id - the weather icon id
         * @return String - the icon name to display
         */
        getWeatherIconById(id) {
            let icon;

            switch (true) {
                case id >= 200 && id < 300:
                    icon = 'icons8-storm';
                    break;
                case id >= 300 && id < 400:
                    icon = 'icons8-light-rain';
                    break;
                case id >= 500 && id < 504:
                    icon = 'icons8-rain';
                    break;
                case id === 511:
                    icon = 'icons8-sleet';
                    break;
                case id >= 511 && id < 531:
                    icon = 'icons8-rain-cloud';
                    break;
                case id >= 600 && id < 700:
                    icon = 'icons8-snow';
                    break;
                case id >= 701 && id < 800:
                    icon = 'icons8-haze';
                    break;
                case id === 800:
                    icon = 'icons8-sun';
                    break;
                case id === 801:
                    icon = 'icons8-partly-cloudy-day';
                    break;
                case id === 802:
                    icon = 'icons8-partly-cloudy-day';
                    break;
                case id === 803:
                    icon = 'icons8-cloud';
                    break;
                case id === 804:
                    icon = 'icons8-cloud';
                    break;

                default:
                    console.log('weather icon id  not recognised: ', id)
            }

            return icon
        },

    },

}
</script>

<style scoped>
.weatherCards-container {
    display: grid;
    grid-template-rows: repeat(7, 1fr);
    grid-row-gap: 16px;
}

.weatherCards-container-horizontal {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 16px;
}

.weatherCards-card-container-outer {
    display: flex;
    align-items: center;
    justify-content: center;

    background: white;
    border-radius: 8px;
    box-shadow: 0 0 8px grey;
}

.weatherCards-card-container-inner {
    display: grid;
    grid-template-rows: 24px auto 24px;
    align-items: center;
    justify-content: center;

    padding: 0 8px;
    width: 88px;
}

</style>
