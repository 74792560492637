<!--
Home Profile Picture Upload

- Card for the Home page to prompt the User to upload a profile picture, if they haven't already.
-->
<template>
    <v-card v-if="!hasUploaded" class="rounded-lg mb-4 pa-4" flat style="width: 100%">

        <!--Icon | Title-->
        <div class="d-flex align-center">

            <v-icon class="icons8-person mr-4" color="accent" size="48"/>

            <app-text category="text-medium-bold" class="primary--text">Profile Picture</app-text>

        </div>

        <!--Instructional Text-->
        <div class="d-flex align-center mt-4">
            <app-text>Please upload a profile picture</app-text>
        </div>

        <!--Upload Button-->
        <photoupload :block="true"
                     class="mt-4"
                     allowed-types="image/*"
                     :doc-link="{collection: 'users', documentId: GET_currentUser.id}"
                     folder="users-profile-pictures"/>

    </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {

    name: "homeProfilePictureUpload",

    data: () => ({
        hasUploaded: false,
    }),

    computed: {
        ...mapGetters({
            GET_currentUser: 'GET_currentUser',
            GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult',
        }),
    },

    watch: {

        /**
         * Photo Upload Result
         *
         * Watch for the result of an image upload.
         * If successful, update the current User's document with the image path.
         */
        GET_photoUploadResult: {
            async handler() {
                const t = this

                if (t.GET_photoUploadResult !== {}) {

                    const updatePhotoResult = await t.MIX_updateDocumentFieldsById(
                        'users',
                        t.GET_photoUploadResult.docLink,
                        {profilePicFileURL: t.GET_photoUploadResult.fileURL}
                    )

                    // Render confirmation box
                    if (updatePhotoResult.code === 1) {
                        t.hasUploaded = true
                        t.MIX_alert(1, 'User successfully updated', updatePhotoResult.data, null)
                    } else {
                        t.MIX_alert(-1, 'Error updating user', null, updatePhotoResult.error)
                    }

                }
            }
        }

    },

}
</script>
