<template>
    <v-card class="rounded-lg pa-4" flat height="100%">

        <!--Icon | Label-->
        <div class="d-flex align-center">
            <v-icon class="icons8-task-completed mr-4" color="primary" size="48"/>
            <app-text category="text-medium">Notice Board</app-text>
        </div>

        <v-divider class="mt-4"/>

        <app-text v-if="!noticeData.length" class="mt-4">There are no messages to show</app-text>

        <div v-for="notice in computedSortedNoticeData" :key="notice.id" class="mt-4">

            <div class="d-flex align-center">

                <!--Priority-->
                <v-icon v-if="notice.noticePriority === 'Default'"
                        class="icons8-filled-circle mr-2" color="success" size="16"/>
                <v-icon v-if="notice.noticePriority === 'Medium'"
                        class="icons8-filled-circle mr-2" color="warning" size="16"/>
                <v-icon v-if="notice.noticePriority === 'High'"
                        class="icons8-filled-circle mr-2" color="error" size="16"/>

                <!--Title-->
                <app-text category="text-default-bold">{{ notice.noticeTitle }}</app-text>

                <v-spacer/>

                <!--Date Posted-->
                <app-text category="text-small" class="grey--text ml-4">
                    {{ $moment(Number(notice.modifiedDateTime)).format('D/M/YY') }}
                </app-text>

            </div>

            <app-text>{{ notice.noticeDescription }}</app-text>

            <v-divider class="mt-4"/>

        </div>

    </v-card>
</template>

<script>
export default {

    name: "noticeBoardsHomePageCard",

    props: ['noticeData'],

    computed: {

        computedSortedNoticeData() {
            const t = this
            const noticeData = t.$props.noticeData

            noticeData.sort((a, b) => {
                return a.modifiedDateTime < b.modifiedDateTime ? 1 : -1
            })

            noticeData.sort((a, b) => {
                if (a.noticePriority === 'High' && b.noticePriority !== 'High')
                    return -1
                if (a.noticePriority === 'Medium' && b.noticePriority === 'Default')
                    return -1
            })

            return noticeData
        }

    },

}

</script>

<style scoped>

</style>
