<template>
    <v-expansion-panels class="mb-4 pa-0" flat>

        <v-expansion-panel class="rounded-lg">

            <!--Header-->
            <v-expansion-panel-header class="px-4">

                <app-text category="text-medium-bold" class="primary--text">Getting Started</app-text>

            </v-expansion-panel-header>

            <!--Content-->
            <v-expansion-panel-content class="expansion-panel-content-container">

                <!--Greeting-->
                <app-text>
                    Welcome to the Walters Information Network (WIN).
                </app-text>

                <!--Instructions-->
                <app-text class="mt-4">
                    To begin, SWAPP In using the button below, and don't forget to SWAPP Out when you leave.
                </app-text>

                <!--Instructions-->
                <app-text category="text-default-bold" class="mt-4">
                    You must log in via mobile to be able to SWAPP in and out
                </app-text>

            </v-expansion-panel-content>

        </v-expansion-panel>

    </v-expansion-panels>
</template>

<script>
export default {

    name: "gettingStartedHomePageCard",

}
</script>
