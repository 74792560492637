<template>
	<v-form>

		{{ computedInit }}

		<!--Form Fields-->
		<v-row no-gutters>

			<form-section-title class="mt-4" title="Project"/>

			<!--Project Title-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 12"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'">
				<app-input input-type="textInput"
						   :error="errors.projectTitle"
						   :error-messages="errors.projectTitleErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Project Title"
						   v-model="form.projectTitle"/>
			</v-col>

			<!--Client-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 12"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'">
				<app-input input-type="textInput"
						   :error="errors.projectClient"
						   :error-messages="errors.projectClientErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Client"
						   v-model.trim="form.projectClient"/>
			</v-col>

			<!--Contract Type-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pr-2' : 'mt-4 pr-2'">
				<app-input input-type="select"
						   :error="errors.projectContractType"
						   :error-messages="errors.projectContractTypeErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   :items="projectContractTypeOptions"
						   label="Contract Type"
						   v-model="form.projectContractType"/>
			</v-col>

			<!--Project Value-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pl-2' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :error="errors.projectValue"
						   :error-messages="errors.projectValueErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Value"
						   type="number"
						   v-model.number="form.projectValue"/>
			</v-col>

			<!--Region-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pr-2' : 'mt-4 pr-2'">
				<app-input input-type="select"
						   :clearable="true"
						   :is-form-read-only="computedIsFormReadOnly"
						   :items="projectRegionOptions"
						   label="Region"
						   v-model="form.projectRegion"/>
			</v-col>

			<!--Contract Award-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pl-2' : 'mt-4 pl-2'">
				<date-picker :date="form.projectContractAward"
							 :error="errors.projectContractAward"
							 :error-messages="errors.projectContractAwardErrorMessage"
							 :is-form-read-only="computedIsFormReadOnly"
							 label="Contract Award"
							 :minDate="[5, 'years', 'past']"
							 :maxDate="[1, 'years', 'future']"
							 v-on:emitDate="handleDatePicker($event, 'projectContractAward')"/>
			</v-col>

			<!--Start Date-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pr-2' : 'mt-4 pr-2'">
				<date-picker :date="form.projectStartDate"
							 :error="errors.projectStartDate"
							 :error-messages="errors.projectStartDateErrorMessage"
							 :is-form-read-only="computedIsFormReadOnly"
							 label="StartDate"
							 :minDate="[5, 'years', 'past']"
							 :maxDate="[1, 'years', 'future']"
							 v-on:emitDate="handleDatePicker($event, 'projectStartDate')"/>
			</v-col>

			<!--Commencement Date-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pl-2' : 'mt-4 pl-2'">
				<date-picker @emitDate="handleDatePicker($event, 'projectCommencementDate')"
							 :clearable="true"
							 :date="form.projectCommencementDate"
							 :error="errors.projectCommencementDate"
							 :error-messages="errors.projectCommencementDateErrorMessage"
							 :is-form-read-only="computedIsFormReadOnly"
							 label="Commencement Date"
							 :minDate="[5, 'years', 'past']"
							 :maxDate="[1, 'years', 'future']"/>
			</v-col>

			<!--Contract Period - start date-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pr-2' : 'mt-4 pr-2'">
				<date-picker @emitDate="handleDatePicker($event, 'projectContractPeriodStartDate')"
							 :clearable="true"
							 :date="form.projectContractPeriodStartDate"
							 :error="errors.projectContractPeriodStartDate"
							 :error-messages="errors.projectContractPeriodStartDateErrorMessage"
							 :is-form-read-only="computedIsFormReadOnly"
							 label="Contract Period (start)"
							 :minDate="[5, 'years', 'past']"
							 :maxDate="[1, 'years', 'future']"/>
			</v-col>

			<!--Contract Period - end date-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pl-2' : 'mt-4 pl-2'">
				<date-picker @emitDate="handleDatePicker($event, 'projectContractPeriodEndDate')"
							 :clearable="true"
							 :date="form.projectContractPeriodEndDate"
							 :error="errors.projectContractPeriodEndDate"
							 :error-messages="errors.projectContractPeriodEndDateErrorMessage"
							 :is-form-read-only="computedIsFormReadOnly"
							 label="Contract Period (end)"
							 :minDate="[5, 'years', 'past']"
							 :maxDate="[5, 'years', 'future']"/>
			</v-col>

			<!--Project Description-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 12"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'">
				<app-input input-type="textArea"
						   counter="360"
						   :error="errors.projectDescription"
						   :error-messages="errors.projectDescriptionErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Project Description"
						   max-length="360"
						   v-model="form.projectDescription"/>
			</v-col>

			<form-section-title class="mt-4" title="Team"/>

			<!--Contracts Manager-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="select"
						   :error="errors.projectContractsManager"
						   :error-messages="errors.projectContractsManagerErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   :items="computedUserOptions"
						   item-text="userName"
						   label="Contracts Manager"
						   :return-object="true"
						   v-model="contractsManagerUserData"/>
			</v-col>

			<!--Project Manager/Agent-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="select"
						   :error="errors.projectManagerAgent"
						   :error-messages="errors.projectManagerAgentErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   :items="computedUserOptions"
						   item-text="userName"
						   label="Project Manager/Agent"
						   :return-object="true"
						   v-model="projectManagerAgentUserData"/>
			</v-col>

			<!--QS-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="select"
						   :error="errors.projectQS"
						   :error-messages="errors.projectQSErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   :items="computedUserOptions"
						   item-text="userName"
						   label="QS"
						   :return-object="true"
						   v-model="qsUserData"/>
			</v-col>

			<!--Foreman-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="select"
						   :error="errors.projectForeman"
						   :error-messages="errors.projectForemanErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   :items="computedUserOptions"
						   item-text="userName"
						   label="Foreman"
						   :return-object="true"
						   v-model="foremanUserData"/>
			</v-col>

			<form-section-title class="mt-8" title="Address"/>

			<!--Project Location Line 1-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :error="errors.projectLocationLine1"
						   :error-messages="errors.projectLocationLine1ErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Address Line 1"
						   v-model="form.projectLocationLine1"/>
			</v-col>

			<!--Project Location Line 2-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :error="errors.projectLocationLine2"
						   :error-messages="errors.projectLocationLine2ErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Address Line 2 (optional)"
						   v-model="form.projectLocationLine2"/>
			</v-col>

			<!--Project Location Town-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :error="errors.projectLocationTown"
						   :error-messages="errors.projectLocationTownErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Town"
						   v-model="form.projectLocationTown"/>
			</v-col>

			<!--Project Location City-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :error="errors.projectLocationCity"
						   :error-messages="errors.projectLocationCityErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="City"
						   v-model="form.projectLocationCity"/>
			</v-col>

			<!--Project Location County-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :error="errors.projectLocationCounty"
						   :error-messages="errors.projectLocationCountyErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="County"
						   v-model="form.projectLocationCounty"/>
			</v-col>

			<!--Project Location Postcode-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :error="errors.projectLocationPostcode"
						   :error-messages="errors.projectLocationPostcodeErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Postcode"
						   v-model="form.projectLocationPostcode"/>
			</v-col>

			<!--Project Location Latitude-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pr-2' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :error="errors.projectLocationLatitude"
						   :error-messages="errors.projectLocationLatitudeErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Latitude Coordinates"
						   v-model="form.projectLocationLatitude"/>
			</v-col>

			<!--Project Location Longitude-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pl-2' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :error="errors.projectLocationLongitude"
						   :error-messages="errors.projectLocationLongitudeErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Longitude Coordinates"
						   v-model="form.projectLocationLongitude"/>
			</v-col>

		</v-row>

		<!--Save button-->
		<div v-if="!computedIsFormReadOnly" class="d-flex justify-end mt-4">
			<app-btn @click.native="saveDocument"
					 color="success"
					 icon="icons8-save"
					 label="Save"/>
		</div>

	</v-form>
</template>

<script>
import DatePicker from "@/components/commonComponents/datePicker.vue";

export default {

	name: "sitesInProgressForm",

	components: {DatePicker},

	props: ['doesFormStartReadOnly', 'formData', 'isConvertingSite', 'usersData'],

	data: () => ({
		errors: {
			projectClient: false,
			projectClientErrorMessage: '',
			projectContractType: false,
			projectContractTypeErrorMessage: '',
			projectDescription: false,
			projectDescriptionErrorMessage: '',
			projectLocationLine1: false,
			projectLocationLine1ErrorMessage: '',
			projectLocationLine2: false,
			projectLocationLine2ErrorMessage: '',
			projectLocationTown: false,
			projectLocationTownErrorMessage: '',
			projectLocationCity: false,
			projectLocationCityErrorMessage: '',
			projectLocationCounty: false,
			projectLocationCountyErrorMessage: '',
			projectLocationPostcode: false,
			projectLocationPostcodeErrorMessage: '',
			projectLocationLatitude: false,
			projectLocationLatitudeErrorMessage: '',
			projectLocationLongitude: false,
			projectLocationLongitudeErrorMessage: '',
			projectSubmissionDate: false,
			projectSubmissionDateErrorMessage: '',
			projectTitle: false,
			projectTitleErrorMessage: '',
			projectTenderNumber: false,
			projectTenderNumberErrorMessage: '',
			projectValue: false,
			projectValueErrorMessage: '',

			projectContractAward: false,
			projectContractAwardErrorMessage: '',
			projectStartDate: false,
			projectStartDateErrorMessage: '',
			projectContractsManager: false,
			projectContractsManagerErrorMessage: '',
			projectManagerAgent: false,
			projectManagerAgentErrorMessage: '',
			projectQS: false,
			projectQSErrorMessage: '',
			projectForeman: false,
			projectForemanErrorMessage: '',
		},
		form: {
			id: '',
			projectBidLead: '',
			projectClient: '',
			projectClientAdvertisedValue: 0,
			projectContractAward: '',
			projectCompletionDate: '',
			projectContractNumber: '',
			projectContractType: '',
			projectContractsManager: '',
			projectDescription: '',
			projectEstimator: '',
			projectEvaluationScoring: '',
			projectForeman: '',
			projectFramework: '',
			projectLocationLine1: '',
			projectLocationLine2: '',
			projectLocationTown: '',
			projectLocationCity: '',
			projectLocationCounty: '',
			projectLocationPostcode: '',
			projectLocationLatitude: '',
			projectLocationLongitude: '',
			projectManagerAgent: '',
			projectOperationalLead: '',
			projectQS: '',
			projectRegion: '',
			projectStartDate: '',
			projectSubmissionDate: '',
			projectTenderNumber: '',
			projectTitle: '',
			projectValue: 0,
			projectArchivedAndCertified: false,
			projectArchivedAndCertifiedNotes: '',
			projectAcceptedIntoAdoption: false,
			projectAcceptedIntoAdoptionNotes: '',
			projectLeftDefectFree: false,
			projectLeftDefectFreeNotes: '',
			projectRetentionCertifiedOrReleased: false,
			projectRetentionCertifiedOrReleasedNotes: '',
			projectHsFileSubmittedAndAccepted: false,
			projectHsFileSubmittedAndAcceptedNotes: '',
			projectClosePEP: false,
			projectClosePEPNotes: '',

			projectCommencementDate: '',
			projectContractPeriodStartDate: '',
			projectContractPeriodEndDate: '',
			siteName: '',

			siteStatus: 'In Progress',
			jobLostWon: '',
			locations: [],
		},
		isFormReadOnly: false,
		projectContractTypeOptions: [
			'NEC3 Option A',
			'NEC3 Option B',
			'NEC3 Option C',
			'NEC3 Option D',
			'NEC3 Option E',
			'NEC3 Short Form',
			'NEC4 Option A',
			'NEC4 Option B',
			'NEC4 Option C',
			'NEC4 Option D',
			'NEC4 Option E',
			'NEC4 Short Form',
			'PSC',
			'JCT',
			'Bespoke',
			'Other',
		],
		projectRegionOptions: [
			'South Wales',
			'South West',
			'Major Projects',
		],

		contractsManagerUserData: {},
		projectManagerAgentUserData: {},
		qsUserData: {},
		foremanUserData: {},
	}),

	computed: {

		/**
		 * Computed From Data
		 *
		 * If this is an existing document, set the form data.
		 * If it's not, the default form will be used.
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props.formData
			const USERS_DATA = t.$props.usersData

			if (FORM_DATA?.id) {
				t.form = FORM_DATA

				// Create user Objects from their ID (objects are used, IDs are saved)
				t.contractsManagerUserData = USERS_DATA.find(user => user.id === t.form.projectContractsManager) || {}
				t.projectManagerAgentUserData = USERS_DATA.find(user => user.id === t.form.projectManagerAgent) || {}
				t.qsUserData = USERS_DATA.find(user => user.id === t.form.projectQS) || {}
				t.foremanUserData = USERS_DATA.find(user => user.id === t.form.projectForeman) || {}
			}
		},

		/**
		 * Computed Is Form Read Only
		 *
		 * When the form is first created in the Supervisor's Control Panel,
		 * the form should be editable.
		 * If the form is open through the Reporting pages, it should open uneditable.
		 *
		 * @returns {boolean} is form read only
		 */
		computedIsFormReadOnly() {
			const t = this

			return t.$props.doesFormStartReadOnly
				? t.$props.doesFormStartReadOnly
				: t.isFormReadOnly
		},

		computedUserOptions() {
			const t = this
			let users = t.usersData

			users = users.sort((a,  b) => {
				return a.userName > b.userName ? 1 : -1
			})

			users = users.filter(user =>
				!['Staff-Operative-Groundworker', 'Staff-Operative-Plant', 'Visitor'].includes(user.userLevel))

			return users
		}

	},

	methods: {

		/**
		 * Clear Errors and Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}
		},

		/**
		 * Handle Date Picker
		 *
		 * Takes the emitted payload as $event data from the input field, and the field name,
		 * and sets the date to the specified form field.
		 *
		 * @param date the date to set
		 * @param field the form field to set
		 */
		handleDatePicker(date, field) {
			const t = this

			// Convert to Unix timestamp
			date = t.$moment(date).format('x')

			// If the date is invalid, set it to an empty string
			// This is probably from the user clearing the date picker
			if (date === 'Invalid date') date = ''

			// Set the date to the current form field
			t.form[field] = date
		},

		/**
		 * Save Document
		 *
		 * Create/update the document.
		 *
		 * @returns {Promise<void>}
		 */
		async saveDocument() {
			const t = this
			let documentResult

			// Get User ID from Object (objects are used, IDs are saved)
			t.form.projectContractsManager = t.$props.usersData.find(user => user.id === t.contractsManagerUserData.id)?.id
			t.form.projectManagerAgent = t.$props.usersData.find(user => user.id === t.projectManagerAgentUserData.id)?.id
			t.form.projectQS = t.$props.usersData.find(user => user.id === t.qsUserData.id)?.id
			t.form.projectForeman = t.$props.usersData.find(user => user.id === t.foremanUserData.id)?.id

			// Set Site Name - This is so logic around the app can reference a single 'siteName' instead of differing fields for Sites (Office) and External Sites (Project)
			t.form.siteName = t.form.projectTitle

			if (!t.validateForm()) return

			t.form.siteStatus = 'In Progress'

			// Create
			if (!t.form?.id) {

				// Generate new IDs
				t.form.id = t.MIX_generateId()

				// Get Incremented Contract Number, and the new counter value
				const {referenceNumber, counter} = await t.generateReferenceNumber()
				t.form.projectContractNumber = referenceNumber

				// Create the document
				documentResult = await t.MIX_createDocument('externalSites', t.form)

				// If the document was created successfully, proceed with post-processing
				if (documentResult.code === 1) {
					await t.MIX_addSiteNoticeBoardEntry(t.form, 'In Progress')
					await t.MIX_updateDocumentFieldsById('counters', 'sitesReferenceNumbers', {contractNumber: counter})
					await t.sendNewSiteInProgressNotification(t.form, t.$props.usersData)
					await t.sendProjectTeamLaunchNotification(t.form)
					await t.sendTenderHandoverNotification(t.form)
				}

			}

			// Update
			else {
				let generatedReference

				// If this site is being converted from a New site, assign it a ContractNumber
				if (t.$props.isConvertingSite) {

					// Get Incremented Contract Number, and the new counter value
					generatedReference = await t.generateReferenceNumber()
					t.form.projectContractNumber = generatedReference.referenceNumber

				}

				// Update the document
				documentResult = await t.MIX_updateDocument('externalSites', t.form)

				// If this site is being converted from a New site, update the counter in the DB
				if (documentResult.code === 1 && t.$props.isConvertingSite) {
					await t.MIX_addSiteNoticeBoardEntry(t.form, 'In Progress')
					await t.MIX_updateDocumentFieldsById('counters', 'sitesReferenceNumbers', {contractNumber: generatedReference.counter})
					await t.sendNewSiteInProgressNotification(t.form, t.$props.usersData)
					await t.sendProjectTeamLaunchNotification(t.form)
					await t.sendTenderHandoverNotification(t.form)
				}

			}

			t.MIX_renderConfirmationAlert(
				documentResult,
				'Successfully Saved Site',
				'Error Saving Site'
			)

			t.$emit('emitSitesInProgressFormComplete')
			t.$emit('emitCloseRightPanel')

		},

		/**
		 * Send New Site In Progress Notification
		 *
		 * Call a Firebase function to email all users when a new Site In Progress has been created.
		 *
		 * @param siteData the site data
		 * @param usersData all users data
		 */
		sendNewSiteInProgressNotification(siteData, usersData) {
			const t = this

			// Only send the required data
			usersData = usersData.map(user => {
				return ({
					userLevel: user.userLevel,
					delete: user.delete === true,
					userEmail: user.userEmail,
					userStatus: user.userStatus,
				})
			})

			const createEmail = t.$firebase.functions.httpsCallable('sendNewSiteInProgressNotification')
			createEmail({
				usersData,
				siteData,
			})
				.then(result => {
					console.log('Successfully sent new Site In Progress emails: ', result)
				})
				.catch(error => {
					console.error('Error sending new Site In Progress emails: ', error)
				})

		},

		/**
		 * Send Project Team Launch Notification
		 *
		 * Call a Firebase function to email all users when a new Site In Progress has been created.
		 *
		 * @param siteData the site data
		 */
		sendProjectTeamLaunchNotification(siteData) {
			const t = this
			const projectAwardDate = (Number(siteData.projectContractAward) + 604800000).toString() // A week in millis
			let usersEmails = []

			usersEmails.push(
				t.contractsManagerUserData.userEmail,
				t.projectManagerAgentUserData.userEmail,
				t.qsUserData.userEmail,
				t.foremanUserData.userEmail
			)

			// Get users from New Sites form (if applicable)
			const projectBidLead = t.$props.usersData.find(user => user.id === t.form.projectBidLead)
			if (projectBidLead?.id) usersEmails.push(projectBidLead.userEmail)
			const projectEstimator = t.$props.usersData.find(user => user.id === t.form.projectEstimator)
			if (projectEstimator?.id) usersEmails.push(projectEstimator.userEmail)
			const projectOperationalLead = t.$props.usersData.find(user => user.id === t.form.projectOperationalLead)
			if (projectOperationalLead?.id) usersEmails.push(projectOperationalLead.userEmail)

			// Set the date for the calendar
			siteData.projectContractAward = projectAwardDate

			const createProjectTeamLaunchEmail = t.$firebase.functions.httpsCallable('sendProjectTeamLaunchNotification')
			createProjectTeamLaunchEmail({
				usersEmails,
				siteData,
			})
				.then(result => {
					console.log('Successfully sent Launch emails: ', result)
				})
				.catch(error => {
					console.error('Error sending Launch emails: ', error)
				})

		},

		/**
		 * Send Tender Handover Notification
		 *
		 * Call a Firebase function to email all users when a new Site In Progress has been created.
		 *
		 * @param siteData the site data
		 */
		sendTenderHandoverNotification(siteData) {
			const t = this
			const projectAwardDate = (Number(siteData.projectContractAward) + 604800000).toString() // 2 weeks in millis (runs in addition to Product Team Launch Date)
			let usersEmails = []

			usersEmails.push(
				t.contractsManagerUserData.userEmail,
				t.projectManagerAgentUserData.userEmail,
				t.qsUserData.userEmail,
				t.foremanUserData.userEmail
			)

			// Get users from New Sites form (if applicable)
			const projectBidLead = t.$props.usersData.find(user => user.id === t.form.projectBidLead)
			if (projectBidLead?.id) usersEmails.push(projectBidLead.userEmail)
			const projectEstimator = t.$props.usersData.find(user => user.id === t.form.projectEstimator)
			if (projectEstimator?.id) usersEmails.push(projectEstimator.userEmail)
			const projectOperationalLead = t.$props.usersData.find(user => user.id === t.form.projectOperationalLead)
			if (projectOperationalLead?.id) usersEmails.push(projectOperationalLead.userEmail)

			// Set the date for the calendar
			siteData.projectContractAward = projectAwardDate

			const createTenderHandoverEmail = t.$firebase.functions.httpsCallable('sendTenderHandoverNotification')
			createTenderHandoverEmail({
				usersEmails,
				siteData,
			})
				.then(result => {
					console.log('Successfully sent Handover emails: ', result)
				})
				.catch(error => {
					console.error('Error sending Handover emails: ', error)
				})

		},

		/**
		 * Generate Reference Number
		 *
		 * Site Tender and Contract numbers are stored in a Counters collection.
		 * Read the document and increment the number, convert back to a string, and return the value.
		 * The format for the number is a 'C' + four incrementing digits (e.g. C1234)
		 */
		async generateReferenceNumber() {
			const t = this
			let counter
			let referenceNumber

			const collection = await t.$firebase.db.collection('counters')
				.doc('sitesReferenceNumbers')
				.get()

			if (collection.exists) {
				counter = await collection.data().contractNumber
				counter++
				referenceNumber = `C${String(counter).padStart(4, '0')}`
			}

			return {referenceNumber, counter}
		},

		/**
		 * Validate Form
		 *
		 * Validate all required form fields before saving.
		 *
		 * @returns {boolean} if the form has passed validation or not
		 */
		validateForm() {
			const t = this

			t.clearErrorsAndMessages()

			// Client
			if (!t.form.projectClient) {
				t.errors.projectClient = true
				t.errors.projectClientErrorMessage = 'Client required'
			}

			// Contract Award
			if (!t.form.projectContractAward) {
				t.errors.projectContractAward = true
				t.errors.projectContractAwardErrorMessage = 'Contract Award required'
			}

			// Contract Type
			if (!t.form.projectContractType) {
				t.errors.projectContractType = true
				t.errors.projectContractTypeErrorMessage = 'Contract Type required'
			}

			// Contracts Manager
			if (!t.form.projectContractsManager) {
				t.errors.projectContractsManager = true
				t.errors.projectContractsManagerErrorMessage = 'Contracts Manager required'
			}

			// Description
			if (!t.form.projectDescription) {
				t.errors.projectDescription = true
				t.errors.projectDescriptionErrorMessage = 'Description required'
			}

			// Foreman
			if (!t.form.projectForeman) {
				t.errors.projectForeman = true
				t.errors.projectForemanErrorMessage = 'Foreman required'
			}

			// Project Location - Line 1
			if (!t.form.projectLocationLine1) {
				t.errors.projectLocationLine1 = true
				t.errors.projectLocationLine1ErrorMessage = 'Line 1 required'
			}

			// Project Location - Town
			if (!t.form.projectLocationTown) {
				t.errors.projectLocationTown = true
				t.errors.projectLocationTownErrorMessage = 'Town required'
			}

			// Project Location Postcode
			const SITE_POSTCODE = t.form.projectLocationPostcode?.trim()
			if (!SITE_POSTCODE) {
				t.errors.projectLocationPostcode = true
				t.errors.projectLocationPostcodeErrorMessage = 'Postcode required'
			} else if (!t.MIX_isPostcodeValid(SITE_POSTCODE)) {
				t.errors.projectLocationPostcode = true
				t.errors.projectLocationPostcodeErrorMessage = 'Postcode not in the expected format'
			}

			// Coordinates
			const SITE_LATITUDE = t.form.projectLocationLatitude?.trim()
			const SITE_LONGITUDE = t.form.projectLocationLongitude?.trim()
			if (!SITE_LATITUDE || !SITE_LONGITUDE) {
				t.errors.projectLocationLatitude = true
				t.errors.projectLocationLatitudeErrorMessage = 'Latitude and Longitude required'
				t.errors.projectLocationLongitude = true
				t.errors.projectLocationLongitudeErrorMessage = 'Latitude and Longitude required'
			}
			else if (SITE_LATITUDE && SITE_LONGITUDE) {
				const COORDS = {latitude: SITE_LATITUDE, longitude: SITE_LONGITUDE}
				const RESULT = t.MIX_isLatLngValid(COORDS)

				if (!RESULT.isLatValid) {
					t.errors.projectLocationLatitude = true
					t.errors.projectLocationLatitudeErrorMessage = 'Latitude is not in the expected format'
				}

				if (!RESULT.isLngValid) {
					t.errors.projectLocationLongitude = true
					t.errors.projectLocationLongitudeErrorMessage = 'Longitude is not in the expected format'
				}
			}

			// Project Manager/Agent
			if (!t.form.projectManagerAgent) {
				t.errors.projectManagerAgent = true
				t.errors.projectManagerAgentErrorMessage = 'Project Manager required'
			}

			// Project Start Date
			if (!t.form.projectStartDate) {
				t.errors.projectStartDate = true
				t.errors.projectStartDateErrorMessage = 'Project Start Date required'
			}

			// Contract Period - Start Date
			// If the End date is present, so must the Start date be
			if (!t.form.projectContractPeriodStartDate && t.form.projectContractPeriodEndDate) {
				t.errors.projectContractPeriodStartDate = true
				t.errors.projectContractPeriodStartDateErrorMessage = 'Contract Period Start Date required'
			}

			// Contract Period - End Date
			// If the Start date is present, so must the End date be
			if (t.form.projectContractPeriodStartDate && !t.form.projectContractPeriodEndDate) {
				t.errors.projectContractPeriodEndDate = true
				t.errors.projectContractPeriodEndDateErrorMessage = 'Contract Period End Date required'
			}
			// If both dates are present, the End date must be after the Start date
			else if (t.form.projectContractPeriodStartDate > t.form.projectContractPeriodEndDate) {
				t.errors.projectContractPeriodStartDate = true
				t.errors.projectContractPeriodStartDateErrorMessage = 'Start Date must be before the End Date'
				t.errors.projectContractPeriodEndDate = true
				t.errors.projectContractPeriodEndDateErrorMessage = 'End Date must be after the Start Date'
			}

			// Project Title
			if (!t.form.projectTitle) {
				t.errors.projectTitle = true
				t.errors.projectTitleErrorMessage = 'Project Title is required'
			}

			// QS
			if (!t.form.projectQS) {
				t.errors.projectQS = true
				t.errors.projectQSErrorMessage = 'QS required'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>

<style scoped>

</style>
