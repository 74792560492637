<template>
    <v-row class="ma-0 pa-0" no-gutters>

        <!-- File input -->
		<input @change="previewFile"
               :accept="allowedTypes"
               ref="fileinput"
               style="display: none"
               type="file"/>

        <!--Add photo button | Progress bar-->
        <v-col class="d-flex align-center">

            <!--Add button-->
            <app-btn v-if="!isUploading"
					 @click.native="fileClick"
                     :block="block"
                     class="white--text"
                     color="primary"
                     :disabled="disabled"
                     icon="icons8-camera mr-2"
                     :label="label || 'Add Photo'"/>

            <!--Progress bar-->
            <v-progress-linear v-if="isUploading"
                               height="36"
                               v-model="uploadValue">
                <app-text category="text-default">{{ Math.ceil(uploadValue) }}%</app-text>
            </v-progress-linear>

        </v-col>

    </v-row>
</template>

<script>
import {mapGetters} from 'vuex';

export default {

    props: ['allowedTypes', 'block', 'comment', 'disabled', 'docLink', 'folder', 'label'],

    data: () => ({
        fileData: null,
        fileType: '',
        hideUploadButton: false,
    }),

    computed: {
        ...mapGetters({
            GET_photoUpload: 'photoUpload_store/GET_photoUpload'
        })
    },

    methods: {

        fileClick() {
            this.$refs.fileinput.click()
        },

		async previewFile(event) {
            this.uploadValue = 0
            this.fileURL = null
            this.fileData = event.target.files[0] // assign fileData for preview
        },

    },

    watch: {
        async fileData() {
            const t = this
			const DOC_LINK = t.$props.docLink
			const FOLDER_NAME = t.$props.folder
			const COMMENT = t.$props.comment || ''

            const UPLOAD_FILE_RESULT = await t.MIX_uploadFile(FOLDER_NAME, t.fileData, DOC_LINK.documentId, COMMENT)

            t.MIX_photoUploadResult(UPLOAD_FILE_RESULT.data)
        },
    }
}
</script>
