<!--
Missing Location Dialog

Renders a dialog component to alert the user if no location has been added to a Site (Currently only used in Sites In Progress).
-->
<template>
	<div>

		<!--Toolbar-->
		<v-toolbar class="pl-2" color="primary">

			<app-text category="text-medium" class="white--text">Missing Location</app-text>

			<v-spacer/>

			<close-icon @click.native="closeDialog"/>

		</v-toolbar>

		<!--Content-->
		<v-card class="pa-4" color="appbackground">

			<app-text category="text-default">The following sites are missing a location:</app-text>

			<app-text v-for="item in $props.sites" :key="item.id" class="mt-4" category="text-default-bold">
				{{ item.projectTitle }}
			</app-text>

			<div class="d-flex align-start mt-4">
				<v-icon class="icons8-error mr-4" color="error" size="48"></v-icon>
				<app-text category="text-default">
					Open a Site with this symbol, select the <strong>SWAPP</strong> tab, and add a location
				</app-text>
			</div>

		</v-card>

	</div>
</template>

<script>

export default {

	props: ['sites'],

	methods: {

		closeDialog() {
			const t = this

			t.$emit('emitCloseMissingSiteLocationDialog')
		},

	}
}
</script>
