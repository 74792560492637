<template>
    <div class="frcuc-container">

        <!--Profile image | User details | Checkbox-->
        <div class="frcuc-details-container">

            <!--Profile image-->
            <div @click="callUser">
                <!--If the user has an image-->
                <v-img v-if="user.profilePicFileURL"

                       :src="user.profilePicFileURL"
                       alt="user"
                       class="frcuc-profileImage"
                       :class="user.swappStatus === 0 && 'frcuc-greyScaleImage'"
                       cover
                       :height="imageSize"
                       :width="imageSize"/>

                <!--If the user does not have and icon-->
                <v-icon v-if="!user.profilePicFileURL"
                        class="icons8-person"
                        :size="imageSize"/>
            </div>

            <!--User details-->
            <div class="d-flex flex-column align-start ml-2">

                <!--User name-->
                <app-text category="text-default-bold" class="primary--text">{{ user.userName }}</app-text>

                <!--User type-->
                <app-text category="text-default-bold" class="accent--text">{{ user.userType }}</app-text>

                <!--Ability icons-->
                <div>
                    <v-icon v-if="user.userAccessAndAbilities.includes('Fire Marshall')" class="icons8-fire mr-2" color="error" size="20"/>
                    <v-icon v-if="user.userAccessAndAbilities.includes('First Aider')" class="icons8-clinic" color="success" size="20"/>
                </div>

            </div>

            <v-spacer/>

            <!--Checkbox-->
            <div>
                <v-icon @click="handleSelection"
                        class="icons8-checkmark-yes"
                        :color="isUserChecked ? 'success' : 'lightgrey'"
                        :size="imageSize"/>
            </div>

        </div>

        <!--Swapp status-->
        <div class="d-flex align-center mt-2">
            <v-icon class="icons8-qr-code mr-2"
                    size="32"
                    :color="computedSwappStatus.color"/>
            <app-text category="text-default-bold"> SWAPPed {{ computedSwappStatus.label }}
                <span :class="computedSwappTime.color" class="font-weight-regular ml-1">
                    {{ computedSwappTime.label }}</span>
            </app-text>
        </div>

    </div>
</template>

<script>
export default {

    name: "fireRollCallUserCard",

    props: ['user'],

    data: () => ({

        imageSize: 72,
        isUserChecked: false,

    }),

    computed: {

        /**
         * Computed Swapp Status
         *
         * Return a color and label to signify if a user is SWAPPed in or out.
         *
         * @returns {{color: string, label: string}}
         */
        computedSwappStatus() {
            const t = this
            const swappStatus = t.$props.user.swappStatus
            let color = ''
            let label = ''

            if (swappStatus) {
                color = 'success'
                label = 'In'
            } else {
                color = 'lightgrey'
                label = 'Out'
            }

            return {color: color, label: label}
        },

        /**
         * Computed Swapp Time
         *
         * Calculate if the current user has ever SWAPPed in/out, if they have and it was today,
         * return the date and time.
         * If they have not, return a message.
         *
         * @returns {{color, label: string}} either the date and time, or a message
         */
        computedSwappTime() {
            const t = this
            let lastSwappDateTime
            let color

            // If the user has a last swapp dateTime
            if (t.$props.user.swappTime) {

                // Grab a reference to the last SWAPP datetime
                const lastSwapp = t.$props.user.swappTime

                // Grab a reference to the date now
                const dateTimeNow = t.$moment().format('DD/MM/YYYY')

                // If the last swapp dateTime was today, assign the date and time
                if (dateTimeNow === t.$moment(Number(lastSwapp)).format('DD/MM/YYYY')) {
                    lastSwappDateTime = 'Today ' + t.$moment(Number(lastSwapp)).format('@ HH:mm')
                }
                // If there is no SWAPP for today, render a message
                else {
                    color = 'grey'
                    lastSwappDateTime = 'More than a day ago'
                }
            }

            return {label: lastSwappDateTime, color: color + '--text'}
        }

    },

    methods: {

        /**
         * Handle Selection
         *
         * Toggle the isUserChecked property for the checkbox colouring.
         * Emit the selected User back to the parent (fireRollCallRegister.vue).
         */
        handleSelection() {
            const t = this

            t.isUserChecked = !t.isUserChecked

            t.$emit('fireRollCallSelection', {id: t.$props.user.id, userName: t.$props.user.userName})
        },

        /**
         * Call User
         *
         * Open a telephone prompt to call the selected User.
         */
        callUser() {
            window.open('tel:' + this.$props.user.userTelephone)
        }

    },

    mounted() {
        const t = this

        t.isUserChecked = !!t.$props.user.checked
    }

}
</script>

<style>
.frcuc-container {
    display: flex;
    flex-direction: column;

    background: white;
    border-radius: 8px;
    padding: 8px;

    animation: fadeIn 1s;
}

.frcuc-details-container {
    display: flex;
}

.frcuc-profileImage {
    border-radius: 8px;
}

.frcuc-greyScaleImage {
    filter: grayscale(100%);
}

</style>
