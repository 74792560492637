<template>
	<div>

		<!--Header (Title | Instructional text)-->
		<div class="d-flex pb-0 pt-4 px-4">

			<!--Page title-->
			<page-title icon="icons8-person"
						page-title="My Profile"
						description="This is your profile. From here you can update your personal, and work details."
						:divider="true"/>

			<v-spacer/>

			<!--Edit-->
			<v-icon @click.native="editDocument"
					class="icons8-edit"
					:class="formReadOnly ? 'frc-icon' : 'frc-icon-edit-active'"
					:color="formReadOnly ? 'warning' : 'white'"
					size="32"/>

		</div>

		<!--Form-->
		<v-row class="pa-4" no-gutters>

			<!--Profile Picture | User Details-->
			<v-row no-gutters style="width: 100%">

				<!--Profile picture-->
				<v-col
					class="d-flex justify-center align-center pa-2 rounded-lg white profileImage-container"
					:class="$vuetify.breakpoint.width >= 600 ? 'flex-grow-0 mr-4' : ''"
					:cols="$vuetify.breakpoint.width < 600 && 12"
					style="height: 272px; width: 272px">

					<!--If an image is present, render it-->
					<div v-if="form.profilePicFileURL && !tempImage"
						 class="d-flex flex-column align-center">

						<!--Image-->
						<v-img :src="form.profilePicFileURL"
							   class="rounded-lg" cover height="256" width="256"/>

						<!--Upload button-->
						<div v-if="formMode === 'Edit'"
							 style="position: absolute; z-index: 9; margin-top: 200px">

							<photoupload class="mr-n4"
										 allowed-types="image/*"
										 :doc-link="{collection: 'users', documentId: form.id}"
										 folder="users-profile-pictures"/>

						</div>

					</div>

					<!--If a tempImage (upload preview) is present, render it-->
					<div v-else-if="tempImage"
						 class="d-flex flex-column align-center">

						<!--Image-->
						<v-img :src="tempImage"
							   class="rounded" cover height="256" width="256"/>

						<!--Upload button-->
						<div style="position: absolute; z-index: 9; margin-top: 200px">

							<photoupload class="mr-n4"
										 allowed-types="image/*"
										 :doc-link="{collection: 'users', documentId: form.id}"
										 folder="users-profile-pictures"/>

						</div>

					</div>

					<!--If neither an image or tempImage is present, render an icon-->
					<div v-else class="d-flex flex-column align-center" style="width: 272px">

						<!--Image-->
						<v-icon class="icons8-customer" size="256"/>

						<!--Upload button-->
						<div v-if="formMode === 'New' || formMode === 'Edit'"
							 style="position: absolute; z-index: 9; margin-top: 200px">

							<photoupload class="mr-n4"
										 style="width: 100%"
										 allowed-types="image/*"
										 :doc-link="{collection: 'users', documentId: form.id}"
										 folder="users-profile-pictures"/>

						</div>

					</div>

				</v-col>

				<!-- User details -->
				<v-col :class="$vuetify.breakpoint.width >= 600 && 'flex-grow-1'"
					   :cols="$vuetify.breakpoint.width < 600 && 12">

					<!--Name-->
					<app-input input-type="textInput"
							   :class="$vuetify.breakpoint.width < 600 && 'mt-4'"
							   :error="errors.userName"
							   :error-messages="errors.userNameErrorMessage"
							   :is-form-read-only="formReadOnly"
							   label="Full Name"
							   v-model.trim="form.userName"/>

					<!--Position-->
					<app-input input-type="textInput"
							   class="mt-4"
							   :error="errors.userPosition"
							   :error-messages="errors.userPositionErrorMessage"
							   :is-form-read-only="formReadOnly"
							   label="Job Title"
							   v-model.trim="form.userPosition"/>

					<!--Email-->
					<app-input input-type="textInput"
							   class="mt-4"
							   :error="errors.userEmail"
							   :error-messages="errors.userEmailErrorMessage"
							   :is-form-read-only="formReadOnly"
							   label="Email"
							   v-model.trim="form.userEmail"/>

					<!--Telephone-->
					<app-input input-type="textInput"
							   class="mt-4"
							   :error="errors.userTelephone"
							   :error-messages="errors.userTelephoneErrorMessage"
							   :is-form-read-only="formReadOnly"
							   label="Telephone"
							   v-model="form.userTelephone"/>

				</v-col>

			</v-row>

			<!-- Biography -->
			<v-col cols="12" :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'">
				<app-input input-type="textArea"
						   counter="360"
						   :is-form-read-only="formReadOnly"
						   label="Biography"
						   max-length="360"
						   v-model="form.userBio"/>
			</v-col>

			<!--Consent to Displaying of Details-->
			<v-checkbox class="d-flex align-start"
						color="primary"
						hide-details
						:disabled="formReadOnly"
						label="I consent to my email address and telephone number being displayed in-app so I can be contacted if necessary"
						v-model="form.userCanUsePersonalDetails"/>

			<!--QR Code-->
			<v-col class="mt-8" cols="12">
				<app-text category="text-medium" class="darkgrey--text">QR Code</app-text>
				<app-text category="text-small" class="grey--text">
					This QR Code is unique you and can be used to Reverse-SWAPP
				</app-text>
				<v-divider class="mt-2"/>
			</v-col>
			<v-col cols="12" class="d-flex justify-center">

				<div class="ma-4">

					<qrcode-vue class="white rounded-lg pa-8"
								level="H"
								ref="qrcode"
								size="256"
								style="outline: 1px solid grey"
								:value="form.id"/>

				</div>

			</v-col>

			<!--Save-->
			<v-col v-if="formMode === 'Edit' || formMode === 'New'"
				   cols="12" class="d-flex justify-end mt-4">
				<app-btn @click.native="validateForm"
						 color="success"
						 icon="icons8-save"
						 label="Save"/>
			</v-col>

		</v-row>

		<!--Created and Modified info -->
		<div v-if="formMode !== 'New'" class="pa-4">

			<v-divider/>

			<app-text category="text-small-bold" class="grey--text mt-4">
				Created:
				<span class="font-weight-regular">
					{{ form.createdUserData.userName }}
					-
					{{ MIX_formatDateTime(form.createdDateTime, "x", "DD-MMM-YYYY") }}
				</span>
				|
				Modified:
				<span class="font-weight-regular">
					{{ form.modifiedUserData.userName }}
					-
					{{ MIX_formatDateTime(form.modifiedDateTime, "x", "DD-MMM-YYYY") }}
				</span>
			</app-text>

		</div>

	</div>
</template>

<script>
import {mapGetters} from "vuex";
import DatePicker from "../../components/commonComponents/datePicker.vue"
import userSchedule from "../userSchedule/userSchedule";
import QrcodeVue from "qrcode.vue";

export default {

	name: "userProfile",

	components: {
		QrcodeVue,
		DatePicker,
		userSchedule
	},

	data: () => ({

		errors: {
			userName: false,
			userNameErrorMessage: '',
			userPosition: false,
			userPositionErrorMessage: '',
			userEmail: false,
			userEmailErrorMessage: '',
			userTelephone: false,
			userTelephoneErrorMessage: '',
		},

		formMode: 'View',
		formReadOnly: true,
		formBackground: 'grey lighten-3',
		form: {
			authId: null,
			id: '',
			lastLocation: {
				locationId: '',
				locationName: '',
			},
			lastSite: {
				siteId: '',
				siteName: '',
			},
			privacyPolicyConsent: false,
			profilePicFileURL: null,
			swappMethod: null,
			swappStatus: 0,
			userAccessAndAbilities: [],
			userBio: '',
			userCanUsePersonalDetails: false,
			userCompany: '',
			userEmail: '',
			userIsNtu: false,
			userLevel: '',
			userName: '',
			userObservationResponsibilities: [],
			userPosition: '',
			userRole: '',
			userStatus: '',
			userTelephone: '',
			userType: '',
			userVisitorType: '',

			// Added from admin
			createdDateTime: '',
			createdUserData: {},
			modifiedDateTime: '',
			modifiedUserData: {},
		},

		// Profile picture
		types: "image/*",
		storagePathProfilePic: "users-profile-pictures",
		photoUploadResult: {},
		tempImage: '',

		sitesCollectionData: [],

	}),

	computed: {
		...mapGetters({
			GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult',
			GET_currentUser: 'GET_currentUser',
		}),

		/**
		 * Computed User Roles
		 *
		 * Return the correct user roles for the specified user type.
		 * @returns {Array} user roles as strings
		 */
		computedUserRoles() {
			const t = this
			let roles = []
			const type = t.form.userType

			if (type === 'Staff') {
				roles = ['Admin', 'Director', 'Senior-Manager', 'Project-Manager', 'User-Site', 'User-Office', 'Operative-Groundworker', 'Operative-Plant']
			} else if (type === 'Visitor') {
				roles = ['User']
				t.form.userRole = 'User'
			} else if (type === 'Developer') {
				roles = ['User']
				t.form.userRole = 'User'
			}

			return roles
		},

	},

	methods: {

		/**
		 * Cancel Document
		 *
		 * Set the form to a viewable state and refresh the user data.
		 *
		 * @returns {Promise<void>}
		 */
		async cancelDocument() {
			const t = this

			t.formMode = "View"
			t.formReadOnly = true
			t.formBackground = "grey lighten-3"
			t.tempImage = '';

			// Refresh user data
			await t.getUserCollectionData()
		},

		/**
		 * Clear Errors and Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Edit Document
		 *
		 * Set the form to an editable state.
		 */
		editDocument() {
			const t = this

			if (t.formMode === 'View') {
				t.formMode = 'Edit'
				t.formReadOnly = false
				t.formBackground = "white"
			} else if (t.formMode === 'Edit') {
				t.cancelDocument()
			}

		},

		/**
		 * Get Sites Collection Data
		 *
		 * Fetch sites collection data.
		 * Iterate over the collection and only push documents that aren't marked as deleted.
		 *
		 * @returns {Promise<void>}
		 */
		async getSitesCollectionData() {
			const t = this

			const collection = t.$firebase.db.collection('sites')
			collection.onSnapshot(snapshot => {

				// Clear the collection data to avoid duplications
				t.sitesCollectionData = []

				snapshot.forEach(doc => {

					const document = doc.data()
					document.id = doc.id

					// Only add documents that aren't marked as deleted
					if (!document.hasOwnProperty('delete')) {
						t.sitesCollectionData.push(document)
					}

				})
			})
		},

		/**
		 * Get User Collection Data
		 *
		 * Fetch user collection data for the current user and sync it to the form.
		 *
		 * @returns {Promise<void>}
		 */
		async getUserCollectionData() {
			const t = this

			const collection = t.$firebase.db.collection('users').doc(t.GET_currentUser.id)
			const doc = await collection.get()

			if (doc.exists) {
				t.userCollectionData = doc.data()
				t.form = doc.data()
			} else {
				console.error('UserProfile error fetching user data')
			}

		},

		/**
		 * Render Confirmation Alert
		 *
		 * Take the result of a document db change and render a confirmation box to the user.
		 */
		renderConfirmationAlert(document, successMessage, failureMessage) {
			const t = this

			if (document.code === 1) {
				t.MIX_alert(1, successMessage, null, null)
			} else {
				t.MIX_alert(-1, failureMessage, null, document.error)
			}

		},

		/**
		 * Save Document
		 *
		 * Update the user document.
		 * Set the form to a viewable state.
		 * Call to upload the profile picture if one has been selected.
		 * Refresh the user data.
		 *
		 * @returns {Promise<void>}
		 */
		async saveDocument() {
			const t = this

			const updateDocumentResult = await this.MIX_updateDocument('users', t.form)

			if (updateDocumentResult.code === 1) {

				t.formBackground = "grey lighten-3"
				t.formMode = "View"
				t.formReadOnly = true
				t.tempImage = ''

				// Add uploaded profile image
				await t.uploadProfileImage()

				// Refresh user data
				await t.getUserCollectionData()

				// t.MIX_go('/')

			}

			// Call for a confirmation alert
			t.renderConfirmationAlert(updateDocumentResult, 'Profile successfully updated', 'Error updating profile')
		},

		/**
		 * Set User Level
		 *
		 * Configure the user's user level from their user type and user role.
		 */
		setUserLevel() {
			const t = this

			t.form.userLevel = t.form.userType + '-' + t.form.userRole
		},

		/**
		 * Upload Profile Image
		 *
		 * Update the user's document with a profile image path  (collection | user id | image path).
		 *
		 * @returns {Promise<void>}
		 */
		async uploadProfileImage() {
			const t = this

			if (t.photoUploadResult !== {}) {

				// Save to the document with: collection | user id | image path
				const updatePhotosResult = await this.MIX_updateDocumentFieldsById(
					'users', t.photoUploadResult.docLink, {profilePicFileURL: t.photoUploadResult.fileURL})

				// Call for a confirmation alert
				t.renderConfirmationAlert(updatePhotosResult, 'Photo successfully updated', 'Error updating photo')

			}
		},

		/**
		 * Validate
		 *
		 * Validates the required fields for presence only.
		 * If any of the fields are missing mark them in an errors object.
		 * When there are no errors left, save the record.
		 */
		validateForm() {
			const t = this

			t.clearErrorsAndMessages()

			// Name
			if (!t.form.userName.trim()) {
				t.errors.userName = true
				t.errors.userNameErrorMessage = 'Name required'
			}

			// Position
			if (!t.form.userPosition.trim()) {
				t.errors.userPosition = true
				t.errors.userPositionErrorMessage = 'Position required'
			}

			// Email
			const USER_EMAIL = t.form.userEmail.trim()
			if (!USER_EMAIL) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Email required'
			}
			// Must be a (simple) valid email address
			else if (!t.MIX_isEmailValid(USER_EMAIL)) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Email not in the expected format'
			}

			// Telephone
			const USER_TELEPHONE = t.form.userTelephone.trim()
			if (!USER_TELEPHONE) {
				t.errors.userTelephone = true
				t.errors.userTelephoneErrorMessage = 'Telephone Number required'
			}
			else if (!t.MIX_isTelephoneNumberValid(USER_TELEPHONE)) {
				t.errors.userTelephone = true
				t.errors.userTelephoneErrorMessage = 'Telephone Number not in the expected format'
			}

			// Check if there are any errors left
			if (!Object.values(t.errors).includes(true)) {
				t.setUserLevel()
				t.saveDocument()
			}
		}

	},

	watch: {

		/**
		 * Photo Upload Result
		 *
		 * Watch for the result of a profile picture upload and add its storage path to the photoUploadResult variable.
		 */
		GET_photoUploadResult: {
			handler: async function () {
				const t = this

				t.photoUploadResult = t.GET_photoUploadResult
				t.tempImage = t.photoUploadResult.fileURL

			}, deep: true
		},

	},

	async mounted() {
		const t = this

		// Fetch required collection data
		await t.getUserCollectionData()
		await t.getSitesCollectionData()

	}
}
</script>
