<!--
Dashboard

Only for Admins['Staff-Admin'], Directors['Staff-Director], Senior Managers['Staff-Senior-Manager'] or those with 'Dashboard' access selected in Access and Abilities
Renders a dashboard displaying staff who are SWAPPED IN at the selected site.
A site can be selected by using the drop down selection.

-->
<template>
	<div style="width: 100%">

	<page-loading :is-page-loading="isPageLoading"/>

	<v-row v-if="!computedIsPageLoading" class="fill-height pa-0 ma-0" no-gutters style="min-width: 100%">
		<v-col>

			<!--Header-->
			<v-row class="pa-4" no-gutters>

				<!--Left side-->
				<div>

					<!--Logo-->
					<v-img v-if="$vuetify.breakpoint.width >= 600"
						   :src="require('@/assets/company_logo_helmet.svg')"
						   cover
						   width="240"/>

					<!--Site details-->
					<div class="d-flex flex-column justify-space-around mt-4">

						<!--Site name-->
						<v-select @change="handleSiteSelection"
								  background-color="appbackground"
								  flat
								  hide-details
								  :items="sortedSitesCollectionData"
								  item-text="siteName"
								  :label="selectedSiteData && 'Select a site to get started'"
								  return-object
								  solo
								  v-model="selectedSiteData">
							<template v-slot:selection="data">
								<app-text category="text-medium-bold" class="grey--text">
									{{ data.item.siteName }}
								</app-text>
							</template>

						</v-select>

						<!--Site address-->
						<app-text class="d-flex mx-3">{{ renderSiteAddress }}</app-text>

					</div>

				</div>

				<v-spacer/>

				<!--Right side (DateTime | Weather forecast)-->
				<div :class="$vuetify.breakpoint.width > 1472
                                ? 'd-flex justify-end align-center'
                                : 'd-flex justify-center mt-4'"
					 :style="$vuetify.breakpoint.width <= 1472 && 'width: 100%'">

					<!--Current date/time-->
					<div v-if="$vuetify.breakpoint.width > 832">
						<v-card flat width="174" height="112" class="pa-2 d-flex flex-column align-center">

							<!--Time-->
							<app-text category="text-xxlarge" class="primary--text pb-3">
								{{ timeNow }}
							</app-text>

							<!--Date-->
							<app-text category="text-default" class="grey--text pb-0">
								{{ $moment().format("ddd D-MMM-YYYY") }}
							</app-text>

						</v-card>
					</div>

					<!--Weather cards - 5 day forecast-->
					<div v-if="$vuetify.breakpoint.width > 632" v-for="forecast in getWeatherForecastForSite(selectedSiteData)">
						<dashboard-weather-card :forecast="forecast"/>
					</div>

				</div>

			</v-row>

			<!--Page break-->
			<div class="primary" style="height: 8px"/>

			<!--Main Body - Personnel in/due - Full size (> 600 width)-->
			<div v-if="$vuetify.breakpoint.width >= 600">

				<!-- <div class="d-flex align-center mr-4">
					<v-switch label="Show all users" v-model="showAllScheduleGroups"></v-switch>
				</div> -->

				<v-spacer/>

				<!--Current location (current)-->
				<div class="dshbrd-cardGrid">
					<div v-for="user in getSwappedInUsers" style="width: 100%">
						<dashboard-user-modal :user="user" :showImages="showImages"/>
					</div>
				</div>

				<!--No-one SWAPP'd in message-->
				<div v-if="!getSwappedInUsers.length" class="d-flex justify-center">
					<app-text>No-one is SWAPP'd into this site</app-text>
				</div>

			</div>

			<!--Main Body - Personnel in/due - Tabbed layout (<= 600 width) ---- MOBILE-->
			<div v-if="$vuetify.breakpoint.width < 600">

				<!--Tabs-->
				<!-- <v-tabs v-model="activeTab" class="align-center" show-arrows>

					<v-tabs-slider color="accent"/>

					<v-tab v-for="day in tabDays" :key="day" :href="'#' + day" @change="currentTabDayChange(day)">{{ day
						}}
					</v-tab>

					<v-spacer/>

					<!-- Show all users switch -->
				<!-- <div class="d-flex align-center mr-4">
				   <v-switch label="Show all users" v-model="showAllScheduleGroups"></v-switch>
			   </div> -->

				<!-- </v-tabs>  -->

				<!--Tab content-->
				<!-- <v-tabs-items v-model="activeTab" class="appbackground">
					<v-tab-item v-for="day in tabDays" :key="day" :value="day"> -->

				<!--Current location (current)-->
				<div
					class="dshbrd-cardGrid">
					<div v-for="user in getSwappedInUsers" style="width: 100%">
						<dashboard-user-modal :user="user" :showImages="showImages"/>
					</div>
				</div>

				<!-- </v-tab-item>
			</v-tabs-items> -->
			</div>

		</v-col>
	</v-row>
	</div>
</template>

<script>
import dashboardWeatherCard from "./dashboardWeatherCard/dashboardWeatherCard";
import dashboardUserModal from "./dashboardUserModal/dashboardUserModal";
import {mapGetters} from "vuex";

export default {

	name: "DashboardNew",

	data: () => ({
		activeTab: '',
		filterBySwappStatus: null,
		allSitesWeatherForecast: [],
		showImages: true,
		selectedSiteData: {},
		timeNow: '',
		isPageLoading: true,

		// Data
		externalSitesCollectionData: [],
		isExternalSitesCollectionDataLoaded: false,
		sitesCollectionData: [],
		isSitesCollectionDataLoaded: false,
		usersCollectionData: [],
		isUsersCollectionDataLoaded: false,
		weatherCollectionData: [],
		isWeatherCollectionDataLoaded: false,
	}),

	components: {
		dashboardUserModal,
		dashboardWeatherCard,
	},

	computed: {
		...mapGetters({
			GET_currentUser: "GET_currentUser",
		}),

		/**
		 * Get SWAPPed In Users
		 *
		 * @return swappedInUsers - an array containing the currently swapped in users
		 */
		getSwappedInUsers() {
			const t = this

			return t.usersCollectionData.filter(user => user.swappStatus)
		},

		/**
		 * Render Site Address
		 *
		 * Check for the presence of the different elements of the site address (line 1, line 2, town),
		 * and return an address string when complete.
		 *
		 * @return siteAddress - the elements of the site address to render
		 */
		renderSiteAddress() {
			const t = this
			let siteAddress = ''

			if (t.selectedSiteData?.siteAddress?.addressLine1) siteAddress += t.selectedSiteData.siteAddress.addressLine1

			if (t.selectedSiteData?.siteAddress?.addressLine2) siteAddress += ', ' + t.selectedSiteData.siteAddress.addressLine2

			if (t.selectedSiteData?.siteAddress?.town) siteAddress += ', ' + t.selectedSiteData.siteAddress.town

			return siteAddress
		},

		/**
		 * Sorted Sites Collection Data
		 *
		 * Reformat the ExternalSites data to match the Sites data structure, and then combine the two arrays.
		 *
		 * @returns {*[]}
		 */
		sortedSitesCollectionData() {
			const t = this
			let externalSitesData = t.externalSitesCollectionData
			let sitesData = t.sitesCollectionData

			// Update the ExternalSites data to match the Sites data structure
			externalSitesData.forEach(site => {
				site.siteName = site.projectTitle
				site.siteAddress = {
					addressLine1: site.projectLocationLine1,
					addressLine2: site.projectLocationLine2,
					town: site.projectLocationTown,
					county: site.projectLocationCounty,
					postcode: site.projectLocationPostcode,
				}
			})

			// Combine the two arrays
			let combinedSites = [...sitesData, ...externalSitesData]

			// Sort sites alphabetically
			combinedSites.sort((a, b) => a.siteName > b.siteName ? 1 : -1)

			// Ensure each site has a location
			return combinedSites.filter(site => site.locations.length)
		},

		/**
		 * Computed Is Page Loading
		 *
		 * Return a boolean for the page loading spinner to denote if all data has been loaded.
		 *
		 * @returns {boolean} if data has been loaded or not
		 */
		computedIsPageLoading() {
			const t = this

			t.isPageLoading = !(
				t.isExternalSitesCollectionDataLoaded &&
				t.isSitesCollectionDataLoaded &&
				t.isUsersCollectionDataLoaded &&
				t.isWeatherCollectionDataLoaded
			)

			return t.isPageLoading
		},

	},

	methods: {

		/**
		 * Filter Staff By Site
		 *
		 * Filter all users by the site selected.
		 */
		filterStaffBySite(userData) {
			const t = this

			t.usersCollectionData = userData.filter(user => user.lastSite.siteId === (t.selectedSiteData.id || t.selectedSiteData.siteId))
		},

		/**
		 * Get External Sites Collection Data
		 *
		 * Clear the table data to avoid duplications.
		 * Fetch all the data from the Sites collection.
		 * Iterate over the collection and only push documents that aren't marked as deleted to the sitesCollectionData array.
		 *
		 * @returns {Promise<void>}
		 */
		async getExternalSitesCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('externalSites')
				.onSnapshot(snapshot => {

					// Clear the data to avoid duplications
					collectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only add documents that aren't marked as deleted
						if (!document.hasOwnProperty('delete')) collectionData.push(document)
					})

					t.externalSitesCollectionData = collectionData
					t.isExternalSitesCollectionDataLoaded = true
				})
		},

		/**
		 * Get Sites Collection Data
		 *
		 * Clear the table data to avoid duplications.
		 * Fetch all the data from the Sites collection.
		 * Iterate over the collection and only push documents that aren't marked as deleted to the sitesCollectionData array.
		 *
		 * @returns {Promise<void>}
		 */
		async getSitesCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('sites')
				.onSnapshot(snapshot => {

					// Clear the data to avoid duplications
					collectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only add documents that aren't marked as deleted
						if (!document.hasOwnProperty('delete')) collectionData.push(document)
					})

					t.sitesCollectionData = collectionData
					t.isSitesCollectionDataLoaded = true
				})
		},

		/**
		 * Get Time
		 *
		 * Get and set the current time for the clock.
		 * This is started in mount() and stopped in beforeDestroy().
		 */
		getTime() {
			const t = this

			t.timeNow = t.$moment().format("HH:mm")
		},

		/**
		 * Get Users Collection
		 *
		 * Clear the table data to avoid duplications.
		 * Fetch all the data from the Users collection.
		 * Iterate over the collection and only push documents that aren't marked as deleted to the tableData array.
		 *
		 * @returns {Promise<void>}
		 */
		async getUsersCollectionData() {
			const t = this
			let collectionData = []
			let userDataOriginal = []

			await t.$firebase.db.collection('users')
				.onSnapshot(snapshot => {

					// Clear the data to avoid duplications
					collectionData = []
					userDataOriginal = []

					snapshot.forEach(doc => {

						const DOCUMENT = doc.data()
						DOCUMENT.id = doc.id

						// Only add documents that:
						//  - Aren't marked as deleted
						//  - Are Approved
						//  - Have NOT signed up with 2FA
						//  - Have signed up with, and have passed 2FA
						if (!DOCUMENT.hasOwnProperty('delete') &&
							DOCUMENT.userStatus === 'APPROVED' &&
							(!DOCUMENT.userShouldUse2fa || (DOCUMENT.userHasPassed2fa))
						) {
							collectionData.push(DOCUMENT)
							userDataOriginal.push(DOCUMENT)
						}

					})

					t.usersCollectionData = collectionData
					t.isUsersCollectionDataLoaded = true
					t.userDataOriginal = userDataOriginal

					t.filterStaffBySite(t.usersCollectionData)
				})
		},

		/**
		 * Get Weather Collection Data
		 *
		 * Fetch all the data from the Weather collection and call to set a five-day forecast.
		 *
		 * @returns {Promise<void>}
		 */
		async getWeatherCollectionData() {
			const t = this
			let collectionData = []

			// Get weather collection
			await t.$firebase.db.collection('weather')
				.onSnapshot(snapshot => {

					// Clear the data to avoid duplications
					collectionData = []

					// Iterate over the weather and set it to the weatherData
					snapshot.forEach(doc => {

						const DOCUMENT = doc.data()
						DOCUMENT.id = doc.id

						collectionData.push(DOCUMENT)

						// Call for a five-day forecast
						t.setFiveDayWeatherForecast(DOCUMENT)
					})

					t.isWeatherCollectionDataLoaded = true
				})
		},

		/**
		 * Get Weather Forecast For Site
		 *
		 * Get and return the weather forecast for the selected site.
		 *
		 * @param siteData the data for the selected site
		 * @returns {[]|*}
		 */
		getWeatherForecastForSite(siteData) {
			const t = this
			const ALL_SITES_WEATHER_FORECAST = t.allSitesWeatherForecast

			// Get the weather forecast for the selected site
			const SITE_WEATHER_FORECAST = ALL_SITES_WEATHER_FORECAST.find(site => site.id === (siteData.id || siteData.siteId))

			return SITE_WEATHER_FORECAST?.fiveDayForecast
		},

		/**
		 * Get Weather Icon By ID
		 *
		 * Take the weather icon id, and return an icon name to display.
		 *
		 * @param id - the weather icon id
		 * @return String - the icon name to display
		 */
		getWeatherIconById(id) {
			let icon;

			switch (true) {
				case id >= 200 && id < 300:
					icon = 'icons8-storm';
					break;
				case id >= 300 && id < 400:
					icon = 'icons8-light-rain';
					break;
				case id >= 500 && id < 504:
					icon = 'icons8-rain';
					break;
				case id === 511:
					icon = 'icons8-sleet';
					break;
				case id >= 511 && id < 531:
					icon = 'icons8-rain-cloud';
					break;
				case id >= 600 && id < 700:
					icon = 'icons8-snow';
					break;
				case id >= 701 && id < 800:
					icon = 'icons8-haze';
					break;
				case id === 800:
					icon = 'icons8-sun';
					break;
				case id === 801:
					icon = 'icons8-partly-cloudy-day';
					break;
				case id === 802:
					icon = 'icons8-partly-cloudy-day';
					break;
				case id === 803:
					icon = 'icons8-cloud';
					break;
				case id === 804:
					icon = 'icons8-cloud';
					break;

				default:
					console.error('weather icon id  not recognised: ', id)
			}

			return icon
		},

		/**
		 * Handle Site Selection
		 *
		 * Take the site selection from the drop down and call to start the process of filtering staff to render.
		 * This uses the userDataOriginal to ensure the process starts with fresh data each time a selection is made.
		 */
		handleSiteSelection() {
			const t = this

			t.filterStaffBySite(t.userDataOriginal)
		},

		/**
		 * Set Five Day Weather Forecast
		 *
		 * Using the raw weather data from the collection, grab the first 5 days
		 * and iterate over it to add an icon name from the icon id.
		 *
		 * @param weatherData - the raw weather data from the collection
		 */
		setFiveDayWeatherForecast(weatherData) {
			const t = this
			let fiveDayForecast = []
			let dailyData = []

			// If weather is available, capture the next five days
			// If there is an error, there will be a code for it (cod)
			if (!weatherData.weatherData.cod) dailyData = weatherData.weatherData.daily?.slice(0, 5)

			// Iterate over the forecast and add the icon names to each day
			dailyData.forEach(day => {
				fiveDayForecast.push({
					...day,
					image_icon: t.getWeatherIconById(day.weather[0].id)
				})
			})

			// Set the forecast for rendering
			t.allSitesWeatherForecast.push({id: weatherData.id, fiveDayForecast})
		},

	},

	/**
	 * Mounted
	 *
	 * Pre-load the required data before components render to the screen.
	 *
	 * @returns {Promise<void>}
	 */
	async mounted() {
		const t = this

		// Get collection data
		await t.getSitesCollectionData()
		await t.getExternalSitesCollectionData()
		await t.getUsersCollectionData()
		await t.getWeatherCollectionData()

		// Start clock timer
		t.interval = setInterval(t.getTime, 1000)

		t.selectedSiteData = t.GET_currentUser.swappStatus === 1 ? t.GET_currentUser.lastSite : {}
	},

	/**
	 * Before Destroy
	 *
	 * Kill any running processes.
	 */
	beforeDestroy() {
		const t = this

		clearInterval(t.interval)
	}

}
</script>

<style>
.dshbrd-cardGrid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(343px, 1fr));
	grid-gap: 16px;

	padding: 16px;
}
</style>
