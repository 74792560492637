<template>
	<div class="pa-4">

		<!--Searches and filters-->
		<v-row no-gutters>

			<!--User Name-->
			<v-col :class="$vuetify.breakpoint.width <= 600 ? '' : 'pr-2'"
				   :cols="$vuetify.breakpoint.width <= 600 ? 12 : 6">

				<app-input v-model.trim="searchByUserName"
						   append-icon="icons8-search"
						   :clearable="true"
						   input-type="textInput"
						   label="User Name..."/>

			</v-col>

			<!--Has Accepted Filter-->
			<v-col :class="$vuetify.breakpoint.width <= 600 ? 'mt-4' : 'pl-2'"
				   :cols="$vuetify.breakpoint.width <= 600 ? 12 : 6">

				<app-input input-type="select"
						   :clearable="true"
						   label="Has Accepted"
						   :items="['YES', 'NO']"
						   v-model="hasAccepted">
				</app-input>

			</v-col>

		</v-row>

		<!--Table-->
		<v-data-table class="rounded-lg my-4"
					  :headers="computedTableHeaders"
					  :items="computedTableData"
					  mobile-breakpoint="0">

			<!--User Name & User Job Title-->
			<template v-slot:item.user="{item}">
				<app-text category="text-small">{{ item.user.userName }}</app-text>
				<app-text category="text-xsmall" style="color: grey">{{ item.user.userPosition }}</app-text>
			</template>

			<!--Document First Opened DateTime-->
			<template v-slot:item.firstOpenedDateTime="{item}">
				<!--Date-->
				<app-text v-if="item.firstOpenedDateTime" category="text-small">
					{{ $moment(Number(item.firstOpenedDateTime)).format('ddd Do MMM \'YY') }}
				</app-text>
				<!--Time-->
				<app-text v-if="item.firstOpenedDateTime" category="text-small" class="grey--text">
					{{ $moment(Number(item.firstOpenedDateTime)).format('@ HH:mm') }}
				</app-text>
				<!--Not Opened-->
				<app-text v-if="!item.firstOpenedDateTime" category="text-small" class="grey--text">
					Not Yet Opened
				</app-text>
			</template>

			<!--Document Response DateTime-->
			<template v-slot:item.responseDateTime="{item}">
				<!--Date-->
				<app-text v-if="item.responseDateTime" category="text-small">
					{{ $moment(Number(item.responseDateTime)).format('ddd Do MMM \'YY') }}
				</app-text>
				<!--Time-->
				<app-text v-if="item.responseDateTime" category="text-small" class="grey--text">
					{{ $moment(Number(item.responseDateTime)).format('@ HH:mm') }}
				</app-text>
				<!--Not Accepted-->
				<app-text v-if="!item.responseDateTime" category="text-small" class="grey--text">
					Not Yet Accepted
				</app-text>
			</template>

			<!--Has Accepted-->
			<template v-slot:item.hasAccepted="{item}">
				<v-icon v-if="item.hasAccepted" class="icons8-checkmark-yes" color="success" size="32"/>
				<v-icon v-if="!item.hasAccepted" class="icons8-cancel" color="error" size="32"/>
			</template>

			<!--Actions-->
			<template v-slot:item.userIsNtu="{item}">
				<v-btn v-if="item.userIsNtu"
					   @click.native="handleNtuAcceptanceVisibility(item)"
					   class="rounded-lg"
					   color="primary"
					   depressed>
					<app-text category="text-small">{{ item.hasAccepted ? 'Un-Accept' : 'Accept' }}</app-text>
				</v-btn>
			</template>

		</v-data-table>

		<!-- Dialogs ----------------------------------------------------------------------------------------------- -->

		<!--NTU Acceptance Dialog-->
		<v-dialog max-width="512"
				  v-model="isNtuAcceptanceVisible">

			<!--Toolbar-->
			<v-toolbar color="primary">
				<app-text category="text-medium" class="white--text pl-2">Confirm Acceptance</app-text>
				<v-spacer/>
				<close-icon @click.native="closeNtuAcceptDocumentDialog"/>
			</v-toolbar>

			<!--Content-->
			<div class="white pa-4">

				<!--Instructional Text - Accept-->
				<app-text v-if="!selectedItem.hasAccepted">
					This is a Non-Tech-User, and has been deemed unable to read and accept the terms of this document
					themselves.
					<br>
					<br>
					You can mark this Action on their behalf, if you are sure they have read and accepted the contents
					of this document.

					<!--Question-->
					<app-text category="text-default-bold" class="mt-4">
						Would you like to accept this on their behalf?
					</app-text>
				</app-text>

				<!--Instructional Text - Un-accept-->
				<app-text v-if="selectedItem.hasAccepted">
					This is a Non-Tech-User, and has already had this document accepted on their behalf.
					<br>
					<br>
					If you think there is an issue with this, you can reverse the acceptance of the document on their behalf.

					<!--Question-->
					<app-text category="text-default-bold" class="mt-4">
						Would you like to REVERSE the acceptance on their behalf?
					</app-text>
				</app-text>

				<!--Action Buttons-->
				<div class="d-flex justify-space-between mt-4">

					<!--Cancel-->
					<app-btn @click.native="isNtuAcceptanceVisible = false"
							 color="lightgrey"
							 icon="icons8-cancel"
							 label="Cancel"/>

					<!--Yes-->
					<app-btn @click.native="handleAcceptDocument"
							 color="success"
							 icon="icons8-checkmark-yes"
							 label="Yes"/>

				</div>

			</div>

		</v-dialog>

	</div>
</template>

<script>
export default {

	name: "actionsResponses",

	props: [
		'formData', // Action data
		'actionResponsesCollectionData', // Action Responses for the selected Document (Action)
		'usersCollectionData' // All users data
	],

	data: () => ({
		form: {
			id: '',
			documentName: '',
			documentUrl: null,
			documentUserLevels: [],
			documentAcceptanceRequired: false,
		},
		hasAccepted: '',
		isNtuAcceptanceVisible: false,
		searchByUserName: '',
		selectedItem: {},
		tableHeaders: [
			{
				text: 'Accepted?',
				value: 'hasAccepted',
				sortable: false,
				align: 'center',
				width: '96px'
			},
			{
				text: 'Name',
				value: 'user',
				sortable: false,
				align: 'left',
			},
			{
				text: 'Opened On',
				value: 'firstOpenedDateTime',
				sortable: false,
				align: 'left',
			},
			{
				text: 'Accepted On',
				value: 'responseDateTime',
				sortable: false,
				align: 'left',
			},
			{
				text: 'NTU',
				value: 'userIsNtu',
				sortable: false,
				align: 'center',
				width: '147px'
			},
		],
	}),

	computed: {

		/**
		 * Computed Table Headers
		 *
		 * Using the tableHeaders data, hide the fields that aren't required.
		 *
		 * @returns {Array} an array of table header objects
		 */
		computedTableHeaders() {
			const t = this

			return t.tableHeaders
		},

		/**
		 * Computed Table Data
		 *
		 * Return a sorted array of JSON objects.
		 *
		 * @returns {Array} Array of processed JSON data
		 */
		computedTableData() {
			const t = this
			let tableData = []
			const ACTIONS_RESPONSES_DATA = t.$props.actionResponsesCollectionData
			const USERS_COLLECTION_DATA = t.$props.usersCollectionData

			// Loop over the ActionsResponses documents for this Action Document,
			// create a table object, and push it tho the returned array
			ACTIONS_RESPONSES_DATA.forEach(doc => {

				// Find the user data using the doc userId
				const USER_DATA = USERS_COLLECTION_DATA.find(user => user.id === doc.userId)

				const TABLE_OBJ = {
					id: doc.id,
					hasAccepted: doc.hasAccepted,
					user: USER_DATA,
					firstOpenedDateTime: doc.firstOpenedDateTime,
					responseDateTime: doc.responseDateTime,
					userIsNtu: USER_DATA.userIsNtu,
				}

				tableData.push(TABLE_OBJ)
			})

			// Search by userName
			if (t.searchByUserName) {
				tableData = tableData.filter(user => user.user.userName.toUpperCase().match(t.searchByUserName.toUpperCase()))
			}

			// Search by hasAccepted === YES
			if (t.hasAccepted === 'YES') tableData = tableData.filter(user => user.hasAccepted)

			// Search by hasAccepted === NO
			if (t.hasAccepted === 'NO') tableData = tableData.filter(user => !user.hasAccepted)

			return tableData
		},

	},

	methods: {

		closeNtuAcceptDocumentDialog() {
			const t = this

			t.isNtuAcceptanceVisible = false
		},

		async handleAcceptDocument() {
			const t = this
			const SELECTED_ACTION_RESPONSE = t.selectedItem

			const RESPONSE = await t.MIX_updateDocumentFieldsById(
				'actionsResponses',
				SELECTED_ACTION_RESPONSE.id,
				{
					hasAccepted: !SELECTED_ACTION_RESPONSE.hasAccepted,
					responseDateTime: t.$moment().format('x')
				}
			)

			t.MIX_renderConfirmationAlert(
				RESPONSE,
				'Successfully Updated Action',
				'Error Updating Action'
			)

			t.closeNtuAcceptDocumentDialog()
		},

		handleNtuAcceptanceVisibility(item) {
			const t = this

			t.selectedItem = item

			t.isNtuAcceptanceVisible = true
		},

	}

}

</script>

<style scoped>

</style>
