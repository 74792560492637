<!--
HOME

Renders the home screen which contains the following:
	- Named greeting
	- Welcome instructional message
	- Covid Screening questions
	- SWAPP button (in or out, depending on status)
	- Log IT Support
	- (Admin only) Users Awaiting Approval

-->
<template>
	<div style="width: 100%">

		<!--Page Loader-->
		<page-loading :is-page-loading="isPageLoading"/>

		<!--Desktop >= 840-->
		<div v-if="!computedIsPageLoading && $vuetify.breakpoint.width >= 840">

			<!--Map-->
			<div v-if="GET_currentUser.userLevel !== 'Visitor-User'" style="position: fixed">
				<siteMap :usersData="usersCollectionData" :weatherData="weatherCollectionData"/>
			</div>

			<!--Notice Board and Twitter message-->
			<app-text v-if="!computedAreWidgetsVisible" class="white rounded-br-lg pa-1"
					  style="
					  	position: relative;
					  	max-width: fit-content;
					  	z-index: 99">

				Notice Board and Twitter only available between 6pm and 7am
			</app-text>

			<!--Home Page Widgets-->
			<div v-if="GET_currentUser.userLevel !== 'Visitor-User'"
				 class="d-flex" style="width: 400px">

				<!--Notice Board | Twitter Feed-->
				<div>

					<!--Tabs-->
					<v-tabs v-if="computedAreWidgetsVisible" class="rounded-lg mt-4 px-4" grow v-model="tabs">
						<v-tab>
							<app-text category="text-small">Notice Board</app-text>
						</v-tab>
						<v-tab>
							<app-text category="text-small">Twitter</app-text>
						</v-tab>
					</v-tabs>

					<!--Tab Content-->
					<v-tabs-items v-if="computedAreWidgetsVisible" class="ma-4 home-widgets-shadow hideScrollbar"
								  :style="'height: calc(100vh - 160px); border-radius: 12px; overflow: scroll'"
								  v-model="tabs">

						<v-tab-item>
							<notice-boards-home-page-card :noticeData="noticeBoardsCollectionData"/>
						</v-tab-item>

						<!--Twitter-->
						<v-tab-item>
							<a class="twitter-timeline"
							   data-chrome="nofooter noheader noscrollbar"
							   :data-height="computedTwitterHeight"
							   :data-width="368"
							   data-dnt="true"
							   data-theme="light"
							   href="https://twitter.com/TheWaltersGroup?ref_src=twsrc%5Etfw">
								Tweets by TheWaltersGroup
							</a>

							<script async src="https://platform.twitter.com/widgets.js"
									charset="utf-8"
									type="application/javascript">
							</script>
						</v-tab-item>

					</v-tabs-items>

				</div>

				<weather-cards
					class="py-4"
					:siteId="GET_currentUser.swappStatus === 1 && GET_currentUser.lastSite.siteId"
					:weatherData="weatherCollectionData"/>

			</div>

			<!--SWAPP In | SWAPP Out (VISITORS only)-->
			<div v-if="GET_currentUser.userLevel === 'Visitor-User'" class="pa-4" style="max-width:512px">

				<!--SWAPP In Card-->
				<v-card v-if="GET_currentUser.swappStatus === 0"
						class="d-flex flex-column rounded-lg ma-0 pa-4" flat>

					<!--Icon | Title-->
					<div class="d-flex">

						<!-- QR code -->
						<v-icon class="icons8-qr-code ma-n1" color="accent" size="48"/>

						<!-- Title -->
						<app-text category="text-medium-bold" class="primary--text ml-4">
							Sign In
						</app-text>

					</div>

					<!--Message-->
					<app-text category="text-default" class="mt-4">
						When you arrive at Site you must Sign In at a Location
					</app-text>

					<app-btn @click.native="MIX_go('/swapp/in')"
							 :block="true"
							 class="mt-4"
							 color="primary"
							 :disabled="GET_currentUser.swappStatus === 1 || MIX_notifications_getDaysRemainingForOldestOutstandingActionResponse() === 'Blocked'"
							 icon="icons8-qr-code"
							 label="Sign In"/>

				</v-card>

				<!--SWAPP Out Card-->
				<v-card v-if="GET_currentUser.swappStatus === 1"
						class="d-flex flex-column rounded-lg ma-0 pa-4" flat>

					<!-- Icon | Title -->
					<div class="d-flex">

						<!-- QR code -->
						<v-icon class="icons8-qr-code ma-n1" color="accent" size="48"/>

						<!-- Title -->
						<app-text category="text-medium-bold" class="primary--text ml-4">
							Sign Out
						</app-text>

					</div>

					<!-- Message -->
					<app-text category="text-default" class="mt-4">
						When you leave a Site you must Sign out of the Location
					</app-text>

					<!--Button-->
					<app-btn @click.native="MIX_go('/swapp/out')"
							 :block="true"
							 class="mt-4"
							 color="primary"
							 icon="icons8-qr-code"
							 label="Sign Out"/>

				</v-card>

			</div>

		</div>

		<!--Mobile < 840-->
		<div v-if="$vuetify.breakpoint.width < 840">

			<!--Map-->
			<siteMap v-if="GET_currentUser.userLevel !== 'Visitor-User' && GET_currentUser.swappStatus === 1"
					 :usersData="usersCollectionData"
					 :weatherData="weatherCollectionData"/>

			<!--Page Content-->
			<div class="pa-4">

				<!--Getting Started Card-->
				<getting-started-home-page-card/>

				<home-outstanding-actions
					v-if="GET_currentUser.userLevel !== 'Visitor-User' && MIX_notifications_getDaysRemainingForOldestOutstandingActionResponse()"/>

				<!--Profile Picture Upload Card-->
				<div class="mt-4"/>
				<home-profile-picture-upload
					v-if="GET_currentUser.userLevel !== 'Visitor-User' && !GET_currentUser.profilePicFileURL"/>

				<!--SWAPP In Card-->
				<v-card v-if="GET_currentUser.swappStatus === 0"
						class="d-flex flex-column rounded-lg ma-0 pa-4" flat>

					<!--Icon | Title-->
					<div class="d-flex">

						<!-- QR code -->
						<v-icon class="icons8-qr-code ma-n1" color="accent" size="48"/>

						<!-- Title -->
						<app-text category="text-medium-bold" class="primary--text ml-4">
							Sign In
						</app-text>

					</div>

					<!--Message-->
					<app-text category="text-default" class="mt-4">
						When you arrive at Site you must Sign In at a Location
					</app-text>

					<app-btn @click.native="MIX_go('/swapp/in')"
							 :block="true"
							 class="mt-4"
							 color="primary"
							 :disabled="GET_currentUser.swappStatus === 1 || MIX_notifications_getDaysRemainingForOldestOutstandingActionResponse() === 'Blocked'"
							 icon="icons8-qr-code"
							 label="Sign In"/>

				</v-card>

				<!--SWAPP Out Card-->
				<v-card v-if="GET_currentUser.swappStatus === 1"
						class="d-flex flex-column rounded-lg ma-0 pa-4" flat>

					<!-- Icon | Title -->
					<div class="d-flex">

						<!-- QR code -->
						<v-icon class="icons8-qr-code ma-n1" color="accent" size="48"/>

						<!-- Title -->
						<app-text category="text-medium-bold" class="primary--text ml-4">
							Sign Out
						</app-text>

					</div>

					<!-- Message -->
					<app-text category="text-default" class="mt-4">
						When you leave a Site you must Sign out of the Location
					</app-text>

					<!--Button-->
					<app-btn @click.native="MIX_go('/swapp/out')"
							 :block="true"
							 class="mt-4"
							 color="primary"
							 icon="icons8-qr-code"
							 label="Sign Out"/>

				</v-card>

				<!--Fire Roll Call Card-->
				<fire-roll-call-home-page-card class="mt-4"/>

				<!--Awaiting Approval Card-->
				<awaiting-approval-home-page-card
					v-if="['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel)"
					:usersAwaitingApproval="usersAwaitingApproval"
					:usersAwaitingApprovalMessage="usersAwaitingApprovalMessage"/>

			</div>

		</div>

	</div>
</template>

<script>
import {mapGetters} from "vuex";
import {mixinNotifications} from "@/mixin-notifications";
import covidQuestions2 from "@/components/commonComponents/covidQuestions2";
import FireRollCallHomePageCard from "@/views/fireRollCall/fireRollCallHomePageCard/fireRollCallHomePageCard";
import AwaitingApprovalHomePageCard from "@/views/home/awaitingApprovalHomePageCard/awaitingApprovalHomePageCard";
import GettingStartedHomePageCard from "@/views/home/gettingStartedHomePageCard/gettingStartedHomePageCard";
import siteMap from "@/views/map/map"
import HomeProfilePictureUpload from "@/views/home/homeProfilePictureUpload/homeProfilePictureUpload";
import NoticeBoardsHomePageCard from "@/views/noticeBoards/noticeBoardsHomePageCard/noticeBoardsHomePageCard";
import WeatherCards from "@/components/commonComponents/weatherCards";
import HomeOutstandingActions from "@/views/home/homeOutstandingActions/homeOutstandingActions";

export default {

	name: "Home",

	mixins: [mixinNotifications],

	components: {
		HomeOutstandingActions,
		WeatherCards,
		HomeProfilePictureUpload,
		GettingStartedHomePageCard,
		AwaitingApprovalHomePageCard,
		FireRollCallHomePageCard,
		covidQuestions2,
		siteMap,
		NoticeBoardsHomePageCard,
	},

	data: () => ({
		collection: 'answers',
		collectionItemTitle: 'Answer',
		cards: [
			{
				name: 'Action',
				path: "/actions",
				icon: "icons8-content",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant']
			},
			{
				name: 'Sites',
				path: "/sites",
				icon: "icons8-marker",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant']
			},
			{
				name: 'Directory',
				path: "/staff-directory",
				icon: "icons8-people",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant']
			},
			{
				name: 'Suppliers',
				path: "/",
				icon: "icons8-dump-truck",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant']
			},
			{
				name: 'Projects',
				path: "/",
				icon: "icons8-group-of-projects",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant']
			},
			{
				name: 'Health & Safety',
				path: "/",
				icon: "icons8-safety-vest",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant']
			},
			{
				name: 'Training',
				path: "/",
				icon: "icons8-training",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant']
			},
			{
				name: 'Sub Contractors',
				path: "/",
				icon: "icons8-worker",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant']
			},
		],
		isSwappEnabled: false,

		// Data
		sitesCollectionData: [],
		isSitesCollectionDataLoaded: false,
		usersCollectionData: [],
		isUsersCollectionDataLoaded: false,
		isPageLoading: true,
		noticeBoardsCollectionData: [],
		isNoticeBoardsCollectionDataLoaded: false,
		weatherCollectionData: [],
		isWeatherCollectionDataLoaded: false,

		tabs: '',
		usersAwaitingApproval: [],
	}),

	computed: {
		...mapGetters({
			GET_currentUser: "GET_currentUser",
			GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult',
		}),

		/**
		 * Computed Are Widgets Visible
		 *
		 * The Notice Board and Twitter Feed are only available outside work hours.
		 * Before 7am, and after 6pm.
		 * Senior Managers and above do not have any restrictions.
		 *
		 * @returns {boolean} whether the widgets are visible or not
		 */
		computedAreWidgetsVisible() {
			const t = this
			const CURRENT_TIME = new Date().getHours()
			let isVisible = false

			if ((CURRENT_TIME < 7 || CURRENT_TIME >= 18) || ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(t.GET_currentUser.userLevel)) isVisible = true

			return isVisible
		},

		computedIsPageLoading() {
			const t = this

			t.isPageLoading = !(
				t.isUsersCollectionDataLoaded &&
				t.isSitesCollectionDataLoaded &&
				t.isWeatherCollectionDataLoaded
			)

			return t.isPageLoading
		},

		computedTwitterHeight() {
			return (window.innerHeight - 64) - 16 - 8
		},

		/**
		 * Users Awaiting Approval Message
		 *
		 * Take the number of users awaiting approval and return a grammatically correct string to render.
		 *
		 * @returns message - message to render to screen
		 */
		usersAwaitingApprovalMessage() {
			const t = this
			let message

			if (t.usersAwaitingApproval.length === 1) {
				message = `You have ${t.usersAwaitingApproval.length} account awaiting approval`
			} else {
				message = `You have ${t.usersAwaitingApproval.length} accounts awaiting approval`
			}

			return message
		},

	},

	methods: {

		/**
		 * Get Sites Collection
		 *
		 * Fetch all the data from the Sites collection.
		 * Iterate over the collection and only push documents that aren't marked as deleted.
		 *
		 * @returns {Promise<void>}
		 */
		async getSitesCollectionData() {
			const t = this
			let sitesCollectionData = []

			await t.$firebase.db.collection('sites')
				.onSnapshot(snapshot => {

					// Reset sites to avoid duplications
					sitesCollectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only add documents that aren't marked as deleted
						if (!document.hasOwnProperty('delete')) sitesCollectionData.push(document)
					})

					t.sitesCollectionData = sitesCollectionData
					t.isSitesCollectionDataLoaded = true
				})
		},

		/**
		 * Get Users Collection Data
		 *
		 * Fetch all the data from the Users collection.
		 * Iterate over the collection and only push documents that aren't marked as deleted.
		 *
		 * @returns {Promise<void>}
		 */
		async getUsersCollectionData() {
			const t = this
			let collectionData = []

			let collection = await t.$firebase.db.collection('users')

			// If user isn't able to Approve Users, only get the current user data
			if (!['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(t.GET_currentUser.userLevel)) {
				collection = collection.where("id", "==", t.GET_currentUser.id)
			}

			collection.get().then(user => {

				// Reset data to avoid duplications
				collectionData = []

				user.forEach(doc => {

					const DOCUMENT = doc.data()
					DOCUMENT.id = doc.id

					// Update the user
					if (t.GET_currentUser.id === DOCUMENT.id) t.$store.commit('SET_currentUser', DOCUMENT)

					// Only add documents that:
					//  - Aren't marked as deleted
					//  - Are Approved
					//  - Have NOT signed up with 2FA
					//  - Have signed up with, and have passed 2FA
					if (!DOCUMENT.hasOwnProperty('delete') &&
						DOCUMENT.userStatus === 'APPROVED' &&
						(!DOCUMENT.userShouldUse2fa || (DOCUMENT.userHasPassed2fa))
					) {
						collectionData.push(DOCUMENT)
					}

					// Get all users awaiting approval
					if (!DOCUMENT.hasOwnProperty('delete') &&
						DOCUMENT.userStatus === 'PENDING' &&
						(!DOCUMENT.userShouldUse2fa || (DOCUMENT.userHasPassed2fa))
					) {
						t.usersAwaitingApproval.push(DOCUMENT)
					}
				})

				t.usersCollectionData = collectionData
				t.isUsersCollectionDataLoaded = true
			})
		},

		async getNoticeBoardsCollectionData() {
			const t = this
			let noticeBoardsCollectionData = []

			await t.$firebase.db.collection('noticeBoards')
				.where('noticeVisibility', '==', 'Visible')
				.onSnapshot(snapshot => {

					noticeBoardsCollectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						if (!document.hasOwnProperty('delete')) noticeBoardsCollectionData.push(document)

					})

					// Sort by Created Date
					noticeBoardsCollectionData = noticeBoardsCollectionData.sort((a, b) => {
						return a.createdDateTime < b.createdDateTime ? 1 : -1
					})

					t.noticeBoardsCollectionData = noticeBoardsCollectionData
					t.isNoticeBoardsCollectionDataLoaded = true
				})
		},

		async getWeatherCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('weather')
				.onSnapshot(snapshot => {

					collectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						collectionData.push(document)

					})
					t.weatherCollectionData = collectionData
					t.isWeatherCollectionDataLoaded = true
				})
		},

	},

	async mounted() {
		const t = this

		// Get collection data
		await t.getSitesCollectionData()
		await t.getUsersCollectionData()
		await t.getNoticeBoardsCollectionData()
		await t.getWeatherCollectionData()

	},

};
</script>

<style>
.expansion-panel-content-container > div {
	padding: 16px;
}

.hme-swappButton-container {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;

	background: var(--v-appbackground-base);
	padding: 16px;
}

.hme-cards-container-outer {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 16px;

	/*padding: 0 16px;*/
	width: 100%;
}

.hme-cards-container-inner {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 16px;
}

.home-widgets-container {
	display: grid;
	grid-template-rows: 1fr 1fr;
	grid-gap: 16px;

	height: calc(100vh - 64px);
	padding: 16px;
	position: relative;
	width: 400px;
}

.home-widgets-shadow {
	box-shadow: 0 0 8px grey;
}

/*.hideScrollbar {*/
/*	background: white;*/
/*	border-radius: 8px;*/
/*	overflow-y: scroll;*/
/*	position: relative;*/

/*	-ms-overflow-style: none; !* IE and Edge *!*/
/*	scrollbar-width: none; !* Firefox *!*/
/*}*/

/*.hideScrollbar::-webkit-scrollbar {*/
/*	display: none;*/
/*}*/

.home-twitterFeed-container {
	border-radius: 16px;
	background-color: transparent;
}


/*!* width *!*/
/*::-webkit-scrollbar {*/
/*	width: 8px;*/
/*}*/

/*!* Track *!*/
/*::-webkit-scrollbar-track {*/
/*	!*background: #ff0000;*!*/
/*}*/

/*!* Handle *!*/
/*::-webkit-scrollbar-thumb {*/
/*	background: #999;*/
/*}*/

/*!* Handle on hover *!*/
/*::-webkit-scrollbar-thumb:hover {*/
/*	background: var(--v-primary-base);*/
/*}*/
</style>
