<template>
	<v-card>

		<!--Toolbar-->
		<v-toolbar class="pl-2" color="primary">

			<!--Title-->
			<app-text category="text-medium" class="white--text">{{ point.projectContractNumber }}</app-text>

			<v-spacer/>

			<close-icon @click.native="closeDialog"/>

		</v-toolbar>

		<!--Content-->
		<div class="d-flex flex-column justify-center text-center pa-4">

			<!--Weather Cards-->
			<div class="pa-1" style="overflow-x: auto">
				<weather-cards
					:horizontal="true"
					:siteId="point.id"
					:weather-data="weatherData"/>
			</div>
			<v-divider v-if="$vuetify.breakpoint.width >= 840" class="mt-4"/>

			<!--ProjectTitle | Client-->
			<div class="mpd-2row-container mt-8">

				<!--Project Title-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Project Title</app-text>
					<app-text>{{ point.projectTitle }}</app-text>
				</div>

				<div style="border-left: 1px solid lightgrey"/>

				<!--Client-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Client</app-text>
					<app-text>{{ point.projectClient }}</app-text>
				</div>

			</div>

			<!--Description-->
			<div class="mt-8">

				<!--Description-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Description</app-text>
					<app-text>{{ point.projectDescription }}</app-text>
				</div>

			</div>

			<!--Value | Type of Contract-->
			<div class="mpd-2row-container mt-8">

				<!--Value-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Value</app-text>
					<app-text>{{ new Intl.NumberFormat('en-GB', {
						maximumFractionDigits: 0,
						style: 'currency',
						currency: 'GBP'
					}).format(point.projectValue) }}
					</app-text>
				</div>

				<div style="border-left: 1px solid lightgrey"/>

				<!--Type of Contract-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Type of Contract</app-text>
					<app-text>{{ point.projectContractType }}</app-text>
				</div>

			</div>

			<!--Award Date | Start Date | Region-->
			<div class="mpd-3row-container mt-8">

				<!--Award Date-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Award Date</app-text>
					<app-text>{{ $moment(Number(point.projectContractAward)).format('ddd Do MMM \'YY') }}</app-text>
				</div>

				<div style="border-left: 1px solid lightgrey"/>

				<!--Start Date-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Start Date</app-text>
					<app-text>{{ $moment(Number(point.projectStartDate)).format('ddd Do MMM \'YY') }}</app-text>
				</div>

				<div style="border-left: 1px solid lightgrey"/>

				<!--Region-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Region</app-text>
					<app-text>{{ point.projectRegion }}</app-text>
				</div>

			</div>

			<!--Contracts Manager | Project Manager/Agent-->
			<div class="mpd-2row-container mt-8">

				<!--Contracts Manager-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Contracts Manager</app-text>
					<app-text>{{ getUserName(point.projectContractsManager) }}</app-text>
				</div>

				<div style="border-left: 1px solid lightgrey"/>

				<!--Project Manager/Agent-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Project Manager/Agent</app-text>
					<app-text>{{ getUserName(point.projectManagerAgent) }}</app-text>
				</div>

			</div>

			<!--QS | Foreman-->
			<div class="mpd-2row-container mt-8">

				<!--QS-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">QS</app-text>
					<app-text>{{ getUserName(point.projectQS) }}</app-text>
				</div>

				<div style="border-left: 1px solid lightgrey"/>

				<!--Foreman-->
				<div class="d-flex flex-column align-center">
					<app-text category="text-small" class="grey--text">Foreman</app-text>
					<app-text>{{ getUserName(point.projectForeman) }}</app-text>
				</div>

			</div>

		</div>

	</v-card>
</template>

<script>
import WeatherCards from "@/components/commonComponents/weatherCards";

export default {

	name: "mapSiteInProgressPointDialog",

	components: {WeatherCards},

	props: ['point', 'usersData', 'weatherData'],

	data: () => ({}),

	methods: {

		closeDialog() {
			const t = this

			t.$emit('emitCloseMapPointDialog')
		},

		/**
		 * Get UserName
		 *
		 * Get and return the userName from the user's ID
		 *
		 * @param userId the user's ID to search on
		 * @returns {String} the userName if it's found, or a 'Not Found' message
		 */
		getUserName(userId) {
			const t = this
			const usersData = t.$props.usersData
			let userName

			const foundUser = userName = usersData.find(user => user.id === userId)

			return userName = foundUser ? foundUser.userName : 'Not Found'
		},

		renderDate(date) {
			const t = this

			return t.$vuetify.breakpoint.width < 512
				? t.$moment(Number(date)).format('Do MMM \'YY')
				: t.$moment(Number(date)).format('ddd Do MMM \'YY')
		}

	}

}
</script>

<style scoped>
.mpd-2row-container {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
}

.mpd-3row-container {
	display: grid;
	grid-template-columns: 1fr auto 1fr auto 1fr;

	margin-top: 32px;
}
</style>
