<template>
	<div style="width: 100%">

		<!--Page Loader-->
		<page-loading :is-page-loading="isPageLoading"/>

		<!--Page Content-->
		<v-row v-if="!computedIsPageLoading" no-gutters style="height: 100%">

			<!--Left Panel-->
			<v-col v-if="computedSplitScreenColumns.left"
				   :cols="computedSplitScreenColumns.left"
				   class="pa-4">

				<!--Title-->
				<page-title icon="icons8-speech-bubble"
							page-title="Teamship"
							description="Create, view and edit Teamship reports."
							:divider="true"/>

				<!--Searches-->
				<v-row no-gutters class="d-flex mb-4">

					<!--Search-->
					<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
						   :class="$vuetify.breakpoint.width < 600 ? 'pa-0' : 'pr-2'">

						<app-input append-icon="icons8-search"
								   :clearable="true"
								   input-type="textInput"
								   label="Search"
								   v-model.trim="searchByIssue"/>

					</v-col>

					<!--Type-->
					<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
						   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'">

						<app-input inputType="select"
								   :clearable="true"
								   :items="observationTypesOptionsData"
								   label="Type"
								   v-model="searchByType"/>

					</v-col>

				</v-row>

				<!--Filters | Action Buttons-->
				<v-row no-gutters class="d-flex mb-4">

					<!-- Status filters -->
					<v-col
						v-if="['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager'].includes(GET_currentUser.userLevel)"
						:cols="$vuetify.breakpoint.width > 775 ? 6 : 12"
						class="d-flex pa-0 align-center"
						:class="$vuetify.breakpoint.width > 775 ? '' : 'mb-4'">

						<!--Status filters-->
						<div>
							<v-btn-toggle active-class="white"
										  background-color="grey"
										  class="rounded-lg"
										  color="white"
										  dense
										  multiple
										  style="height: 48px"
										  v-model="filter.status">

								<!--Pending-->
								<v-btn :height="buttonSizeDefault" value="Pending">
									<v-icon color="error" class="icons8-inactive-state"/>
									<app-text v-if="$vuetify.breakpoint.width >= 400"
											  category="text-small" class="grey--text">
										Pending
									</app-text>
								</v-btn>

								<!--Resolved-->
								<v-btn :height="buttonSizeDefault" value="Resolved">
									<v-icon color="success" class="icons8-checkmark-yes"/>
									<app-text v-if="$vuetify.breakpoint.width >= 400"
											  category="text-small" class="grey--text">
										Resolved
									</app-text>
								</v-btn>

							</v-btn-toggle>
						</div>

					</v-col>

					<!--Action Buttons-->
					<v-col
						:cols="!['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager'].includes(GET_currentUser.userLevel) ? 12 : $vuetify.breakpoint.width < 776 ? 12 : $vuetify.breakpoint.width < 1400 && isRightPanelOpen ? 12 : 6"
						class="d-flex pa-0 align-center"
						:class="$vuetify.breakpoint.width < 1400 && isRightPanelOpen ? 'justify-start mt-4' : 'justify-end'">

						<!--Action Buttons-->
						<div class="d-flex">

							<!--New Button (> 775px)-->
							<app-btn v-if="$vuetify.breakpoint.width > 775 && !isRightPanelOpen"
									 @click.native="openFormTypeDialog"
									 color="primary ml-4"
									 icon="icons8-speech-bubble"
									 label="New"/>

							<!--Columns | Export-->
							<div
								v-if="['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager'].includes(GET_currentUser.userLevel)"
								class="d-flex align-center">

								<!--Columns-->
								<app-btn v-if="$vuetify.breakpoint.width > 775"
										 @click.native="columnsDialog = true"
										 class="ml-4"
										 color="primary"
										 :hideLabelBelow="isRightPanelOpen ? 8000 : 915"
										 icon="icons8-select-column"
										 label="Columns"/>

								<!--Export-->
								<app-btn v-if="$vuetify.breakpoint.width > 775"
										 @click.native="MIX_exportDocuments(computedExportCSV.headers, 'Reports', computedExportCSV.data)"
										 class="ml-4"
										 color="primary"
										 :hideLabelBelow="isRightPanelOpen ? 8000 : 915"
										 icon="icons8-export-csv"
										 label="Export"/>

							</div>

						</div>

					</v-col>

					<!--New Button (<= 775px)-->
					<app-btn v-if="$vuetify.breakpoint.width <= 775 && !isRightPanelOpen"
							 @click.native="openFormTypeDialog"
							 :block="true"
							 class="primary"
							 icon="icons8-speech-bubble"
							 label="New Report"/>

				</v-row>

				<!--Table-->
				<v-data-table v-if="$vuetify.breakpoint.width >= 600"
							  :headers="computedTableHeaders"
							  :items="computedTableData"
							  class="rounded-lg my-4">

					<!--Id-->
					<template v-slot:[`item.id`]="{item}">
						<app-text category="text-small">{{ item.id }}</app-text>
					</template>

					<!--Status-->
					<template v-slot:[`item.observationStatus`]="{ item }">
						<v-icon v-if="item.observationStatus === 'Pending'"
								color="error" class="icons8-inactive-state" size="32"/>
						<v-icon v-if="item.observationStatus === 'Resolved'"
								color="success" class="icons8-checkmark-yes" size="32"/>
					</template>

					<!--Title-->
					<template v-slot:[`item.observationTitle`]="{item}">
						<app-text category="text-small">{{ item.observationTitle }}</app-text>
					</template>

					<!--Type-->
					<template v-slot:[`item.observationType`]="{item}">
						<app-text category="text-small">{{ item.observationType }}</app-text>
					</template>

					<!--Site-->
					<template v-slot:[`item.observationSite`]="{item}">
						<app-text category="text-small">{{ getSiteName(item.observationSite) }}</app-text>
					</template>

					<!--Reported By-->
					<template v-slot:[`item.createdUserData`]="{item}">
						<app-text category="text-small">{{ item.createdUserData.userName }}</app-text>
					</template>

					<!--Reported On-->
					<template v-slot:[`item.createdDateTime`]="{item}">
						<app-text category="text-small">
							{{ $moment(Number(item.createdDateTime)).format('Do MMM \'YY') }}
						</app-text>
					</template>

					<!--Actions-->
					<template v-slot:[`item.actions`]="{item}">
						<v-btn @click="openRightPanel(item)" class="primary--text white" depressed>
							<v-icon class="icons8-forward" color="primary"/>
						</v-btn>
					</template>

				</v-data-table>

				<!--Mobile Cards-->
				<div v-if="$vuetify.breakpoint.width < 600">

					<app-text v-if="!observationsCollectionData.length" class="text-center mt-4">
						There are no Reports to show
					</app-text>

					<div v-for="item in computedTableData"
						 @click="openRightPanel(item)" :key="item.id"
						 class="white rounded-lg mb-4">

						<div class="d-flex align-center pa-4">

							<!--Status icons-->
							<div class="mr-4">

								<v-icon v-if="item.observationStatus === 'Pending'"
										color="error"
										class="icons8-inactive-state"
										size="32"/>

								<v-icon v-if="item.observationStatus === 'Resolved'"
										color="success"
										class="icons8-checkmark-yes"
										size="32"/>

							</div>

							<!--Observation Details - (Name | Type | Date)-->
							<div class="flex-grow-1">

								<!--Observation name-->
								<app-text category="text-default-bold">{{ item.observationTitle }}</app-text>

								<!--Observation type-->
								<app-text category="text-small-bold" class="grey--text">{{ item.observationType }}
								</app-text>

								<!--Reported date-->
								<app-text category="text-small" class="grey--text">
									{{ $moment(Number(item.createdDateTime)).format('Do MMM YY @ HH:mm') }}
								</app-text>

							</div>

						</div>
					</div>
				</div>

			</v-col>

			<!--Right panel-->
			<v-col v-if="isRightPanelOpen"
				   :class="computedSplitScreenColumns.left && 'borderLeft'"
				   :cols="computedSplitScreenColumns.right">

				<!--Action Bar-->
				<div class="d-flex align-center justify-space-between appbackground pa-4">

					<!--Title | Status-->
					<div class="d-flex flex-column">

						<!--Title-->
						<app-text v-if="$vuetify.breakpoint.width >= 600" category="text-default">
							{{ selectedItem && selectedItem.observationTitle ? selectedItem.observationTitle : 'New' }}
						</app-text>

						<!--Status-->
						<div v-if="selectedItem && selectedItem.observationTitle !== 'New'"
							 class="d-flex align-center mt-2">

							<v-icon v-if="selectedItem.observationStatus === 'Pending'"
									class="icons8-inactive-state"
									color="error"
									size="32"/>
							<v-icon v-if="selectedItem.observationStatus === 'Resolved'"
									class="icons8-checkmark-yes"
									color="success"
									size="32"/>

							<!--Status Text-->
							<div>
								<app-text v-if="selectedItem.observationStatus"
										  category="text-small-bold" class="grey--text ml-2">
									{{ selectedItem.observationStatus }}
								</app-text>
								<app-text v-if="selectedItem.observationRespondingDateTime"
										  category="text-small" class="grey--text ml-2">
									{{ $moment(Number(selectedItem.observationRespondingDateTime)).format('do MMM \'YY @ HH:mm')
									}}
								</app-text>
							</div>

						</div>

					</div>

					<v-spacer/>

					<!--Action Buttons-->
					<div>

						<!--Edit Icon-->
						<edit-icon :isActive="!isFormReadOnly" @click.native="editForm"/>

						<!--Delete Icon-->
						<delete-icon-dialog :disabled="!isFormReadOnly"
											:item-to-delete="selectedItem && selectedItem.observationTitle"
											v-on:emitDeleteFromDialog="emittedDeleteFromDialog"/>

						<!--Close Icon-->
						<close-icon @click.native="closeRightPanel"/>

					</div>

				</div>

				<!--Form Content-->
				<observations-form v-if="formType === 'Observation'"
								   @emitCloseRightPanel="emittedCloseRightPanel"
								   :does-form-start-read-only="isFormReadOnly"
								   :form-data="selectedItem"
								   :sites-data="sitesCollectionData"
								   :external-sites-data="externalSitesCollectionData"
								   :users-data="usersCollectionData"/>

				<good-practice-form v-if="formType === 'Good Practice'"
									@emitCloseRightPanel="emittedCloseRightPanel"
									:form-data="selectedItem"
									:is-read-only="isFormReadOnly"
									:sites-data="sitesCollectionData"
									:external-sites-data="externalSitesCollectionData"/>

				<recognition-form v-if="formType === 'Recognition'"
							 @emitCloseRightPanel="emittedCloseRightPanel"
							 :form-data="selectedItem"
							 :is-read-only="isFormReadOnly"
							 :sites-data="sitesCollectionData"
							 :external-sites-data="externalSitesCollectionData"
							 :users-data="usersCollectionData"/>

			</v-col>

		</v-row>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->

		<!--Columns Dialog Box-->
		<v-dialog v-model="columnsDialog" scrollable max-width="300px">
			<v-card>

				<!--Title-->
				<app-text category="text-large" class="primary--text pa-4">{{ $t('miscHeadings.showHideColumns') }}
				</app-text>

				<v-divider/>

				<!--List items-->
				<v-card-text>
					<v-list>
						<v-list-item v-for="(header, index) in tableHeaders.slice(0, -1)" :key="index">
							<app-text category="text-default">
								<v-checkbox color="grey darken-1" hide-details v-model="tableHeaders[index].hidden"
											:false-value="true" :true-value="false"
											:label="header.text"></v-checkbox>
							</app-text>
						</v-list-item>
					</v-list>
				</v-card-text>

				<v-divider/>

				<!--Close button-->
				<v-card-actions class="text-right">
					<v-spacer/>
					<v-btn color="primary" text @click="columnsDialog = false">Close</v-btn>
				</v-card-actions>

			</v-card>
		</v-dialog>

		<!--Form Type Dialog-->
		<v-dialog max-width="512" v-model="isFormTypeDialogVisible">
			<v-card>

				<!--Toolbar-->
				<v-toolbar color="primary">
					<app-text category="text-medium" class="white--text pa-4">Create a Report</app-text>
					<v-spacer/>
					<close-icon @click.native="isFormTypeDialogVisible = false"/>
				</v-toolbar>

				<!--Content-->
				<div class="rounded-lg pa-4">
					<app-text>What type of Teamship report would you like to create?</app-text>

					<app-input input-type="select"
							   class="mt-4"
							   :items="observationTypesOptionsData"
							   label="Select a report type"
							   v-model="formType"/>

					<app-btn @click.native="handleFormTypeSelection" :block="true" class="mt-4" label="Create"/>
				</div>
			</v-card>
		</v-dialog>

	</div>
</template>

<script>
import ObservationsForm from "@/views/observations/observationsForm/observationsForm";
import GoodPracticeForm from "@/views/observations/goodPracticeForm/goodPracticeForm.vue";
import RecognitionForm from "@/views/observations/recognitionForm/recognitionForm.vue";
import {mapGetters} from "vuex";

export default {

	name: "observations",

	components: {RecognitionForm, GoodPracticeForm, ObservationsForm},

	data: () => ({
		columnsDialog: false,
		filter: {
			// Default Filters
			sortAsc: false,
			status: [],
			type: '',
		},
		isFormReadOnly: true,
		isPageLoading: true,
		isRightPanelOpen: false,
		searchByIssue: '',
		searchByType: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'id', sortable: false, align: 'left', hidden: true, hide: false},
			{
				text: 'Status',
				value: 'observationStatus',
				sortable: false,
				align: 'center',
				hidden: false,
				hide: false,
				width: '48px'
			},
			{
				text: 'Title',
				value: 'observationTitle',
				align: 'left',
				sortable: false,
				hidden: false,
				hide: false
			},
			{
				text: 'Type',
				value: 'observationType',
				align: 'left',
				sortable: false,
				hidden: false,
				hide: false
			},
			{
				text: 'Site',
				value: 'observationSite',
				align: 'left',
				sortable: false,
				hidden: false,
				hide: false
			},
			{
				text: 'Reported By',
				value: 'createdUserData',
				align: 'left',
				sortable: false,
				hidden: false,
				hide: true
			},
			{
				text: 'Reported On',
				value: 'createdDateTime',
				align: 'left',
				sortable: false,
				hidden: false,
				hide: false,
				width: '112px'
			},
			{text: '', value: 'actions', align: 'right', sortable: false, hidden: false, width: '96px'},
		],

		formType: '',
		isFormTypeDialogVisible: false,

		// Data
		externalSitesCollectionData: [],
		isExternalSitesCollectionDataLoaded: false,
		observationsCollectionData: [],
		isObservationsCollectionDataLoaded: false,
		sitesCollectionData: [],
		isSitesCollectionDataLoaded: false,
		usersCollectionData: [],
		isUsersCollectionDataLoaded: false,
	}),

	computed: {
		...mapGetters({GET_currentUser: 'GET_currentUser'}),

		/**
		 * Computed Export CSV
		 *
		 * Process and return a Headers object and Data array of selected User data to export.
		 *
		 * @returns {{headers: {observationTitle: string, observationSite: string, observationStatus: string, observationType: string, observationReportedBy: string, observationModified: string, observationModifiedBy: string, observationReportedOn: string}, data: *[]}} headers object and data array for export file
		 */
		computedExportCSV() {
			const t = this
			let headers = {}
			let data = []

			headers = {
				observationStatus: 'Observation Status',
				observationTitle: 'Observation Name',
				observationType: 'Observation Type',
				observationSite: 'Observation Site',
				observationReportedBy: 'Reported By',
				observationReportedOn: 'Reported On',
				observationModified: 'Modified',
				observationModifiedBy: 'Modified By',
			}

			t.computedTableData.forEach(report => {
				const reportDataObj = {
					observationStatus: report.observationStatus,
					observationName: report.observationTitle,
					observationType: report.observationType,
					observationSite: report.observationSite.siteName,
					observationReportedBy: report.createdUserData.userName,
					observationReportedOn: t.MIX_formatDateTime(report.createdDateTime, 'x', 'Do MMM YY @ HH:mm'),
					observationModified: t.MIX_formatDateTime(report.modifiedDateTime, 'x', 'Do MMM YY @ HH:mm'),
					observationModifiedBy: report.modifiedUserData.userName,

				}
				data.push(reportDataObj)
			})

			return {headers, data}
		},

		/**
		 * Computed Is Page Loading
		 *
		 * Return a boolean for the page loading spinner to denote if all data has been loaded.
		 *
		 * @returns {boolean} if data has been loaded or not
		 */
		computedIsPageLoading() {
			const t = this

			t.isPageLoading = !(
				t.isObservationsCollectionDataLoaded &&
				t.isSitesCollectionDataLoaded &&
				t.isExternalSitesCollectionDataLoaded &&
				t.isUsersCollectionDataLoaded
			)

			return t.isPageLoading
		},

		/**
		 * Computed Split Screen Columns
		 *
		 * Calculate the width of the left snd right panels, based on the currently selected functionality.
		 * The left panel has the table, the right panel has the forms.
		 *
		 * @returns {{left: number, right: number}}
		 */
		computedSplitScreenColumns() {
			const t = this
			let left = 12
			let right = 0

			if (t.$vuetify.breakpoint.width >= 1200) {
				if (t.isRightPanelOpen) {
					left = 6
					right = 6
				}
			} else {
				if (t.isRightPanelOpen) {
					left = 0
					right = 12
				}
			}

			return {left, right}
		},

		/**
		 * Computed Table Headers
		 *
		 * Process and return the headers for the table.
		 *
		 * @returns {Array} array of header objects
		 */
		computedTableHeaders() {
			const t = this;
			const headers = t.tableHeaders;
			return (t.$filter(headers, {
				hidden: false, // filter headers based on initial hidden value
			}));
		},

		/**
		 * Computed Table Data
		 *
		 * Process and return the data for the table.
		 *
		 * @returns {Array} array of observation objects
		 */
		computedTableData() {
			const t = this
			const CURRENT_USER = t.GET_currentUser
			let tableData = t.observationsCollectionData

			// Filter the observations by:
			//  - The current user created the observation
			//  - The current user is included in 'Who to Notify'
			//  - The current user's userLevel is an Admin, Director, Senior Manager, or Project Manager
			//  - The current user has the 'Teamship Reports' Observation Responsibility
			//  - The current user has the 'Teamship: Can Respond' Access & Ability
			tableData = tableData.filter(o =>
				o.createdUserData.userId === CURRENT_USER.id ||
				o.observationUsersToNotify?.includes(CURRENT_USER.id) ||
				['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager'].includes(CURRENT_USER.userLevel) ||
				CURRENT_USER.userObservationResponsibilities.includes('Teamship Reports') ||
				CURRENT_USER.userAccessAndAbilities.includes('Teamship: Can Respond')
			)

			// Search by Issue
			if (t.searchByIssue) tableData = tableData.filter(o => o.observationTitle.toUpperCase().includes(t.searchByIssue.toUpperCase()))

			// Search by Type
			if (t.searchByType) tableData = tableData.filter(o => o.observationType === t.searchByType)

			// Filter by Status
			if (t.filter.status.length) tableData = tableData.filter(o => t.filter.status.includes(o.observationStatus))

			tableData = tableData.sort((a, b) => a.createdDateTime < b.createdDateTime ? 1 : -1)

			return (tableData)
		},

	},

	methods: {

		/**
		 * Get External Sites Collection Data
		 *
		 * Fetch all the data from the collection.
		 * Iterate over the collection and only push documents that aren't marked as deleted.
		 *
		 * @returns {Promise<void>}
		 */
		async getExternalSitesCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('externalSites')
				.onSnapshot(snapshot => {

					// Rest the data to avoid duplication
					collectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only add documents that aren't marked as deleted
						if (!document.hasOwnProperty('delete')) collectionData.push(document)
					})

					t.externalSitesCollectionData = collectionData
					t.isExternalSitesCollectionDataLoaded = true
				})
		},

		/**
		 * Get Collection Data
		 *
		 * Fetch the data from the collection and assign them to the class state.
		 * Includes: Observations | Good Practices
		 *
		 * @returns {Promise<void>}
		 */
		async getObservationsCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('observations')
				.onSnapshot(snapshot => {

					// Clear the array to avoid duplications
					collectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only push active documents
						if (!document.hasOwnProperty('delete')) collectionData.push(document)
					})

					// Assign the documents in one and set isLoaded to true
					t.observationsCollectionData = collectionData
					t.isObservationsCollectionDataLoaded = true
				})
		},

		/**
		 * Get Sites Collection Data
		 *
		 * Fetch all the data from the collection.
		 * Iterate over the collection and only push documents that aren't marked as deleted.
		 *
		 * @returns {Promise<void>}
		 */
		async getSitesCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('sites')
				.orderBy('siteName')
				.onSnapshot(snapshot => {

					// Rest the data to avoid duplication
					collectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only add documents that aren't marked as deleted
						if (!document.hasOwnProperty('delete')) collectionData.push(document)
					})

					t.sitesCollectionData = collectionData
					t.isSitesCollectionDataLoaded = true
				})
		},

		/**
		 * Get Users Collection Data
		 *
		 * Fetch all the data from the collection.
		 * Iterate over the collection and only push documents that aren't marked as deleted.
		 *
		 * @returns {Promise<void>}
		 */
		async getUsersCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('users')
				.orderBy('userName')
				.onSnapshot(snapshot => {

					// Rest the data to avoid duplication
					collectionData = []

					snapshot.forEach(doc => {

						const DOCUMENT = doc.data()
						DOCUMENT.id = doc.id

						// Only add documents that:
						//  - Aren't marked as deleted
						//  - Are Approved
						//  - Have NOT signed up with 2FA
						//  - Have signed up with, and have passed 2FA
						if (!DOCUMENT.hasOwnProperty('delete') &&
							DOCUMENT.userStatus === 'APPROVED' &&
							(!DOCUMENT.userShouldUse2fa || (DOCUMENT.userHasPassed2fa))
						) {
							collectionData.push(DOCUMENT)
						}
					})

					// Sort alphabetically by userName
					collectionData = collectionData.sort((a, b) => a.userName > b.userName ? 1 : -1)

					t.usersCollectionData = collectionData
					t.isUsersCollectionDataLoaded = true
				})
		},

		/**
		 * Close Form Type Dialog
		 *
		 * Close the form type dialog.
		 */
		closeFormTypeDialog() {
			const t = this

			t.isFormTypeDialogVisible = false
		},

		/**
		 * Close Right Panel
		 *
		 * When closing the form, reset it to ensure it opens as new.
		 */
		closeRightPanel() {
			const t = this

			t.isFormReadOnly = true
			t.isRightPanelOpen = false
		},

		/**
		 * Edit Form
		 *
		 * Toggle the read only state of the form.
		 */
		editForm() {
			const t = this

			t.isFormReadOnly = !t.isFormReadOnly
		},

		/**
		 * Emitted Delete From Dialog
		 *
		 * Take the emitted message from the 'deleteIconDialog', and mark the selected document as deleted.
		 *
		 * @returns {Promise<void>}
		 */
		async emittedDeleteFromDialog() {
			const t = this

			const deleteDocument = await t.MIX_deleteDocument('observations', t.selectedItem.id)

			t.closeRightPanel()

			t.MIX_renderConfirmationAlert(
				deleteDocument,
				'Successfully Deleted Report',
				'Error Deleting Report')
		},

		/**
		 * Emitted Close Right Panel
		 *
		 * Take the emitted message and call to close the right panel.
		 *
		 * @returns {Promise<void>}
		 */
		async emittedCloseRightPanel() {
			const t = this

			t.closeRightPanel()
		},

		/**
		 * Get Site Name
		 *
		 * Get the site name from the site ID.
		 * If the site is an external site, get the project title.
		 *
		 * @param siteId the site ID to get the name from
		 * @returns {string} the site name
		 */
		getSiteName(siteId) {
			const t = this
			const SITES_DATA = t.sitesCollectionData
			const EXTERNAL_SITES_DATA = t.externalSitesCollectionData
			let siteName

			if (siteId) {
				const SITE = SITES_DATA.find(s => s.id === siteId)
				const EXTERNAL_SITE = EXTERNAL_SITES_DATA.find(s => s.id === siteId)

				siteName = SITE?.siteName || EXTERNAL_SITE?.projectTitle
			}

			return siteName
		},

		/**
		 * Handle Form Type Selection
		 *
		 * Close the form type dialog and open the right panel.
		 */
		handleFormTypeSelection() {
			const t = this

			if (!t.formType) return

			t.closeFormTypeDialog()
			t.openRightPanel()
		},

		/**
		 * Open Form Type Dialog
		 *
		 * Open the form type dialog.
		 */
		openFormTypeDialog() {
			const t = this

			t.formType = ''
			t.isFormTypeDialogVisible = true
		},

		/**
		 * Open Right Panel
		 *
		 * Open the Observation form.
		 * If the form is opened using the new button, it will be blank.
		 * If the form is opened from the table, it will be assigned here and populated.
		 *
		 * @param item JSON containing the selected data, if required
		 */
		openRightPanel(item) {
			const t = this

			// If the item is undefined, it means the form is being opened from the new button.
			// If the item is defined, it means the form is being opened from the table.
			if (!item?.id) t.isFormReadOnly = false
			else t.formType = item.observationType

			t.selectedItem = item

			t.isRightPanelOpen = true
		},

	},

	async mounted() {
		const t = this

		await t.getExternalSitesCollectionData()
		await t.getObservationsCollectionData()
		await t.getSitesCollectionData()
		await t.getUsersCollectionData()
	}

}
</script>

<style scoped>
.borderLeft {
	border-left: 4px solid #999999;
	min-height: calc(100vh - 128px);
}
</style>
