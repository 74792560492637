<template>
    <div style="width: 100%">

        <mapbox class="maplayer"
                :marker="marker"
                style="top: 0 !important; left: 0 !important;"
				:usersData="usersData"
                :weatherData="weatherData"/>

    </div>
</template>

<script>
import mapbox from "../../components/mapbox"

export default {

    name: 'Map',

    props: ['usersData', 'weatherData'],

    components: {
        mapbox,
    },

    data: () => ({
        device: {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [],
            },
            properties: {
                name: '',
                id: '',
                value: '',
                color: '#582768',
                icon: 'school-white',
                alert: 'false',
                textColor: '#582768',
                circle: 'true',
            },
        },
        isFormValid: false,
        loading: true,
        marker: null,
    }),

};
</script>

<style>
.mapboxgl-ctrl-top-left .mapboxgl-ctrl {
    box-shadow: 0 0 8px grey;
    position: fixed;
    bottom: 16px;
    right: 16px;
}

/* Hide Mapbox branding */
.mapboxgl-ctrl-bottom-right {
    display: none
}

.mapboxgl-ctrl-bottom-left {
    display: none
}
</style>
