<template>
	<v-form>

		{{ computedInit }}

		<!--Form Fields-->
		<v-row class="d-flex" no-gutters>

			<form-section-title class="mt-4" title="Project"/>

			<!--Project Title-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 12"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'">
				<app-input input-type="textInput"
						   :error="errors.projectTitle"
						   :error-messages="errors.projectTitleErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Project Title"
						   v-model="form.projectTitle"/>
			</v-col>

			<!--Client-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 12"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'">
				<app-input input-type="textInput"
						   :error="errors.projectClient"
						   :error-messages="errors.projectClientErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Client"
						   v-model.trim="form.projectClient"/>
			</v-col>

			<!--Framework-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pr-2' : 'mt-4 pr-2'">
				<app-input input-type="select"
						   :error="errors.projectFramework"
						   :error-messages="errors.projectFrameworkErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   :items="['Yes', 'No']"
						   label="Framework"
						   v-model="form.projectFramework"/>
			</v-col>

			<!--Contract Type-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pl-2' : 'mt-4 pl-2'">
				<app-input input-type="select"
						   :error="errors.projectContractType"
						   :error-messages="errors.projectContractTypeErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   :items="projectContractTypeOptions"
						   label="Contract Type"
						   v-model="form.projectContractType"/>
			</v-col>

			<!--Project Client Advertised Value-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pr-2' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :error="errors.projectClientAdvertisedValue"
						   :error-messages="errors.projectClientAdvertisedValueErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Client Advertised Value"
						   type="number"
						   v-model.number="form.projectClientAdvertisedValue"/>
			</v-col>

			<!--Evaluation Scoring-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pl-2' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :error="errors.projectEvaluationScoring"
						   :error-messages="errors.projectEvaluationScoringErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Evaluation Scoring"
						   v-model.trim="form.projectEvaluationScoring"/>
			</v-col>

			<!--Submission Date-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pr-2' : 'mt-4 pr-2'">
				<date-picker :date="form.projectSubmissionDate"
							 :error="errors.projectSubmissionDate"
							 :error-messages="errors.projectSubmissionDateErrorMessage"
							 :is-form-read-only="computedIsFormReadOnly"
							 label="Submission Date"
							 :minDate="[5, 'years', 'past']"
							 :maxDate="[1, 'years', 'future']"
							 v-on:emitDate="handleDatePicker($event, 'projectSubmissionDate')"/>
			</v-col>

			<!--Commencement Date-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pl-2' : 'mt-4 pl-2'">
				<date-picker :date="form.projectCommencementDate"
							 :error="errors.projectCommencementDate"
							 :error-messages="errors.projectCommencementDateErrorMessage"
							 :is-form-read-only="computedIsFormReadOnly"
							 label="Commencement Date"
							 :minDate="[5, 'years', 'past']"
							 :maxDate="[1, 'years', 'future']"
							 v-on:emitDate="handleDatePicker($event, 'projectCommencementDate')"/>
			</v-col>

			<!--Contract Period - start date-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pr-2' : 'mt-4 pr-2'">
				<date-picker @emitDate="handleDatePicker($event, 'projectContractPeriodStartDate')"
							 :clearable="true"
							 :date="form.projectContractPeriodStartDate"
							 :error="errors.projectContractPeriodStartDate"
							 :error-messages="errors.projectContractPeriodStartDateErrorMessage"
							 :is-form-read-only="computedIsFormReadOnly"
							 label="Contract Period (start)"
							 :minDate="[5, 'years', 'past']"
							 :maxDate="[1, 'years', 'future']"/>
			</v-col>

			<!--Contract Period - end date-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pl-2' : 'mt-4 pl-2'">
				<date-picker @emitDate="handleDatePicker($event, 'projectContractPeriodEndDate')"
							 :clearable="true"
							 :date="form.projectContractPeriodEndDate"
							 :error="errors.projectContractPeriodEndDate"
							 :error-messages="errors.projectContractPeriodEndDateErrorMessage"
							 :is-form-read-only="computedIsFormReadOnly"
							 label="Contract Period (end)"
							 :minDate="[5, 'years', 'past']"
							 :maxDate="[1, 'years', 'future']"/>
			</v-col>

			<!--Project Description-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 12"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'">
				<app-input input-type="textArea"
						   counter="360"
						   :error="errors.projectDescription"
						   :error-messages="errors.projectDescriptionErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Project Description"
						   max-length="360"
						   v-model="form.projectDescription"/>
			</v-col>

			<form-section-title class="mt-4" title="Team"/>

			<!--Bid Lead-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="select"
						   :clearable="true"
						   :is-form-read-only="computedIsFormReadOnly"
						   :items="computedUserOptions"
						   item-text="userName"
						   label="Bid Lead"
						   :return-object="true"
						   v-model="bidLeadUserData"/>
			</v-col>

			<!--Estimator-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="select"
						   :clearable="true"
						   :is-form-read-only="computedIsFormReadOnly"
						   :items="computedUserOptions"
						   item-text="userName"
						   label="Estimator"
						   :return-object="true"
						   v-model="estimatorUserData"/>
			</v-col>

			<!--Operational Lead-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="select"
						   :clearable="true"
						   :is-form-read-only="computedIsFormReadOnly"
						   :items="computedUserOptions"
						   item-text="userName"
						   label="Operational Lead"
						   :return-object="true"
						   v-model="operationalLeadUserData"/>
			</v-col>

			<form-section-title class="mt-8" title="Address"/>

			<!--Project Location Line 1-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :error="errors.projectLocationLine1"
						   :error-messages="errors.projectLocationLine1ErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Address Line 1"
						   v-model="form.projectLocationLine1"/>
			</v-col>

			<!--Project Location Line 2-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :error="errors.projectLocationLine2"
						   :error-messages="errors.projectLocationLine2ErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Address Line 2 (optional)"
						   v-model="form.projectLocationLine2"/>
			</v-col>

			<!--Project Location Town-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :error="errors.projectLocationTown"
						   :error-messages="errors.projectLocationTownErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Town"
						   v-model="form.projectLocationTown"/>
			</v-col>

			<!--Project Location City-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :error="errors.projectLocationCity"
						   :error-messages="errors.projectLocationCityErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="City"
						   v-model="form.projectLocationCity"/>
			</v-col>

			<!--Project Location County-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :error="errors.projectLocationCounty"
						   :error-messages="errors.projectLocationCountyErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="County"
						   v-model="form.projectLocationCounty"/>
			</v-col>

			<!--Project Location Postcode-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :error="errors.projectLocationPostcode"
						   :error-messages="errors.projectLocationPostcodeErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Postcode"
						   v-model="form.projectLocationPostcode"/>
			</v-col>

			<!--Project Location Latitude-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pr-2' : 'mt-4 pr-2'">
				<app-input input-type="textInput"
						   :error="errors.projectLocationLatitude"
						   :error-messages="errors.projectLocationLatitudeErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Latitude Coordinates"
						   v-model="form.projectLocationLatitude"/>
			</v-col>

			<!--Project Location Longitude-->
			<v-col :cols="$vuetify.breakpoint.width < 600 ? 6 : 6"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pl-2' : 'mt-4 pl-2'">
				<app-input input-type="textInput"
						   :error="errors.projectLocationLongitude"
						   :error-messages="errors.projectLocationLongitudeErrorMessage"
						   :is-form-read-only="computedIsFormReadOnly"
						   label="Longitude Coordinates"
						   v-model="form.projectLocationLongitude"/>
			</v-col>

		</v-row>

		<!--Save button-->
		<div v-if="!computedIsFormReadOnly" class="d-flex justify-end mt-4">
			<app-btn @click.native="saveDocument"
					 color="success"
					 icon="icons8-save"
					 label="Save"/>
		</div>

	</v-form>
</template>

<script>
import DatePicker from "@/components/commonComponents/datePicker.vue";

export default {

	name: "newSitesForm",

	components: {DatePicker},

	props: ['doesFormStartReadOnly', 'formData', 'usersData'],

	data: () => ({
		errors: {
			projectClient: false,
			projectClientErrorMessage: '',
			projectClientAdvertisedValue: false,
			projectClientAdvertisedValueErrorMessage: '',
			projectContractType: false,
			projectContractTypeErrorMessage: '',
			projectDescription: false,
			projectDescriptionErrorMessage: '',
			projectEvaluationScoring: false,
			projectEvaluationScoringErrorMessage: '',
			projectFramework: false,
			projectFrameworkErrorMessage: '',
			projectLocationLine1: false,
			projectLocationLine1ErrorMessage: '',
			projectLocationLine2: false,
			projectLocationLine2ErrorMessage: '',
			projectLocationTown: false,
			projectLocationTownErrorMessage: '',
			projectLocationCity: false,
			projectLocationCityErrorMessage: '',
			projectLocationCounty: false,
			projectLocationCountyErrorMessage: '',
			projectLocationPostcode: false,
			projectLocationPostcodeErrorMessage: '',
			projectLocationLatitude: false,
			projectLocationLatitudeErrorMessage: '',
			projectLocationLongitude: false,
			projectLocationLongitudeErrorMessage: '',
			projectSubmissionDate: false,
			projectSubmissionDateErrorMessage: '',
			projectTitle: false,
			projectTitleErrorMessage: '',
			projectTenderNumber: false,
			projectTenderNumberErrorMessage: '',

			projectCommencementDate: false,
			projectCommencementDateErrorMessage: '',
			projectContractPeriodStartDate: false,
			projectContractPeriodStartDateErrorMessage: '',
			projectContractPeriodEndDate: false,
			projectContractPeriodEndDateErrorMessage: '',
		},
		form: {
			id: '',
			projectBidLead: '',
			projectClient: '',
			projectCompletionDate: '',
			projectContractNumber: '',
			projectContractType: '',
			projectDescription: '',
			projectEstimator: '',
			projectEvaluationScoring: '',
			projectFramework: '',
			projectLocationLine1: '',
			projectLocationLine2: '',
			projectLocationTown: '',
			projectLocationCity: '',
			projectLocationCounty: '',
			projectLocationPostcode: '',
			projectLocationLatitude: '',
			projectLocationLongitude: '',
			projectOperationalLead: '',
			projectSubmissionDate: '',
			projectTenderNumber: '',
			projectTitle: '',
			projectClientAdvertisedValue: 0,
			projectArchivedAndCertified: false,
			projectArchivedAndCertifiedNotes: '',
			projectAcceptedIntoAdoption: false,
			projectAcceptedIntoAdoptionNotes: '',
			projectLeftDefectFree: false,
			projectLeftDefectFreeNotes: '',
			projectRetentionCertifiedOrReleased: false,
			projectRetentionCertifiedOrReleasedNotes: '',
			projectHsFileSubmittedAndAccepted: false,
			projectHsFileSubmittedAndAcceptedNotes: '',
			projectClosePEP: false,
			projectClosePEPNotes: '',

			projectCommencementDate: '',
			projectContractPeriodStartDate: '',
			projectContractPeriodEndDate: '',
			siteName: '',

			siteStatus: 'New',
			jobLostWon: '',
			locations: [],
		},
		projectContractTypeOptions: [
			'NEC3 Option A',
			'NEC3 Option B',
			'NEC3 Option C',
			'NEC3 Option D',
			'NEC3 Option E',
			'NEC3 Short Form',
			'NEC4 Option A',
			'NEC4 Option B',
			'NEC4 Option C',
			'NEC4 Option D',
			'NEC4 Option E',
			'NEC4 Short Form',
			'PSC',
			'JCT',
			'Bespoke',
			'Other',
		],
		isFormReadOnly: false,

		bidLeadUserData: {},
		estimatorUserData: {},
		operationalLeadUserData: {},
	}),

	computed: {

		/**
		 * Computed From Data
		 *
		 * If this is an existing document, set the form data.
		 * If it's not, the default form will be used.
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props.formData
			const USERS_DATA = t.$props.usersData

			if (FORM_DATA?.id) {

				// Assign data
				t.form = FORM_DATA

				console.log('FORM_DATA', FORM_DATA)

				// Create user Objects from their ID (objects are used, IDs are saved)
				t.bidLeadUserData = USERS_DATA.find(user => user.id === t.form.projectBidLead)
				t.estimatorUserData = USERS_DATA.find(user => user.id === t.form.projectEstimator)
				t.operationalLeadUserData = USERS_DATA.find(user => user.id === t.form.projectOperationalLead)
			}

		},

		/**
		 * Computed Is Form Read Only
		 *
		 * When the form is first created in the Supervisor's Control Panel,
		 * the form should be editable.
		 * If the form is open through the Reporting pages, it should open uneditable.
		 *
		 * @returns {boolean} is form read only
		 */
		computedIsFormReadOnly() {
			const t = this

			return t.$props.doesFormStartReadOnly
				? t.$props.doesFormStartReadOnly
				: t.isFormReadOnly
		},

		computedUserOptions() {
			const t = this
			let users = t.usersData

			users = users.sort((a, b) => {
				return a.userName > b.userName ? 1 : -1
			})

			users = users.filter(user =>
				!['Staff-Operative-Groundworker', 'Staff-Operative-Plant', 'Visitor-User'].includes(user.userLevel))

			return users
		}

	},

	methods: {

		/**
		 * Clear Errors and Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Generate Reference Number
		 *
		 * Site Tender and Contract numbers are stored in a Counters collection.
		 * Read the document and increment the number, convert back to a string, and return the value.
		 * The format for the number is a 'T' + four incrementing digits (e.g. T1234)
		 */
		async generateReferenceNumber() {
			const t = this
			let counter
			let referenceNumber

			const collection = await t.$firebase.db.collection('counters')
				.doc('sitesReferenceNumbers')
				.get()

			if (collection.exists) {
				counter = await collection.data().tenderNumber
				counter++
				referenceNumber = `T${String(counter).padStart(4, '0')}`
			}

			return {referenceNumber, counter}
		},

		/**
		 * Handle Date Picker
		 *
		 * Takes the emitted payload as $event data from the input field, and the field name,
		 * and sets the date to the specified form field.
		 *
		 * @param date the date to set
		 * @param field the form field to set
		 */
		handleDatePicker(date, field) {
			const t = this

			// Convert to Unix timestamp
			date = t.$moment(date).format('x')

			// If the date is invalid, set it to an empty string
			// This is probably from the user clearing the date picker
			if (date === 'Invalid date') date = ''

			// Set the date to the current form field
			t.form[field] = date
		},

		/**
		 * Save Document
		 *
		 * Create/update the document.
		 *
		 * @returns {Promise<void>}
		 */
		async saveDocument() {
			const t = this
			let documentResult

			// Get User ID from Object (objects are used, IDs are saved)
			t.form.projectBidLead = t.$props.usersData.find(user => user.id === t.bidLeadUserData?.id)?.id || ''
			t.form.projectEstimator = t.$props.usersData.find(user => user.id === t.estimatorUserData?.id)?.id || ''
			t.form.projectOperationalLead = t.$props.usersData.find(user => user.id === t.operationalLeadUserData?.id)?.id || ''

			// Set Site Name - This is so logic around the app can reference a single 'siteName' instead of differing fields for Sites (Office) and External Sites (Project)
			t.form.siteName = t.form.projectTitle

			if (!t.validateForm()) return

			// Create
			if (!t.form?.id) {

				// Generate new IDs
				t.form.id = t.MIX_generateId()

				// Get Incremented Tender Number, and the new counter value
				const {referenceNumber, counter} = await t.generateReferenceNumber()

				// Generate Incremented Tender Number
				t.form.projectTenderNumber = referenceNumber

				// Create the document
				documentResult = await t.MIX_createDocument('externalSites', t.form)

				// If the document was created successfully, proceed with post-processing
				if (documentResult.code === 1) {
					await t.MIX_addSiteNoticeBoardEntry(t.form, 'New')
					await t.MIX_updateDocumentFieldsById('counters', 'sitesReferenceNumbers', {tenderNumber: counter})
					await t.sendNewSitesNotification(t.form, t.$props.usersData)
				}

			}

			// Update
			else {


				// Update the document
				documentResult = await t.MIX_updateDocument('externalSites', t.form)

			}

			t.MIX_renderConfirmationAlert(
				documentResult,
				'Successfully Saved',
				'Error Saving'
			)

			t.$emit('emitCloseRightPanel')
		},

		/**
		 * Send New Site Notification
		 *
		 * Call a Firebase function to email all users when a new site has been created.
		 *
		 * @param siteData the new site data
		 * @param usersData all users data
		 */
		sendNewSitesNotification(siteData, usersData) {
			const t = this

			// Only send the required data
			usersData = usersData.map(user => {
				return ({
					userLevel: user.userLevel,
					delete: user.delete === true,
					userEmail: user.userEmail,
					userStatus: user.userStatus,
				})
			})

			const createEmail = t.$firebase.functions.httpsCallable('sendNewSiteNotification')
			createEmail({
				usersData,
				siteData,
			})
				.then(result => {
					console.log('Successfully sent New Site emails: ', result)
				})
				.catch(error => {
					console.error('Error sending New Site emails: ', error)
				})

		},

		/**
		 * Validate Form
		 *
		 * Validate all required form fields before saving.
		 *
		 * @returns {boolean} if the form has passed validation or not
		 */
		validateForm() {
			const t = this

			t.clearErrorsAndMessages()

			// Client
			if (!t.form.projectClient?.trim()) {
				t.errors.projectClient = true
				t.errors.projectClientErrorMessage = 'Client required'
			}

			// Contract Type
			if (!t.form.projectContractType?.trim()) {
				t.errors.projectContractType = true
				t.errors.projectContractTypeErrorMessage = 'Contract Type required'
			}

			// Framework
			if (!t.form.projectFramework?.trim()) {
				t.errors.projectFramework = true
				t.errors.projectFrameworkErrorMessage = 'Framework required'
			}

			// Evaluation Scoring
			if (!t.form.projectEvaluationScoring?.trim()) {
				t.errors.projectEvaluationScoring = true
				t.errors.projectEvaluationScoringErrorMessage = 'Evaluation Scoring required'
			}

			// Evaluation Scoring
			if (!t.form.projectEvaluationScoring?.trim()) {
				t.errors.projectEvaluationScoring = true
				t.errors.projectEvaluationScoringErrorMessage = 'Evaluation Scoring required'
			}

			// Project Description
			if (!t.form.projectDescription?.trim()) {
				t.errors.projectDescription = true
				t.errors.projectDescriptionErrorMessage = 'Project Description required'
			}

			// Project Location Line 1
			if (!t.form.projectLocationLine1?.trim()) {
				t.errors.projectLocationLine1 = true
				t.errors.projectLocationLine1ErrorMessage = 'Address Line 1 required'
			}

			// Project Location Town
			if (!t.form.projectLocationTown?.trim()) {
				t.errors.projectLocationTown = true
				t.errors.projectLocationTownErrorMessage = 'Town required'
			}

			// Project Location City
			if (!t.form.projectLocationCity?.trim()) {
				t.errors.projectLocationCity = true
				t.errors.projectLocationCityErrorMessage = 'City required'
			}

			// Project Location County
			if (!t.form.projectLocationCounty?.trim()) {
				t.errors.projectLocationCounty = true
				t.errors.projectLocationCountyErrorMessage = 'County required'
			}

			// Project Location Postcode
			const SITE_POSTCODE = t.form.projectLocationPostcode?.trim()
			if (!SITE_POSTCODE) {
				t.errors.projectLocationPostcode = true
				t.errors.projectLocationPostcodeErrorMessage = 'Postcode required'
			} else if (!t.MIX_isPostcodeValid(SITE_POSTCODE)) {
				t.errors.projectLocationPostcode = true
				t.errors.projectLocationPostcodeErrorMessage = 'Postcode not in the expected format'
			}

			// Coordinates
			if (t.form.projectLocationLatitude.trim() && t.form.projectLocationLongitude.trim()) {
				const COORDS = {
					latitude: t.form.projectLocationLatitude.trim(),
					longitude: t.form.projectLocationLongitude.trim()
				}
				const RESULT = t.MIX_isLatLngValid(COORDS)

				if (!RESULT.isLatValid) {
					t.errors.projectLocationLatitude = true
					t.errors.projectLocationLatitudeErrorMessage = 'Latitude is not in the expected format'
				}

				if (!RESULT.isLngValid) {
					t.errors.projectLocationLongitude = true
					t.errors.projectLocationLongitudeErrorMessage = 'Longitude is not in the expected format'
				}
			}

			// Project Title
			if (!t.form.projectTitle?.trim()) {
				t.errors.projectTitle = true
				t.errors.projectTitleErrorMessage = 'Project Title required'
			}

			// Submission Date
			if (!t.form.projectSubmissionDate) {
				t.errors.projectSubmissionDate = true
				t.errors.projectSubmissionDateErrorMessage = 'Submission Date required'
			}

			// Commencement Date
			if (!t.form.projectCommencementDate) {
				t.errors.projectCommencementDate = true
				t.errors.projectCommencementDateErrorMessage = 'Commencement Date required'
			}

			// Contract Period - Start Date
			// If the End date is present, so must the Start date be
			if (!t.form.projectContractPeriodStartDate && t.form.projectContractPeriodEndDate) {
				t.errors.projectContractPeriodStartDate = true
				t.errors.projectContractPeriodStartDateErrorMessage = 'Contract Period Start Date required'
			}

			// Contract Period - End Date
			// If the Start date is present, so must the End date be
			if (t.form.projectContractPeriodStartDate && !t.form.projectContractPeriodEndDate) {
				t.errors.projectContractPeriodEndDate = true
				t.errors.projectContractPeriodEndDateErrorMessage = 'Contract Period End Date required'
			}
			// If both dates are present, the End date must be after the Start date
			else if (t.form.projectContractPeriodStartDate > t.form.projectContractPeriodEndDate) {
				t.errors.projectContractPeriodStartDate = true
				t.errors.projectContractPeriodStartDateErrorMessage = 'Start Date must be before the End Date'
				t.errors.projectContractPeriodEndDate = true
				t.errors.projectContractPeriodEndDateErrorMessage = 'End Date must be after the Start Date'
			}

			// Return a boolean if the form has passed, or not
			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>

<style scoped>

</style>
