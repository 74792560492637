<template>
<v-row>
    <v-row class="pa-4" no-gutters>

        <!--Description-->
        <v-col class="pa-0" cols="12">

            <!--Info-->
            <app-text category="text-default">
                Use the form below to Add Locations to Sites.
            </app-text>

            <v-divider class="my-4"/>

        </v-col>

        <!--Add button | Deleted switch-->
        <v-col class="d-flex align-center">

            <!--Add-->
            <app-btn
                @click.native="MIX_location(true, 'externalSites', form.id, form.locations, {id: form.id, siteName: form.projectTitle, siteManager: form.bidManager, sitePostcode: form.addressPostcode})"
                class="mr-2"
                color="success"
                icon="icons8-edit-property"
                label="Add New Location"/>

            <v-spacer/>

        </v-col>

    </v-row>

    <!--Table-->
    <v-row class="ma-4 mt-0" no-gutters>
        <v-col cols="12">

            <v-data-table class="rounded-lg"
                            :headers="locationsHeaders"
                            :items="locationsTableData"
                            :items-per-page="10">

                <!--Location name-->
                <template v-slot:[`item.locationName`]="{ item }">
                    <app-text category="text-default">{{ item.locationName }}</app-text>
                </template>

                <!--Staff-->
                <template v-slot:[`item.staff`]="{ item }">
                    <app-text category="text-default" class="justify-center">
                        {{ noOfUsersAtLocation(item.id, 'Staff') }}
                    </app-text>
                </template>

                <!--Total-->
                <template v-slot:[`item.totalCount`]="{ item }">
                    <app-text category="text-default" class="justify-center">
                        {{ noOfUsersAtLocation(item.id, '') }}
                    </app-text>
                </template>

                <!--Actions-->
                <template v-slot:[`item.actions`]="{ item }">

                    <!--QR Poster-->
                    <v-icon @click="openQrCode(item.id, item, item.locationName, form.siteTelephone)"
                            class="icons8-qr-code"
                            color="success"
                            size="32"/>

                    <!--Delete-->
                    <v-icon
                        @click="MIX_deleteCollectionArray(item.id, item, 'locations', 'externalSites', form.id)"
                        class="icons8-trash ml-4"
                        color="error"
                        size="32"/>

                </template>

            </v-data-table>

        </v-col>
    </v-row>
</v-row>
</template>

<script>

export default {

    name: "newSitesLocation",

    components: {},

    props: ['formData', 'usersData'],

    data: () => ({
        form: {},
        locationsTableData: [],
        usersTableData: [],
        }),

    computed: {

        locationsHeaders() {
            return [
                {text: 'Name', value: 'locationName', align: 'left', sortable: false, hidden: false, hide: false},
                {text: 'Staff', value: 'staff', align: 'center', sortable: false, hidden: false, hide: false},
                {text: 'Total', value: 'totalCount', align: 'center', sortable: false, hidden: false, hide: false},
                {text: 'Actions', value: 'actions', align: 'center', sortable: false, hidden: false, hide: false},
            ]
        },
    },

     methods: {

        noOfUsersAtLocation(locationId, userType) {
            let count = 0

            // If a user type is specified
            if (userType !== '') {
                for (let i = 0; i < this.usersTableData.length; i++) {
                    if (locationId === this.usersTableData[i].lastLocation.locationId && this.usersTableData[i].swappStatus === 1 && this.usersTableData[i].userType === userType) {
                        count++
                    }
                }
            } else {
                for (let i = 0; i < this.usersTableData.length; i++) {
                    if (locationId === this.usersTableData[i].lastLocation.locationId && this.usersTableData[i].swappStatus === 1) {
                        count++
                    }
                }
            }

            return count
        },

        openQrCode(id, item, siteName, siteTelephone) {
            const t = this

            t.MIX_generateCode(true, id, 300, "H", "qrcode", "canvas", "#ffffff", "#000000", true, item.locationName, siteName, siteTelephone)
        },

        getLocations(site) {
            const t = this

            if (site) {
                t.$firebase.db.collection('locations').where("site.id", "==", site.id)
                    .onSnapshot(function (querySnapshot) {
                        t.locationsTableData = []
                        querySnapshot.forEach(function (doc) {
                            const document = doc.data()
                            document.id = doc.id
                            if (!document.hasOwnProperty('delete')) t.locationsTableData.push(document)
                        })
                    })
            }
        },
     },

    async mounted() {
        const t = this

        t.form = t.$props.formData
        t.usersTableData = t.$props.usersData

        await t.getLocations(t.$props.formData)
     },

}
</script>

<style scoped>

</style>
